import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getViewsAttributes } from '../../../../store/attributes/biteAttributes/biteAttributes.slice';
import HeaderTabs from '../../common/HeaderTabs';
import useRedirectFromAttributes from '../../hooks/useRedirectFromAttributes';
import { IStackNavigation } from '../../../../navigation/types';
import useViewsAttributesMap from '../hooks/useViewsAttributesMap';
import { currentListSelector } from '../../../../store/analytics/analytics.selector';
import { ECurrentList } from '../../../../store/analytics/analytics.types';
import { EAnalyticsScreenType } from '../../Analytics.types';
import GuestsAttribute from '../common/GuestsAttribute';
import {
  selectedFilterAttributeValueIdsSelector,
  biteSharedWithFilterSelector,
} from '../../../../store/attributes/biteAttributes/biteAttributes.selector';
import { VIEWS_ATTRIBUTES_SCREEN, VIEWS_LIST_SCREEN } from '../constants';
import useHeaderTabsDivider from '../../hooks/useHeaderTabsDivider';
import { attributesListDataSet } from '../../analytics.constants';
import { isNeedToScrollUpViewsSelector } from '../../../../store/attributes/biteStats/biteStats.selector';
import { ESharedWithFilter } from '../../../../types/anayltics';
import BiteAttributesList from '../common/BiteAttributesList';

const ViewsAttributes: React.FC<IStackNavigation> = ({ navigation }) => {
  const currentList = useSelector(currentListSelector);
  const isNeedToScrollUp = useSelector(isNeedToScrollUpViewsSelector);
  const sharedWithFilter = useSelector(biteSharedWithFilterSelector);

  const selectedAttributes = useSelector(selectedFilterAttributeValueIdsSelector);
  const [isHideGuestsAttribute, setIsHideGuestsAttribute] = useState(false);
  const [isFocusedMode, setIsFocusedMode] = useState(false);

  const { handleScroll, isShowHeaderBottomDivider } = useHeaderTabsDivider();

  const { attributesMap } = useViewsAttributesMap();
  useRedirectFromAttributes({
    attributesMap,
    listScreen: VIEWS_LIST_SCREEN,
    navigation,
  });

  const renderListHeaderComponent = useCallback(
    ({ isRefreshing }) => {
      if (
        selectedAttributes.length ||
        isHideGuestsAttribute ||
        (sharedWithFilter.length === 1 && sharedWithFilter.includes(ESharedWithFilter.COMMUNICATION_HUB))
      ) {
        return null;
      }

      return <GuestsAttribute currentTab={EAnalyticsScreenType.VIEWS} isRefreshing={isRefreshing} />;
    },
    [isHideGuestsAttribute, selectedAttributes.length, sharedWithFilter],
  );

  useEffect(() => {
    if (currentList === ECurrentList.USERS) {
      navigation.replace(VIEWS_LIST_SCREEN);
    }
  }, [currentList, navigation]);

  return (
    <>
      <HeaderTabs
        currentScreen={VIEWS_ATTRIBUTES_SCREEN}
        attributesScreen={VIEWS_ATTRIBUTES_SCREEN}
        listScreen={VIEWS_LIST_SCREEN}
        isShowBottomDivider={isShowHeaderBottomDivider && !isFocusedMode}
      />
      <BiteAttributesList
        dataSet={attributesListDataSet}
        scrollEventThrottle={10}
        onScroll={handleScroll}
        onSelectAttribute={setIsHideGuestsAttribute}
        onSingleAttributeChanged={setIsFocusedMode}
        renderListHeaderComponent={renderListHeaderComponent}
        isNeedToScrollUp={isNeedToScrollUp}
        listType={EAnalyticsScreenType.VIEWS}
        attributesMap={attributesMap}
        onLoad={getViewsAttributes}
      />
    </>
  );
};

export default memo(ViewsAttributes);
