import AnalyticsList from '../AnalyticsList';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import { calcHeight, deviceHeight } from '../../../../utils/dimensions';
import { log } from '../../../../store/appActivity/appActivity.slice';
import { IOrganizationAttributeValue } from '../../../../types/anayltics';

interface IProps {
  selectedAttributeId: number;
  selectedValues: IOrganizationAttributeValue[];
  selectedValuesMap: { [attributeId: number]: boolean };
  renderItem: (props: { item: IOrganizationAttributeValue }) => React.ReactElement;
  dataSet?: { [key: string]: string };
  onGetSearchList: (payload: { attributeId: number; reset?: boolean; callback?: () => void }) => void;
  isSearchDataLoading: boolean;
  searchData: IOrganizationAttributeValue[];
  searchDataError: string;
  searchDataNext: string;
  selectedValueIds: number[];
}

const SearchList: React.FC<IProps> = ({
  selectedAttributeId,
  selectedValues,
  selectedValuesMap,
  renderItem,
  dataSet,
  onGetSearchList,
  isSearchDataLoading,
  searchData,
  searchDataError,
  searchDataNext,
  selectedValueIds,
}) => {
  const dispatch = useDispatch();

  const data = useMemo(() => {
    if (!searchDataNext && !isSearchDataLoading && selectedValues.length) {
      const selectedIdsInResultsMap = {};
      const result = [];

      searchData.forEach((value) => {
        if (selectedValuesMap[value.id]) {
          selectedIdsInResultsMap[value.id] = true;
        }

        result.push(value);
      });

      selectedValues.forEach((value) => {
        if (!selectedIdsInResultsMap[value.id]) {
          result.push(value);
        }
      });

      return result;
    }

    return searchData;
  }, [isSearchDataLoading, searchData, searchDataNext, selectedValues, selectedValuesMap]);

  const handleEndReached = useCallback(() => {
    dispatch(
      log({
        event: 'SearchList.handleEndReached',
        data: { selectedAttributeId, isSearchDataLoading, searchDataNext, searchDataError },
      }),
    );

    onGetSearchList({ attributeId: selectedAttributeId });
  }, [dispatch, selectedAttributeId, isSearchDataLoading, searchDataNext, searchDataError, onGetSearchList]);

  const handleEndReachedProp = useMemo(() => {
    if (isSearchDataLoading || !searchDataNext || searchDataError) {
      return;
    }

    return handleEndReached;
  }, [isSearchDataLoading, searchDataNext, searchDataError, handleEndReached]);

  const handleErrorRefresh = useCallback(() => {
    dispatch(
      log({
        event: 'SearchList.handleErrorRefresh',
        data: { selectedAttributeId },
      }),
    );

    onGetSearchList({ attributeId: selectedAttributeId });
  }, [dispatch, onGetSearchList, selectedAttributeId]);

  const handleRefresh = useCallback(
    ({ callback }) => {
      dispatch(
        log({
          event: 'SearchList.handleRefresh',
          data: { selectedAttributeId },
        }),
      );

      onGetSearchList({ attributeId: selectedAttributeId, reset: true, callback });
    },
    [dispatch, onGetSearchList, selectedAttributeId],
  );

  return (
    <S.AnalyticsList
      dataSet={dataSet}
      isLoading={isSearchDataLoading}
      onEndReached={handleEndReachedProp}
      onErrorRefresh={handleErrorRefresh}
      selectedValueIds={selectedValueIds}
      onRefresh={handleRefresh}
      error={searchDataError}
      data={data}
      renderItem={renderItem}
    />
  );
};

const S = {
  AnalyticsList: styled(AnalyticsList)`
    width: 100%;
    max-height: ${deviceHeight - calcHeight(100)}px;
    margin-bottom: ${calcHeight(13)}px;
  `,
};

export default memo(SearchList);
