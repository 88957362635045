import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/shared/buttons/Button/Button';
import defaultTheme from '../../../../themes/defaultTheme';
import VideoPlayerPlayIcon from '../../../../assets/icons/videoEditor/video-player-play.svg';
import Checkbox from '../../../../components/shared/Checkbox/Checkbox';
import { useBooleanState } from '../../../../hooks/useBooleanState';

type PreviewControlsProps = {
  onAdd?: () => void;
  onReplace?: () => void;
  onClose?: () => void;
};
export const PreviewControls = memo(({ onAdd, onReplace, onClose }: PreviewControlsProps) => {
  const replaceState = useBooleanState(!!(onAdd && onReplace));

  return (
    <>
      <PreviewControlsContainer>
        {onClose ? (
          <Button
            text='Close'
            textColor={defaultTheme.colors.primaryBlue}
            border={defaultTheme.colors.primaryBlue}
            fill={defaultTheme.colors.white}
            style={buttonStyle}
            onPress={onClose}
          />
        ) : (
          <div />
        )}
        <S.PreviewControlsRightContainer>
          {onAdd && onReplace && (
            <S.CheckboxContainer>
              <Checkbox checked={!replaceState.state} onChange={replaceState.toggle} />
              <S.CheckboxLabel onClick={replaceState.toggle}>Add as layer, keep original</S.CheckboxLabel>
            </S.CheckboxContainer>
          )}
          {onReplace && (!onAdd || replaceState.state) && (
            <Button
              text='Replace'
              textColor={defaultTheme.colors.white}
              border='#FF3C27'
              fill='#FF3C27'
              style={buttonStyle}
              onPress={onReplace}
            />
          )}
          {onAdd && (!onReplace || !replaceState.state) && (
            <Button
              text='Add'
              textColor={defaultTheme.colors.white}
              border={defaultTheme.colors.primaryBlue}
              fill={defaultTheme.colors.primaryBlue}
              style={buttonStyle}
              onPress={onAdd}
            />
          )}
        </S.PreviewControlsRightContainer>
      </PreviewControlsContainer>
    </>
  );
});

type VideoPlayerProps = {
  url: string;
};
// export const VideoPlayer = memo(({ url }: VideoPlayerProps) => {
//   const mediaViewerProps = useMemo(
//     () => ({
//       mediaURI: url,
//       withPlayIcon: true,
//     }),
//     [url],
//   );

//   return (
//     <VimeoVideoPlayer
//       mediaViewerProps={mediaViewerProps}
//       //  playIcon={<VideoPlayerPlayIcon />}
//     />
//   );
// });
export const VideoPlayer = memo(({ url }: VideoPlayerProps) => {
  const ref = useRef<HTMLVideoElement>(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const clickTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const togglePlay = useCallback(() => {
    clearTimeout(clickTimeout.current);
    // timeout workaround, due to event propagation
    clickTimeout.current = setTimeout(() => {
      if (isPlaying) {
        setIsPlaying(false);
        ref.current?.pause();
        return;
      }

      setIsPlaying(true);
      ref.current?.play();
    }, 0);
  }, [isPlaying]);

  useEffect(() => {
    const video = ref.current;

    if (!video) {
      return;
    }

    const onPlay = () => {
      setIsPlaying(true);
    };

    const onPause = () => {
      setIsPlaying(false);
    };

    video.addEventListener('play', onPlay);
    video.addEventListener('pause', onPause);

    return () => {
      video.removeEventListener('play', onPlay);
      video.removeEventListener('pause', onPause);

      clearTimeout(clickTimeout.current);
    };
  }, []);

  return (
    <S.VideoContainer onClick={(e) => e.stopPropagation()}>
      <S.Video src={url} controls={isPlaying} ref={ref} onClick={togglePlay} />
      {!isPlaying && (
        <S.VideoPlayerPlayIconContainer>
          <VideoPlayerPlayIcon />
        </S.VideoPlayerPlayIconContainer>
      )}
    </S.VideoContainer>
  );
});

const S = {
  VideoContainer: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  `,
  Video: styled.video`
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  `,
  VideoPlayerPlayIconContainer: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  `,
  PreviewControlsRightContainer: styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 24px;
  `,
  CheckboxContainer: styled.div`
    display: flex;
    align-items: center;
  `,
  CheckboxLabel: styled.div`
    color: #313235;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 15px;
    margin-left: 8px;
  `,
};

const buttonStyle = {
  paddingLeft: 16,
  paddingRight: 16,
  height: 45,
  width: 133,
};

export const PreviewContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 0 20px;
  height: 100%;
  min-height: 0;
`;
export const PreviewControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  gap: 16px;
  margin-top: 21px;
  padding: 0 21px 21px;
  width: 100%;
`;
