import { useEffect, useState } from 'react';
import { LAYER_PADDING_LEFT, TIMELINE_RIGHT_SPACING } from '../Timeline.web';
import { useDispatch } from 'react-redux';
import { setTimelineScale } from '../../../store/videoEditor/videoEditor.slice';

export const useTimelineScale = ({
  containerWidth,
  videoDuration,
  timelineScalePc,
}: {
  containerWidth: number;
  videoDuration: number;
  timelineScalePc: number;
}) => {
  const dispatch = useDispatch();

  const MIN_SCALE_MIN_DISPLAY_SECONDS = 30;

  const getMinSecondWidth = () => (window.innerWidth * 0.8) / Math.max(videoDuration, MIN_SCALE_MIN_DISPLAY_SECONDS);

  const currentMinSecondWidth = getMinSecondWidth();
  const [minSecondWidth, setMinSecondWidth] = useState(currentMinSecondWidth);

  // put back when allow scale up to frames
  // const FRAME_WIDTH = 100;
  // const maxSecondWidth = DISPLAY_FRAMES_PER_SECOND * FRAME_WIDTH;
  const maxSecondWidth = 90;

  const minMaxSecondWidthDiff = maxSecondWidth - minSecondWidth;
  const secondDisplayWidth = (minMaxSecondWidthDiff * timelineScalePc) / 100 + minSecondWidth;

  const containerSeconds = (containerWidth - LAYER_PADDING_LEFT) / secondDisplayWidth;
  const rightSpaceSeconds = TIMELINE_RIGHT_SPACING / secondDisplayWidth;

  const timelineSeconds = Math.max(videoDuration + rightSpaceSeconds, containerSeconds);
  const timelineWidth = timelineSeconds * secondDisplayWidth;

  useEffect(() => {
    if (currentMinSecondWidth === minSecondWidth) {
      return;
    }

    const diff = maxSecondWidth - currentMinSecondWidth;

    const newTimelineScalePc = ((secondDisplayWidth - currentMinSecondWidth) * 100) / diff;

    dispatch(setTimelineScale(newTimelineScalePc));
    setMinSecondWidth(currentMinSecondWidth);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMinSecondWidth]);

  return {
    secondDisplayWidth,
    timelineSeconds,
    timelineWidth,
  };
};
