import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectedTimelineItemIdSelector,
  showTimelineItemGeneratedMetaSelector,
  timelineItemSeletor,
} from '../../store/videoEditor/videoEditor.selectors';
import styled from 'styled-components';
import BulbIcon from '../../assets/icons/videoEditor/bulb.svg';
import SearchBlueIcon from '../../assets/icons/videoEditor/search-blue.svg';
import GlobeIcon from '../../assets/icons/videoEditor/leftSidebar/stock-selected.svg';
import { cloudAssetSelector } from '../../store/cloudAssets/cloudAssets.selector';
import defaultTheme from '../../themes';
import { ITimelineItem } from '../../store/videoEditor/videoEditor.types';
import { setFileType, setPrimaryTab, setSearch } from '../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import {
  setReplacingSelectedTimelineItem,
  setShowTimelineItemGeneratedMeta,
} from '../../store/videoEditor/videoEditor.slice';
import { ICloudAsset } from '../../store/cloudAssets/cloudAssets.types';
import { stopPropagation } from '../../utils/stopPropagation';

export const GeneratedMeta = memo(() => {
  const selectedTimelineItemId = useSelector(selectedTimelineItemIdSelector);
  const timelineItem = useSelector(timelineItemSeletor(selectedTimelineItemId));
  const cloudAsset = useSelector(cloudAssetSelector(timelineItem?.cloudAssetId));

  if (!timelineItem || !cloudAsset) {
    return null;
  }

  return <GeneratedMetaWithData timelineItem={timelineItem} cloudAsset={cloudAsset} />;
});

type Props = {
  timelineItem: ITimelineItem;
  cloudAsset: ICloudAsset;
};
export const GeneratedMetaWithData = memo(({ timelineItem, cloudAsset }: Props) => {
  const dispatch = useDispatch();

  const showTimelineItemGeneratedMeta = useSelector(showTimelineItemGeneratedMetaSelector);
  const keyword = cloudAsset.sourceMeta?.keyword || timelineItem.generatedMeta?.keywords?.[0] || null;

  const handleSearchStock = useCallback(() => {
    dispatch(setSearch(keyword));
    dispatch(setReplacingSelectedTimelineItem(true));
    dispatch(setPrimaryTab('STOCK'));
    dispatch(setFileType(cloudAsset.fileType as 'video' | 'image'));
    dispatch(setShowTimelineItemGeneratedMeta(null));
  }, [cloudAsset.fileType, dispatch, keyword]);

  const handleSearchCloud = useCallback(() => {
    dispatch(setSearch(keyword));
    dispatch(setReplacingSelectedTimelineItem(true));
    dispatch(setPrimaryTab('CLOUD'));
    dispatch(setFileType(cloudAsset.fileType as 'video' | 'image'));
    dispatch(setShowTimelineItemGeneratedMeta(null));
  }, [cloudAsset.fileType, dispatch, keyword]);

  if (!keyword) {
    return null;
  }

  return (
    <S.Container
      x={showTimelineItemGeneratedMeta.x}
      y={showTimelineItemGeneratedMeta.y}
      width={showTimelineItemGeneratedMeta.width}
      onClick={stopPropagation}
    >
      <S.Top>
        <BulbIcon />
        <S.TopText>(Beta Version)</S.TopText>
      </S.Top>
      <S.Title>Want a different asset?</S.Title>
      <S.Description>
        You can replace this asset with one from the projects folder or using stock content. Click to search for "
        {keyword}".
      </S.Description>
      <S.Btn onClick={handleSearchCloud}>
        <SearchBlueIcon />
        <span>Search Project Assets</span>
      </S.Btn>
      <S.Btn onClick={handleSearchStock}>
        <GlobeIcon />
        <span>Search Web Stock</span>
      </S.Btn>
    </S.Container>
  );
});

const WIDTH = 354;

const S = {
  Container: styled.div<{
    x: number;
    y: number;
    width: number;
  }>`
    z-index: 20;
    position: fixed;
    bottom: ${({ y }) => window.innerHeight - (y + 5)}px;
    right: ${({ x, width }) => {
      const right = window.innerWidth - (x + width + 26);

      const leftOverflow = window.innerWidth - right - WIDTH;
      if (leftOverflow < 0) {
        return right + leftOverflow;
      }

      if (right < 0) {
        return 0;
      }

      return right;
    }}px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 15px;
    padding: 20px;
    width: ${WIDTH}px;
    border: 1px solid var(--Style, #fe9900);
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.15);
  `,
  Top: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
  `,
  TopText: styled.div`
    color: #707175;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 12px;
    line-height: 16.8px;
  `,
  Title: styled.div`
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 19px;
  `,
  Description: styled.div`
    color: #313235;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 15px;
    line-height: 21px;
  `,
  Btn: styled.div`
    display: flex;
    padding: 16px 17px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #dfdfdf;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    cursor: pointer;

    &:hover {
      background-color: #f4f4f4;
    }
  `,
};
