import { useSelector } from 'react-redux';
import { contentIdSelector } from '../../../../store/analytics/analytics.selector';
import { playlistItemSelector } from '../../../../store/playlist/playlist.selectors';
import { PlaylistStats } from '../../../../store/attributes/playlistAttributes/playlistAttributes.types';

export type TPlaylistStats = {
  started: number;
  startedRate?: number;
  progress: number;
  progressRate: number;
  completed: number;
  completedRate: number;
  success: number;
  sharedWithUsersNum: number;
};

const useCalculatePlaylistStats = (stats: PlaylistStats): TPlaylistStats => {
  const playlistId = useSelector(contentIdSelector);
  const playlist = useSelector(playlistItemSelector(playlistId));

  if (!stats || !playlist) {
    return {
      started: 0,
      startedRate: 0,
      progress: 0,
      progressRate: 0,
      completed: 0,
      completedRate: 0,
      success: 0,
      sharedWithUsersNum: 0,
    };
  }

  const safeDivide = (numerator: number, denominator: number): number => {
    return denominator ? Math.round((numerator / denominator) * 100) : 0;
  };

  return {
    started: stats.started_users_num,
    startedRate: stats.shared_with_users_num
      ? safeDivide(stats.started_users_num, stats.shared_with_users_num)
      : stats.started_users_num
      ? 100
      : 0,
    progress: stats.viewed_bite_shares_num,
    progressRate: safeDivide(stats.viewed_bite_shares_num, stats.started_users_num * playlist?.bite_shares.length),
    completed: stats.completed_users_num,
    completedRate: safeDivide(stats.completed_users_num, stats.started_users_num),
    success: safeDivide(stats.answered_correctly_num, stats.total_answers_num),
    sharedWithUsersNum: stats.shared_with_users_num,
  };
};

export default useCalculatePlaylistStats;
