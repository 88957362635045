import { all, delay, put, select, takeLatest } from 'redux-saga/effects';
import * as calls from '../../api/bites-api/calls/playlistAttributes.calls';
import {
  getFilterList,
  getPlaylistOrganizationAttributes,
  getPlaylistAttributes,
  getPlaylistGuestsAttribute,
  getSearchList,
  setAttribute,
  setAttributeError,
  setAttributeLoading,
  setFilterList,
  setFilterListError,
  setGuestsAttribute,
  setGuestsAttributeError,
  setGuestsAttributeLoading,
  setSearchList,
  setSearchListError,
  setPlaylistOrganizationAttributes,
  setPlaylistOrganizationAttributesError,
} from './playlistAttributes.slice';
import { activeOrganizationSelector } from '../../auth/auth.selectors';
import { contentIdSelector } from '../../analytics/analytics.selector';
import withRetry from '../../../utils/withRetry';
import {
  playlistDisplayModeSelector,
  filterAttributeByIdSelector,
  playlistAttributeNextSelector,
  searchValueSelector,
  searchValuesNextSelector,
  selectedFilterAttributeValueIdsSelector,
  playlistSharedWithFilterSelector,
  sortBySelector,
  sortDirectionSelector,
} from './playlistAttributes.selector';
import { PayloadAction } from '@reduxjs/toolkit';
import { resetAnalytics, setIsRefreshing } from '../../analytics/analytics.slice';
import { log } from '../../appActivity/appActivity.slice';
import { ESortDirection } from '../../../types/common';
import { organizationFeaturesSelector } from '../../org/org.selectors';
import { FEATURE_FLAG_IDS } from '../../../utils/constants/org';
import { ESortBy, ESortByType, IPlaylistAttributeMapItem, PlaylistAttributeValue } from './playlistAttributes.types';
import { IGetAttributesAction } from '../../org/org.types';
import { TDisplayMode } from '../../../types/anayltics';
import { IGetAttributesPayload } from '../../../screens/analytics/Analytics.types';
import { getOrganizationAttributes } from '../../api/bites-api/calls/biteAttributes.calls';

export const API_SORT_TYPES_BY_APP_SORT_TYPE = {
  [ESortByType.STARTED]: {
    percentage: ESortBy.STARTED_PERCENTAGE,
    absolute: ESortBy.STARTED_ABSOLUTE,
  },
  [ESortByType.PROGRESS]: {
    percentage: ESortBy.PROGRESS_PERCENTAGE,
    absolute: ESortBy.PROGRESS_ABSOLUTE,
  },
  [ESortByType.COMPLETED]: {
    percentage: ESortBy.COMPLETED_PERCENTAGE,
    absolute: ESortBy.COMPLETED_ABSOLUTE,
  },
  [ESortByType.SUCCESS]: {
    percentage: ESortBy.SUCCESS_PERCENTAGE,
    absolute: ESortBy.SUCCESS_ABSOLUTE,
  },
};

function* getOrganizationAttributesSaga({ payload }: PayloadAction<IGetAttributesAction>) {
  const { organizationId, onSuccess, onError } = payload;
  const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);

  yield put(
    log({
      event: 'getOrganizationAttributesSaga: start',
      data: {
        organizationId,
      },
    }),
  );

  try {
    const { id: orgId } = yield select(activeOrganizationSelector);
    const sharedWith = yield select(playlistSharedWithFilterSelector);
    const playlistId = yield select(contentIdSelector);

    const {
      data: { attributes },
    } = yield withRetry(
      () =>
        getOrganizationAttributes({
          orgId: organizationId || orgId,
          attributeValueIds: selectedFilterAttributeValueIds,
          playlistIds: [playlistId],
          sharedWith,
        }),
      {
        errorContext: {
          action: 'attributes.saga getOrganizationAttributesSaga',
        },
      },
    );

    if (typeof onSuccess === 'function') {
      onSuccess(attributes);
    }

    yield put(
      log({
        event: 'getOrganizationAttributesSaga: success',
        data: {
          organizationId,
        },
      }),
    );

    yield put(setPlaylistOrganizationAttributes({ attributes }));
  } catch (error) {
    yield put(
      log({
        event: 'getOrganizationAttributesSaga: error',
        data: {
          error,
          // axios case
          errorResponse: error?.response,
          organizationId,
        },
      }),
    );

    if (typeof onError === 'function') {
      onError();
    }
    yield put(setPlaylistOrganizationAttributesError({ error }));
  }
}

const getSelectedExcludeAttributeId = (
  attribute: IPlaylistAttributeMapItem,
  selectedFilterAttributeValueIds: number[],
) => {
  const attributeIdsMap = {};
  attribute.selectedValues.forEach((value: PlaylistAttributeValue) => {
    attributeIdsMap[value.id] = true;
  });
  return selectedFilterAttributeValueIds.filter((id: number) => !attributeIdsMap[id]);
};

function* getFilterListSaga({
  payload: { attributeId, reset, callback },
}: PayloadAction<{ attributeId: number; reset?: boolean; callback: () => void }>) {
  yield put(
    log({
      event: 'getFilterListSaga: start',
      data: {
        attributeId,
        reset,
      },
    }),
  );

  try {
    const { id: orgId } = yield select(activeOrganizationSelector);
    const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);
    const attribute = yield select(filterAttributeByIdSelector(attributeId));
    const sharedWith = yield select(playlistSharedWithFilterSelector);
    const playlistId = yield select(contentIdSelector);

    const attributeValueIds = getSelectedExcludeAttributeId(attribute, selectedFilterAttributeValueIds);

    const { data } = yield withRetry(
      () =>
        getOrganizationAttributes({
          orgId,
          attributeId,
          attributeValueIds,
          sharedWith,
          playlistIds: [playlistId],
          next: !reset ? attribute?.next : undefined,
          pageSize: 20,
        }),
      {
        errorContext: {
          action: 'attributes.saga getFilterListSaga',
        },
      },
    );

    yield put(
      log({
        event: 'getFilterListSaga: success',
        data: {
          attributeId,
          reset,
        },
      }),
    );

    yield put(setFilterList({ attributeId, values: data.attributes?.[0]?.values || [], next: data.next, reset }));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    yield put(
      log({
        event: 'getFilterListSaga: error',
        data: {
          error,
          // axios case
          errorResponse: error?.response,
          attributeId,
          reset,
        },
      }),
    );

    if (reset) {
      yield put(setFilterList({ attributeId, values: null, next: null, reset: true }));
    }
    yield put(setFilterListError({ attributeId, error }));

    if (typeof callback === 'function') {
      callback();
    }
  }
}

function* getSearchListSaga({
  payload: { attributeId, reset, withDebounce, callback },
}: PayloadAction<{ attributeId: number; reset?: boolean; withDebounce?: boolean; callback: () => void }>) {
  yield put(
    log({
      event: 'getSearchListSaga: start',
      data: {
        attributeId,
        reset,
        withDebounce,
      },
    }),
  );

  try {
    if (withDebounce) {
      yield delay(300);
    }
    const { id: orgId } = yield select(activeOrganizationSelector);
    const searchValue = yield select(searchValueSelector);
    const sharedWith = yield select(playlistSharedWithFilterSelector);
    const playlistId = yield select(contentIdSelector);
    const searchValuesNext = yield select(searchValuesNextSelector);

    const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);
    const attribute = yield select(filterAttributeByIdSelector(attributeId));
    const attributeValueIds = getSelectedExcludeAttributeId(attribute, selectedFilterAttributeValueIds);

    const { data } = yield withRetry(
      () =>
        getOrganizationAttributes({
          orgId,
          attributeId,
          attributeValueIds,
          sharedWith,
          playlistIds: [playlistId],
          searchAttributeValue: searchValue,
          next: !reset ? searchValuesNext : undefined,
          pageSize: 20,
        }),
      {
        errorContext: {
          action: 'attributes.saga getSearchListSaga',
        },
      },
    );

    yield put(
      log({
        event: 'getSearchListSaga: success',
        data: {
          attributeId,
          reset,
          withDebounce,
        },
      }),
    );

    yield put(setSearchList({ data: data.attributes, next: data.next, reset }));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    yield put(
      log({
        event: 'getSearchListSaga: error',
        data: {
          error,
          // axios case
          errorResponse: error?.response,
          attributeId,
          reset,
          withDebounce,
        },
      }),
    );

    yield put(setSearchListError({ error: error.message, reset }));

    if (typeof callback === 'function') {
      callback();
    }
  }
}

function* getAttributeSaga({
  attributeId,
  sortBy,
  sortDirection,
  next,
}: {
  attributeId: number;
  sortBy?: ESortBy;
  sortDirection?: ESortDirection;
  next?: string;
}) {
  const { id: orgId } = yield select(activeOrganizationSelector);
  const playlistId = yield select(contentIdSelector);
  const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);
  const sharedWith = yield select(playlistSharedWithFilterSelector);
  const organizationFeatures = yield select(organizationFeaturesSelector);
  const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);

  return yield withRetry(
    () =>
      calls.getPlaylistAnalyticsAttributes({
        orgId,
        playlistIds: [playlistId],
        attributeId,
        attributeValueIds: selectedFilterAttributeValueIds,
        sortBy,
        sortDirection,
        sharedWith,
        allowedDataOnly,
        next,
      }),
    {
      errorContext: {
        action: 'attributes.saga getAttributeSaga',
      },
    },
  );
}

function* getPlaylistAttributeSaga({
  payload: { attributeId, reset },
}: {
  payload: { attributeId: number; reset?: boolean };
}) {
  yield put(
    log({
      event: 'getOverviewAttributeSaga: start',
      data: {
        attributeId,
        reset,
      },
    }),
  );
  try {
    yield put(setAttributeLoading({ attributeId }));
    const displayMode: TDisplayMode = yield select(playlistDisplayModeSelector);
    const sortByType = yield select(sortBySelector);
    const sortDirection = yield select(sortDirectionSelector);

    const sortBy = API_SORT_TYPES_BY_APP_SORT_TYPE[sortByType][displayMode];

    const next = yield select(playlistAttributeNextSelector(attributeId, displayMode));
    const { data } = yield getAttributeSaga({
      attributeId,
      sortBy,
      sortDirection,
      next: !reset ? next : undefined,
    });

    yield put(
      log({
        event: 'getOverviewAttributeSaga: success',
        data: {
          attributeId,
          reset,
        },
      }),
    );

    yield put(setAttribute({ attributeId, values: data.results?.values || [], next: data.next, reset }));
  } catch (error) {
    yield put(
      log({
        event: 'getOverviewAttributeSaga: error',
        data: {
          error,
          // axios case
          errorResponse: error?.response,
          attributeId,
          reset,
        },
      }),
    );

    yield put(setAttributeError({ attributeId, error, reset }));
  }
}

function* getPlaylistAttributesSaga({
  payload: { attributeIds, clearAnalyticsCache, reset, callback },
}: PayloadAction<IGetAttributesPayload>) {
  if (clearAnalyticsCache) {
    yield put(
      resetAnalytics({
        excludeFields: {
          analytics: ['contentId', 'contentType', 'hasDistributions', 'isFilterTooltipShown'],
          playlistAttributes: ['guestsAttribute', { field: 'attributesMapById', attributeIds }],
        },
      }),
    );
  }

  const getGuests = () => {
    if (!clearAnalyticsCache) {
      return null;
    }
    return getGuestsAttributeSaga();
  };

  yield all(
    [getGuests, ...attributeIds.map((id) => getPlaylistAttributeSaga({ payload: { attributeId: id, reset } }))].filter(
      Boolean,
    ),
  );

  yield put(setIsRefreshing(false));

  if (typeof callback === 'function') {
    callback();
  }
}

function* getGuestsAttributeSaga() {
  const { id: orgId } = yield select(activeOrganizationSelector);
  const playlistId = yield select(contentIdSelector);
  const organizationFeatures = yield select(organizationFeaturesSelector);
  const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);

  yield put(
    log({
      event: 'getGuestsAttributeSaga: start',
      data: {
        orgId,
        playlistId,
      },
    }),
  );

  try {
    yield put(setGuestsAttributeLoading());
    const { data } = yield withRetry(
      () =>
        calls.getPlaylistGuestsAttribute({
          orgId,
          playlistIds: [playlistId],
          allowedDataOnly,
        }),
      {
        errorContext: {
          action: 'attributes.saga getGuestsAttributeSaga',
        },
      },
    );

    yield put(
      log({
        event: 'getGuestsAttributeSaga: success',
        data: {
          orgId,
          playlistId,
        },
      }),
    );

    yield put(setGuestsAttribute({ attribute: data }));
  } catch (error) {
    yield put(setGuestsAttributeError({ error }));

    yield put(
      log({
        event: 'getGuestsAttributeSaga: error',
        data: {
          error,
          // axios case
          errorResponse: error?.response,
        },
      }),
    );
  }
}

export default function* playlistAttributesSaga() {
  yield all([
    takeLatest(getPlaylistOrganizationAttributes, getOrganizationAttributesSaga),
    takeLatest(getFilterList, getFilterListSaga),
    takeLatest(getSearchList, getSearchListSaga),

    takeLatest(getPlaylistAttributes, getPlaylistAttributesSaga),

    takeLatest(getPlaylistGuestsAttribute, getGuestsAttributeSaga),
  ]);
}
