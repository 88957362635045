import React, { memo } from 'react';
import { PreviewContent, PreviewControls } from './Preview';
import SidebarTitle from '../SidebarTitle';

type Props = {
  url: string;
  onAdd: () => void;
  onReplace: () => void;
  onClose: () => void;
};
export const PreviewAudio = memo(({ url, onAdd, onReplace, onClose }: Props) => {
  return (
    <>
      <SidebarTitle title='Audio' bold={false} />
      <PreviewContent>
        <audio src={url} controls />
      </PreviewContent>
      <PreviewControls onAdd={onAdd} onReplace={onReplace} onClose={onClose} />
    </>
  );
});
