import { EQuestionType, IBiteAnswer } from '../../../types/biteQuestion';
import { IInitialState as AnalyticsInitialState } from '../../analytics/analytics.types';
import { IInitialState as BiteAttributesInitialState } from '../biteAttributes/biteAttributes.types';
import { IInitialState as PlaylistAttributesInitialState } from '../playlistAttributes/playlistAttributes.types';
import { IInitialState as PlaylistStatsInitialState } from '../playlistStats/playlistStats.types';

export interface IInitialState {
  stats: {
    aggregatedAnswers: {
      isLoading: boolean;
      error: string | null;
      data: {
        question: IAnalyticsBiteQuestion;
        aggregated: AggregatedAnswers;
      };
    };
    overview: {
      isNeedToScrollUp: boolean;
      error: string | null;
      filter: EEngagedFilterValue | null;
      isLoading: boolean;
      data: IAnalyticsViewsListItem[];
      next: null;
    };
    views: {
      isNeedToScrollUp: boolean;
      error: string | null;
      filter: EEngagedFilterValue | null;
      isLoading: boolean;
      data: IAnalyticsViewsListItem[];
      next: null;
    };
    answers: {
      isNeedToScrollUp: boolean;
      isLoading: boolean;
      error: string | null;
      data: IAnalyticsAnswersListItem[];
      filter: {
        answerIds: number[];
        answered: EAnsweredFilterValue | null;
      };
      next: null;
    };
    comments: {
      isNeedToScrollUp: boolean;
      isLoading: boolean;
      error: string | null;
      data: IAnalyticsCommentsListItem[];
      filter: ECommentedFilterValue | null;
      next: null;
    };
  };
  overview: {
    views: {
      isLoading: boolean;
      isRequestInProgress: boolean;
      error: string | null;
      data: number;
    };
    comments: {
      isLoading: boolean;
      isRequestInProgress: boolean;
      error: string | null;
      data: number;
    };
    answers: {
      isLoading: boolean;
      isRequestInProgress: boolean;
      error: string | null;
      data: number;
    };
  };
}

export type TFilter = IViewsFilter | IOverviewFilter | IAnsweredFilter | ICommentsFilter;

export type TFilterValue = EEngagedFilterValue | EAnsweredFilterValue | ECommentedFilterValue;

export interface IOverviewFilter {
  label: string;
  value: EEngagedFilterValue;
}

export interface IViewsFilter {
  label: string;
  value: EEngagedFilterValue;
}

export interface IAnsweredFilter {
  label: string;
  value: EAnsweredFilterValue;
}

export interface ICommentsFilter {
  label: string;
  value: ECommentedFilterValue;
}

export enum EEngagedFilterValue {
  ALL = 'all',
  ENGAGED = 'true',
  NOT_ENGAGED = 'false',
}

export enum EAnsweredValue {
  NOT_ANSWERED = 'NOT_ANSWERED',
  ANSWERED = 'ANSWERED',
  ANSWERED_CORRECT = 'ANSWERED_CORRECT',
  ANSWERED_INCORRECT = 'ANSWERED_INCORRECT',
}

export enum EAnsweredFilterValue {
  ALL = 'all',
  ANSWERED = 'true',
  NOT_ANSWERED = 'false',
  CORRECTLY = 'correctly',
  INCORRECTLY = 'incorrectly',
}

export enum ECommentedFilterValue {
  ALL = 'all',
  COMMENTED = 'true',
  NOT_COMMENTED = 'false',
}

export type AggregatedAnswers = {
  [key: string]: {
    percentage: number;
    absolute: number;
  };
};

export interface IAnalyticsViewsListItem extends IAnalyticsListItem {
  activity: {
    first_interaction_date: string;
    last_interaction_date: string;
    view_intro_date?: string;
    video_progress?: number;
    answer?: EAnsweredFilterValue;
    commented?: boolean;
  };
}

export interface IAnalyticsAnswersListItem extends IAnalyticsListItem {
  activity: {
    first_interaction_date: string;
    last_interaction_date: string;
    answer: EAnsweredValue;
    answer_date?: string;
    answer_text?: string;
    choice_ids?: number[];
    answer_media_url?: string;
  };
}

export interface IAnalyticsCommentsListItem extends IAnalyticsListItem {
  activity: {
    first_interaction_date: string;
    last_interaction_date: string;
    commented?: boolean;
    comment_date?: string;
  };
}

export interface IAnalyticsUser {
  id?: number;
  first_name?: string;
  last_name?: string;
  profile_image?: string;
  user_organization_id?: string;
  guest_id?: string;
  guest_alias?: string;
}

export interface IAnalyticsListItem {
  bite_id: number;
  user: IAnalyticsUser;
}

export interface IAnalyticsBiteQuestion {
  id: number;
  text: string;
  type: EQuestionType;
  choices: IBiteAnswer[];
  created_at: Date;
  is_multi_choice: boolean;
  correct_choice_id?: number;
  bite_section?: number;
}

export type TExcludeFields = {
  biteStats?: (keyof IInitialState['stats'])[];
  analytics?: (keyof AnalyticsInitialState)[];
  biteAttributes?: (
    | keyof BiteAttributesInitialState
    | {
        field:
          | 'overviewAttributesMapById'
          | 'viewsAttributesMapById'
          | 'answersAttributesMapById'
          | 'commentsAttributesMapById';
        attributeIds: number[];
      }
  )[];
  playlistAttributes?: (
    | keyof PlaylistAttributesInitialState
    | {
        field: 'attributesMapById';
        attributeIds: number[];
      }
  )[];
  playlistStats?: (keyof PlaylistStatsInitialState)[];
};

export interface IAnalyticsViewsListItem extends IAnalyticsListItem {
  activity: {
    first_interaction_date: string;
    last_interaction_date: string;
    view_intro_date?: string;
    video_progress?: number;
    answer?: EAnsweredFilterValue;
    commented?: boolean;
  };
}

export interface IAnalyticsAnswersListItem extends IAnalyticsListItem {
  activity: {
    first_interaction_date: string;
    last_interaction_date: string;
    answer: EAnsweredValue;
    answer_date?: string;
    answer_text?: string;
    choice_ids?: number[];
    answer_media_url?: string;
  };
}

export interface IAnalyticsCommentsListItem extends IAnalyticsListItem {
  activity: {
    first_interaction_date: string;
    last_interaction_date: string;
    commented?: boolean;
    comment_date?: string;
  };
}

export interface IAnalyticsUser {
  id?: number;
  first_name?: string;
  last_name?: string;
  profile_image?: string;
  user_organization_id?: string;
  guest_id?: string;
  guest_alias?: string;
}

export interface IAnalyticsListItem {
  bite_id: number;
  user: IAnalyticsUser;
}
