import {
  ESharedWithFilter,
  ESortingDirection,
  IOrganizationAttribute,
  IOrganizationAttributeMap,
  IOrganizationAttributeValue,
  TDisplayMode,
} from '../../../types/anayltics';

export interface IInitialState {
  displayMode: TDisplayMode;
  overviewAttributesMapById: {
    sortBy: ESortByType;
    sortDirection: ESortingDirection;
    absolute: IBiteAttributeMap;
    percentage: IBiteAttributeMap;
    isGuestsAttributesLoaded: boolean;
  };
  viewsAttributesMapById: {
    sortBy: ESortByType;
    sortDirection: ESortingDirection;
    absolute: IBiteAttributeMap;
    percentage: IBiteAttributeMap;
    isGuestsAttributesLoaded: boolean;
  };
  answersAttributesMapById: {
    sortBy: ESortByType;
    sortDirection: ESortingDirection;
    absolute: IBiteAttributeMap;
    percentage: IBiteAttributeMap;
    isGuestsAttributesLoaded: boolean;
  };
  commentsAttributesMapById: {
    sortBy: ESortByType;
    sortDirection: ESortingDirection;
    absolute: IBiteAttributeMap;
    percentage: IBiteAttributeMap;
    isGuestsAttributesLoaded: boolean;
  };
  selectedValueIds: number[];
  filterAttributesMapById: IOrganizationAttributeMap;
  initialAttributesMapById: IBiteAttributeMap;
  sharedWithFilter: ESharedWithFilter[];
  filterSearchValue: string;
  searchValues: {
    isLoading: boolean;
    error?: string;
    data: IOrganizationAttributeValue[];
    next: null | string;
  };

  organizationAttributes: {
    isLoading: boolean;
    error?: string;
    data: IOrganizationAttribute[];
  };

  guestsAttribute: {
    isLoading: boolean;
    error?: string;
    data: IBiteAttributeValue;
  };
  guestsAttributeAnswersWithOption: {
    isLoading: boolean;
    error?: string;
    data: IBiteAttributeValue;
  };
}

export interface IBiteAttributeMapItem {
  isLoading: boolean;
  error?: string;
  data: IBiteAttribute;
  selectedValues?: IBiteAttributeValue[];
  next?: string;
}

export interface IBiteAttributeMap {
  [key: number]: IBiteAttributeMapItem;
}

export interface IBiteAttributeValue {
  id: number;
  name: string;
  attribute_title_id: number;
  answers_absolute: number;
  views_absolute: number;
  comments_absolute: number;
  total_users_count: number;
}

export interface IBiteAttribute {
  title: string;
  index: number;
  id: number;
  organization_id: number;
  values: IBiteAttributeValue[];
}

export enum EAggregatedFields {
  VIEWS = 'views',
  ANSWERS = 'answers',
  COMMENTS = 'comments',
  TOTAL_USERS_COUNT = 'total_users_count',
}

export enum ESortBy {
  VIEWS_ABSOLUTE = 'views_absolute',
  VIEWS_PERCENTAGE = 'views_percentage',
  ANSWERS_ABSOLUTE = 'answers_absolute',
  ANSWERS_PERCENTAGE = 'answers_percentage',
  COMMENTS_ABSOLUTE = 'comments_absolute',
  COMMENTS_PERCENTAGE = 'comments_percentage',
}

export enum ESortByType {
  VIEWS = 'views',
  ANSWERS = 'answers',
  COMMENTS = 'comments',
}
