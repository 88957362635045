import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { IOrganizationAttributeValue } from '../../../types/anayltics';

const useSelectedValues = (
  initialState: IOrganizationAttributeValue[],
): [
  IOrganizationAttributeValue[],
  Dispatch<SetStateAction<IOrganizationAttributeValue[]>>,
  { [key: string]: boolean },
] => {
  const [selectedValues, setSelectedValues] = useState(initialState);
  const [selectedValuesMap, setSelectedValuesMap] = useState(() =>
    initialState.reduce((map, { id }) => {
      map[id] = true;
      return map;
    }, {}),
  );

  const handleUpdateState = useCallback((values) => {
    setSelectedValues(values);
    setSelectedValuesMap(() =>
      values.reduce((map, { id }) => {
        map[id] = true;
        return map;
      }, {}),
    );
  }, []);

  return [selectedValues, handleUpdateState, selectedValuesMap];
};

export default useSelectedValues;
