import { getIsWebRtl } from '../../../locale/i18n';
import dayjs from 'dayjs';
import React, { memo } from 'react';
import styled from 'styled-components/native';
import OwnerInitials from '../../../components/feed/biteItemOverlays/OwnerInitials';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import { IPlaylistAnalyticsUser } from '../../../store/attributes/playlistStats/playlistStats.types';

const OWNER_INITIALS_SIZE = calcWidth(44);

interface IProps {
  user: IPlaylistAnalyticsUser;
}

const UserCard: React.FC<IProps> = ({ user }) => {
  const isWebRtl = getIsWebRtl();

  const isGuest = !!user.guest_id;
  const userName = isGuest
    ? user.guest_alias
    : `${user.first_name ? user.first_name + ' ' : ''}${user.last_name || ''}`;
  const profileImage = user.profile_image;
  const lastActivityDate = dayjs(user.last_interaction_date);
  const formattedLastActivity = lastActivityDate.isToday()
    ? lastActivityDate.format('HH:mm')
    : lastActivityDate.fromNow();

  return (
    <S.Container isRtl={isWebRtl}>
      <S.OwnerInitials
        size={OWNER_INITIALS_SIZE}
        name={userName}
        photo={profileImage}
        isGrayed={!user.last_interaction_date}
      />
      <S.TextContainer>
        <S.NameContainer isRtl={isWebRtl}>
          {user.last_interaction_date ? (
            <>
              <S.LastActivityText>{formattedLastActivity}</S.LastActivityText>
              <S.NameText>{userName}</S.NameText>
            </>
          ) : (
            <>
              <S.LastActivityTextGrayed>---</S.LastActivityTextGrayed>
              <S.NameTextGrayed>{userName}</S.NameTextGrayed>
            </>
          )}
        </S.NameContainer>
      </S.TextContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.View<{ isRtl?: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
  `,
  OwnerInitials: styled(OwnerInitials)`
    border-radius: 100px;
  `,
  TextContainer: styled.View`
    justify-content: center;
    flex-direction: column;
  `,
  NameContainer: styled.View<{ isRtl: boolean }>`
    align-items: ${({ isRtl }) => (isRtl ? 'flex-end' : 'flex-start')};
    margin: 0 ${calcWidth(8)}px;
  `,
  LastActivityText: styled.Text`
    height: ${calcHeight(15)}px;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s13};
    font-weight: 400;
    text-align: left;
  `,
  LastActivityTextGrayed: styled.Text`
    height: ${calcHeight(15)}px;
    color: ${({ theme }) => theme.colors.gray19};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s13};
    font-weight: 400;
    text-align: left;
  `,
  NameText: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s13};
    font-weight: 700;
  `,
  NameTextGrayed: styled.Text`
    color: ${({ theme }) => theme.colors.gray19};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s13};
    font-weight: 700;
  `,
};

export default memo(UserCard);
