import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
  getPlaylistDistributions,
  getPlaylistStatsOverview,
  getUsersData,
  setPlaylistStatsOverview,
  setPlaylistStatsOverviewError,
} from './playlistStats.slice';
import * as calls from '../../api/bites-api/calls/analytics.calls';
import { contentIdSelector } from '../../analytics/analytics.selector';
import { activeOrganizationSelector } from '../../auth/auth.selectors';
import { FEATURE_FLAG_IDS } from '../../../utils/constants/org';
import { organizationFeaturesSelector } from '../../org/org.selectors';
import { log } from '../../appActivity/appActivity.slice';
import withRetry from '../../../utils/withRetry';
import { getPlaylistDistributions as getPlaylistDistributionsService } from '../../api/bites-api/calls/analytics.calls';
import { resetAnalytics, setHasDistributions, setIsRefreshing } from '../../analytics/analytics.slice';
import { setSharedWithFilter } from '../playlistAttributes/playlistAttributes.slice';
import { ESharedWithFilter } from '../../../types/anayltics';
import {
  usersNextSelector,
  usersSortBySelector,
  usersSortDirectionSelector,
} from '../playlistStats/playlistStats.selector';
import {
  playlistDisplayModeSelector,
  playlistSharedWithFilterSelector,
  selectedFilterAttributeValueIdsSelector,
} from '../playlistAttributes/playlistAttributes.selector';
import { setUsersData, setUsersDataError } from '../playlistStats/playlistStats.slice';
import { ESortBy, ESortByType } from '../playlistAttributes/playlistAttributes.types';

function* getPlaylistStatsOverviewSaga() {
  const playlistId = yield select(contentIdSelector);
  const { id: orgId } = yield select(activeOrganizationSelector);
  const organizationFeatures = yield select(organizationFeaturesSelector);
  const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);

  try {
    const { data } = yield calls.getPlaylistStatsOverview({
      orgId,
      playlistIds: [playlistId],
      allowedDataOnly,
    });

    yield put(setPlaylistStatsOverview(data));
  } catch (error) {
    yield put(setPlaylistStatsOverviewError(error));
  }
}

function* getPlaylistDistributionsSaga() {
  yield put(
    log({
      event: 'getPlaylistDistributionsSaga: start',
    }),
  );
  try {
    const { id: orgId } = yield select(activeOrganizationSelector);
    const playlistId = yield select(contentIdSelector);

    const {
      data: { distributions },
    } = yield withRetry(() => getPlaylistDistributionsService({ orgId, playlistId }), {
      errorContext: {
        action: 'playlistStats.saga getPlaylistDistributionsSaga',
      },
    });

    yield put(
      log({
        event: 'getPlaylistDistributionsSaga: success',
      }),
    );

    yield put(setHasDistributions(!!distributions.length));

    if (distributions.length) {
      yield put(setSharedWithFilter([ESharedWithFilter.COMMUNICATION_HUB, ESharedWithFilter.ORGANICALLY]));
    }
  } catch (error) {
    yield put(
      log({
        event: 'getPlaylistDistributionsSaga: error',
        data: {
          error,
          // axios case
          errorResponse: error?.response,
        },
      }),
    );

    yield put(setHasDistributions(true));
  }
}

const SUCCESS_SORT_BY = {
  absolute: ESortBy.SUCCESS_ABSOLUTE,
  percentage: ESortBy.SUCCESS_PERCENTAGE,
};

function* getUsersDataSaga({ payload }) {
  const reset = payload?.reset;
  const clearAnalyticsCache = payload?.clearAnalyticsCache;
  try {
    const playlistId = yield select(contentIdSelector);
    const { id: orgId } = yield select(activeOrganizationSelector);
    const next = yield select(usersNextSelector);
    const displayMode = yield select(playlistDisplayModeSelector);
    const sortBy = yield select(usersSortBySelector);
    const sortDirection = yield select(usersSortDirectionSelector);
    const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);
    const sharedWith = yield select(playlistSharedWithFilterSelector);
    const organizationFeatures = yield select(organizationFeaturesSelector);
    const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);

    const formattedSortBy = sortBy === ESortByType.SUCCESS ? SUCCESS_SORT_BY[displayMode] : sortBy;

    if (clearAnalyticsCache) {
      yield put(
        resetAnalytics({
          excludeFields: {
            analytics: [
              'contentId',
              'contentType',
              'currentList',
              'isRefreshing',
              'hasDistributions',
              'isFilterTooltipShown',
            ],
            playlistStats: ['overview'],
          },
        }),
      );
    }

    const { data } = yield withRetry(
      () =>
        calls.getPlaylistUsersList({
          orgId,
          playlistIds: [playlistId],
          attributeValueIds: selectedFilterAttributeValueIds,
          sharedWith,
          allowedDataOnly,
          sortBy: formattedSortBy,
          sortDirection,
          pageSize: 10,
          next,
        }),
      {
        errorContext: {
          action: 'biteStats.saga getOverviewDataSaga',
        },
      },
    );

    yield put(setUsersData({ ...data, reset }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  } catch (error) {
    if (clearAnalyticsCache) {
      resetAnalytics({
        excludeFields: {
          analytics: ['contentId', 'contentType', 'currentList', 'hasDistributions', 'isFilterTooltipShown'],
        },
      });
    }

    if (reset) {
      yield put(setUsersData({ results: [], next: null, reset: true }));
    }
    yield put(setUsersDataError({ error }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  }
}

export default function* playlistStatsSaga() {
  yield all([
    takeLatest(getPlaylistStatsOverview, getPlaylistStatsOverviewSaga),
    takeLatest(getPlaylistDistributions, getPlaylistDistributionsSaga),
    takeLatest(getUsersData, getUsersDataSaga),
  ]);
}
