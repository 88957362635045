import { AWS_URL } from './constants/env';

export type Voice = {
  id: string;
  avatar: string;
  name: string;
  sample: string;
  description: string;
};
export const VOICES_LIST: Voice[] = [
  {
    id: 'e3SQYxMM1v1apbaHnt8w',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/evgeniya/evgeniya.png`,
    name: 'Angelina',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/evgeniya/evgeniya.mp3`,
    description: 'American, Calm, Young, Female, Narrative & Story',
  },
  {
    id: 'Xb7hH8MSUJpSbSDYk0k2',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/alice/alice.png`,
    name: 'Alice',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/alice/alice.mp3`,
    description: 'British, Confident, Middle-aged, News',
  },
  {
    id: '9BWtsMINqrJLrRacOk9x',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/aria/aria.png`,
    name: 'Aria',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/aria/aria.mp3`,
    description: 'American, Expressive, Middle-aged, Social media',
  },
  {
    id: 'pqHfZKP75CvOlQylNhV4',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/bill/bill.png`,
    name: 'Bill',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/bill/bill.mp3`,
    description: 'American, Trustworthy, Old, Narration',
  },
  {
    id: 'nPczCjzI2devNBz1zQrb',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/brian/brian.png`,
    name: 'Brian',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/brian/brian.mp3`,
    description: 'American, Deep, Middle-aged, Narration',
  },
  {
    id: 'N2lVS1w4EtoT3dr4eOWO',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/callum/callum.png`,
    name: 'Callum',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/callum/callum.mp3`,
    description: 'Transatlantic, Intense, Middle-aged, Characters',
  },
  {
    id: 'IKne3meq5aSn9XLyUdCD',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/charlie/charlie.png`,
    name: 'Charlie',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/charlie/charlie.mp3`,
    description: 'Australian, Natural, Middle aged, Conversational',
  },
  {
    id: 'XB0fDUnXU5powFXDhCwa',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/charlotte/charlotte.png`,
    name: 'Charlotte',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/charlotte/charlotte.mp3`,
    description: 'Swedish, Seductive, Young, Characters',
  },
  {
    id: 'iP95p4xoKVk53GoZ742B',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/chris/chris.png`,
    name: 'Chris',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/chris/chris.mp3`,
    description: 'American, Casual, Middle-aged, Conversational',
  },
  {
    id: 'onwK4e9ZLuTAKqWW03F9',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/daniel/daniel.png`,
    name: 'Daniel',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/daniel/daniel.mp3`,
    description: 'British, Authoritative, Middle-aged, News',
  },
  {
    id: 'cjVigY5qzO86Huf0OWal',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/eric/eric.png`,
    name: 'Eric',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/eric/eric.mp3`,
    description: 'American, Friendly, Middle-aged, Conversational',
  },
  {
    id: 'JBFqnCBsd6RMkjVDRZzb',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/george/george.png`,
    name: 'George',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/george/george.mp3`,
    description: 'British, Warm, Middle aged, Narration',
  },
  {
    id: 'cgSgspJ2msm6clMCkdW9',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/jessica/jessica.png`,
    name: 'Jessica',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/jessica/jessica.mp3`,
    description: 'American, Expressive, Young, Conversational',
  },
  {
    id: 'FGY2WhTYpPnrIDTdsKH5',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/laura/laura.png`,
    name: 'Laura',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/laura/laura.mp3`,
    description: 'American, Upbeat, Young, Social media',
  },
  {
    id: 'TX3LPaxmHKxFdv7VOQHJ',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/liam/liam.png`,
    name: 'Liam',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/liam/liam.mp3`,
    description: 'American, Articulate, Young, Narration',
  },
  {
    id: 'pFZP5JQG7iQjIQuC4Bku',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/lily/lily.png`,
    name: 'Lily',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/lily/lily.mp3`,
    description: 'British, Warm, Middle-aged, Narration',
  },
  {
    id: 'XrExE9yKIg1WjnnlVkGX',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/matilda/matilda.png`,
    name: 'Matilda',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/matilda/matilda.mp3`,
    description: 'American, Friendly, Middle-aged, Narration',
  },
  {
    id: 'SAz9YHcvj6GT2YYXdXww',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/river/river.png`,
    name: 'River',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/river/river.mp3`,
    description: 'American, Confident, Middle-aged, Social media',
  },
  {
    id: 'CwhRBWXzGAHq8TQ4Fs17',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/roger/roger.png`,
    name: 'Roger',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/roger/roger.mp3`,
    description: 'American, Confident, Middle-aged, Social media',
  },
  {
    id: 'EXAVITQu4vr4xnSDxMaL',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/sarah/sarah.png`,
    name: 'Sarah',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/sarah/sarah.mp3`,
    description: 'American, Soft, Young, News',
  },
  {
    id: 'bIHbv24MWmeRgasZH58o',
    avatar: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/will/will.png`,
    name: 'Will',
    sample: `${AWS_URL}/staticfiles/bites-ui/ai-content/speakers/will/will.mp3`,
    description: 'American, Friendly, Young, Social media',
  },
];
