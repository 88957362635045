import { useDispatch, useSelector } from 'react-redux';
import { contentIdSelector, currentListSelector } from '../../../../store/analytics/analytics.selector';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Filter from '../common/Filter';
import AnswersAggregation from '../common/AnswersAggregation';
import { AnsweredIcons } from '../common/Icons';
import { QuestionSection } from '../../../../types/bite';
import { biteItemSelector } from '../../../../store/bite/bite.selectors';
import { EQuestionType, IBiteAnswer } from '../../../../types/biteQuestion';
import ListItemComponent from '../common/ListItem/ListItem';
import AnalyticsList from '../../common/AnalyticsList';
import {
  EAnsweredFilterValue,
  IAnalyticsAnswersListItem,
} from '../../../../store/attributes/biteStats/biteStats.types';
import { useIsFocused } from '@react-navigation/native';
import HeaderTabs from '../../common/HeaderTabs';
import { log } from '../../../../store/appActivity/appActivity.slice';
import {
  getAnswersAttributes,
  resetAnswersAttributes,
} from '../../../../store/attributes/biteAttributes/biteAttributes.slice';
import useHasAttributesToRender from '../../hooks/useHasAttributesToRender';
import useAnsweredAttributesMap from '../hooks/useAnsweredAttributesMap';
import useHasOrganizationAttributes from '../../hooks/useHasOrganizationAttributes';
import { IStackNavigation } from '../../../../navigation/types';
import useAttributesData from '../../hooks/useAttributesData';
import { EAnalyticsScreenType } from '../../Analytics.types';
import { ANSWERED_ATTRIBUTES_SCREEN, ANSWERED_LIST_SCREEN } from '../constants';
import useHeaderTabsDivider from '../../hooks/useHeaderTabsDivider';
import { analyticsListDataSet } from '../../analytics.constants';
import {
  answersAggregatedErrorSelector,
  answersDataSelector,
  answersErrorSelector,
  answersFilterSelector,
  answersIdsFilterSelector,
  answersLoadingSelector,
  answersNextSelector,
  isNeedToScrollUpAnswersSelector,
} from '../../../../store/attributes/biteStats/biteStats.selector';
import {
  getAnswersAggregated,
  getAnswersData,
  setAnswersData,
  setAnswersFilter,
  setIsNeedToScrollUp,
} from '../../../../store/attributes/biteStats/biteStats.slice';
import Error from '../../../stats/common/Error';
import { ECurrentList } from '../../../../store/analytics/analytics.types';
import { selectedFilterAttributeValueIdsSelector } from '../../../../store/attributes/biteAttributes/biteAttributes.selector';

const ANSWERED_CORRECT_FILTERS = [
  {
    label: 'analytics.filter.all',
    value: EAnsweredFilterValue.ALL,
  },
  {
    label: 'analytics.filter.answeredCorrectly',
    value: EAnsweredFilterValue.CORRECTLY,
  },
  {
    label: 'analytics.filter.answeredIncorrectly',
    value: EAnsweredFilterValue.INCORRECTLY,
  },
  {
    label: 'analytics.filter.notAnswered',
    value: EAnsweredFilterValue.NOT_ANSWERED,
  },
];

const ANSWERED_FILTERS = [
  {
    label: 'analytics.filter.all',
    value: EAnsweredFilterValue.ALL,
  },
  {
    label: 'analytics.filter.answered',
    value: EAnsweredFilterValue.ANSWERED,
  },
  {
    label: 'analytics.filter.notAnswered',
    value: EAnsweredFilterValue.NOT_ANSWERED,
  },
];

const AnsweredList: React.FC<IStackNavigation> = ({ navigation }) => {
  const dispatch = useDispatch();

  const isFocused = useIsFocused();
  const isLoading = useSelector(answersLoadingSelector);
  const answeredList = useSelector(answersDataSelector);
  const currentFilter = useSelector(answersFilterSelector);
  const answeredNext = useSelector(answersNextSelector);
  const answeredError = useSelector(answersErrorSelector);
  const answersAggregatedError = useSelector(answersAggregatedErrorSelector);
  const biteId = useSelector(contentIdSelector);
  const bite = useSelector(biteItemSelector(biteId));
  const selectedAnswers = useSelector(answersIdsFilterSelector);
  const currentList = useSelector(currentListSelector);
  const { hasOrganizationAttributes } = useHasOrganizationAttributes();
  const isNeedToScrollUp = useSelector(isNeedToScrollUpAnswersSelector);

  const selectedValueIds = useSelector(selectedFilterAttributeValueIdsSelector);

  const [scrollHandleOffset, setScrollHandleOffset] = useState(null);

  const { attributesMap } = useAnsweredAttributesMap();
  const { hasAttributesToRender } = useHasAttributesToRender({ attributesMap });
  const { handleScroll, isShowHeaderBottomDivider } = useHeaderTabsDivider(scrollHandleOffset);

  useAttributesData({
    attributesMap,
    onLoad: getAnswersAttributes,
  });

  const handleSetIsNeedToScrollUp = useCallback(
    (props) => {
      dispatch(setIsNeedToScrollUp(props));
    },
    [dispatch],
  );

  const [isRefreshing, setIsRefreshing] = useState(false);

  const formattedData = useMemo(() => (answeredList ? [null, ...answeredList] : undefined), [answeredList]);

  const question = useMemo(() => {
    const quest = bite?.bite_sections?.find((section) => section.type === 'question') as QuestionSection;
    return quest?.questions[0];
  }, [bite]);

  const sortedChoices = useMemo(() => {
    if (!question || question.type === EQuestionType.OPEN_ENDED) {
      return null;
    }

    return question.choices.slice().sort((a, b) => a.order - b.order);
  }, [question]);

  const handleRefresh = useCallback(
    ({ isPullToRefresh, callback }) => {
      dispatch(
        log({
          event: 'AnsweredList.handleRefresh',
        }),
      );

      setIsRefreshing(true);
      dispatch(getAnswersAggregated());
      dispatch(
        getAnswersData({
          reset: true,
          clearAnalyticsCache: isPullToRefresh,
          callback: () => {
            setIsRefreshing(false);

            if (typeof callback === 'function') {
              callback();
            }
          },
        }),
      );
    },
    [dispatch],
  );

  const handleFilterChange = useCallback(
    (filter) => {
      dispatch(
        log({
          event: 'AnsweredList.handleFilterChange',
          data: { filter },
        }),
      );
      dispatch(setAnswersData({ results: null, next: null, reset: true }));
      dispatch(setAnswersFilter(filter));
      dispatch(getAnswersData());
    },
    [dispatch],
  );

  const handleAnswerAggregationLayout = useCallback((event) => {
    setScrollHandleOffset(event.nativeEvent.layout.height);
  }, []);

  const handleAnswerSelect = useCallback(() => {
    dispatch(setAnswersData({ results: null, next: null, reset: true }));
    dispatch(resetAnswersAttributes());
  }, [dispatch]);

  const renderListItem = useCallback(
    ({ item, index }) => {
      if (index === 0) {
        const filters = question.type === EQuestionType.MULTI_CHOICE ? ANSWERED_CORRECT_FILTERS : ANSWERED_FILTERS;

        return (
          <>
            {!!selectedAnswers.length && (
              <AnswersAggregation
                onLayout={handleAnswerAggregationLayout}
                isRefreshing={isRefreshing}
                onSelect={handleAnswerSelect}
              />
            )}
            <HeaderTabs
              currentScreen={ANSWERED_LIST_SCREEN}
              attributesScreen={ANSWERED_ATTRIBUTES_SCREEN}
              listScreen={ANSWERED_LIST_SCREEN}
              disableAttributes={!hasAttributesToRender}
              rightComponent={
                !selectedAnswers.length ? (
                  <Filter currentFilter={currentFilter} filters={filters} onChangeFilter={handleFilterChange} />
                ) : undefined
              }
              isShowBottomDivider={isShowHeaderBottomDivider}
            />
          </>
        );
      }

      const withDivider = index > 1 && !item.activity && !!answeredList[index - 2]?.activity;
      return <ListItem item={item} withDivider={withDivider} sortedChoices={sortedChoices} />;
    },
    [
      answeredList,
      currentFilter,
      handleAnswerAggregationLayout,
      handleAnswerSelect,
      handleFilterChange,
      hasAttributesToRender,
      isRefreshing,
      isShowHeaderBottomDivider,
      question.type,
      selectedAnswers.length,
      sortedChoices,
    ],
  );

  const stickyHeaderIndices = useMemo(() => {
    return !selectedAnswers.length ? [1] : [0];
  }, [selectedAnswers.length]);

  const handleEndReached = useCallback(() => {
    dispatch(
      log({
        event: 'AnsweredList.handleEndReached',
        data: { answeredNext, isLoading, answeredError },
      }),
    );

    dispatch(getAnswersData());
  }, [answeredError, answeredNext, dispatch, isLoading]);

  const handleEndReachedProp = useMemo(() => {
    if (!answeredNext || isLoading || answeredError) {
      return;
    }

    return handleEndReached;
  }, [answeredError, answeredNext, handleEndReached, isLoading]);

  const handleGetData = useCallback(() => {
    dispatch(
      log({
        event: 'AnsweredList.handleGetData',
      }),
    );
    dispatch(getAnswersAggregated());
    dispatch(getAnswersData({ reset: true }));
  }, [dispatch]);

  const handleErrorRefresh = useCallback(() => {
    dispatch(
      log({
        event: 'AnsweredList.handleErrorRefresh',
      }),
    );
    dispatch(getAnswersData());
  }, [dispatch]);

  const switchedToFocusRef = useRef(isFocused);

  useEffect(() => {
    const switchedToFocus = isFocused && !switchedToFocusRef.current;
    switchedToFocusRef.current = isFocused;

    if (isLoading || !isFocused || (answeredList !== null && (!answeredError || !switchedToFocus))) {
      return;
    }

    dispatch(getAnswersData({ reset: !answeredError }));
  }, [answeredError, answeredList, dispatch, isFocused, isLoading]);

  useEffect(() => {
    if (currentList === ECurrentList.ATTRIBUTES && hasOrganizationAttributes) {
      navigation.replace(ANSWERED_ATTRIBUTES_SCREEN);
    }
  }, [currentList, hasOrganizationAttributes, navigation]);

  if (answeredError && answersAggregatedError) {
    return <Error onRefresh={handleGetData} />;
  }

  return (
    <AnalyticsList
      dataSet={analyticsListDataSet}
      stickyHeaderIndices={stickyHeaderIndices}
      // removeClippedSubviews is true by default on Android
      // without it content disappears sometimes
      // or a crash happens
      // Note: May have bugs (missing content) in some circumstances - use at your own risk.
      // https://github.com/facebook/react-native/issues/25157#issuecomment-503394856
      removeClippedSubviews={false}
      selectedValueIds={selectedValueIds}
      ListHeaderComponent={
        !selectedAnswers.length ? (
          <AnswersAggregation
            onLayout={handleAnswerAggregationLayout}
            isRefreshing={isRefreshing}
            onSelect={handleAnswerSelect}
          />
        ) : null
      }
      data={formattedData}
      renderItem={renderListItem}
      onEndReached={handleEndReachedProp}
      onErrorRefresh={handleErrorRefresh}
      isLoading={isLoading}
      onRefresh={handleRefresh}
      error={answeredError}
      listType={EAnalyticsScreenType.ANSWERS}
      isNeedToScrollUp={isNeedToScrollUp}
      scrollEventThrottle={10}
      onScroll={handleScroll}
      setIsNeedToScrollUp={handleSetIsNeedToScrollUp}
    />
  );
};

interface IListItemProps {
  item: IAnalyticsAnswersListItem;
  withDivider?: boolean;
  sortedChoices: IBiteAnswer[];
}

const ListItem: React.FC<IListItemProps> = ({ item, withDivider, sortedChoices }) => {
  const { activity, user } = item;
  const renderIcons = useCallback(() => {
    return <AnsweredIcons activity={activity} sortedChoices={sortedChoices} />;
  }, [activity, sortedChoices]);

  return (
    <ListItemComponent
      lastActivity={activity?.answer_date || activity?.last_interaction_date}
      user={user}
      renderIcons={renderIcons}
      withDivider={withDivider}
      answer={activity?.answer_text}
      mediaUrl={activity?.answer_media_url}
    />
  );
};

export default memo(AnsweredList);
