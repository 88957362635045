import { all, delay, put, select, takeLatest } from 'redux-saga/effects';
import * as calls from '../../api/bites-api/calls/biteAttributes.calls';
import {
  getAnswersAttributes,
  getCommentsAttributes,
  getFilterList,
  getGuestsAttribute,
  getOrganizationAttributes,
  getOverviewAttributes,
  getSearchList,
  getViewsAttributes,
  setAnswersAttribute,
  setAnswersAttributeError,
  setAnswersAttributeLoading,
  setCommentsAttribute,
  setCommentsAttributeError,
  setCommentsAttributeLoading,
  setFilterList,
  setFilterListError,
  setGuestsAttribute,
  setGuestsAttributeError,
  setGuestsAttributeLoading,
  setOrganizationAttributes,
  setOrganizationAttributesError,
  setOverviewAttribute,
  setOverviewAttributeError,
  setOverviewAttributeLoading,
  setSearchList,
  setSearchListError,
  setViewsAttribute,
  setViewsAttributeError,
  setViewsAttributeLoading,
} from './biteAttributes.slice';
import { activeOrganizationSelector } from '../../auth/auth.selectors';
import { contentIdSelector } from '../../analytics/analytics.selector';
import withRetry from '../../../utils/withRetry';
import {
  answersAttributeNextSelector,
  commentsAttributeNextSelector,
  biteDisplayModeSelector,
  filterAttributeByIdSelector,
  overviewAttributeNextSelector,
  searchValueSelector,
  searchValuesNextSelector,
  selectedFilterAttributeValueIdsSelector,
  biteSharedWithFilterSelector,
  sortBySelector,
  sortDirectionSelector,
  viewsAttributeNextSelector,
} from './biteAttributes.selector';
import {
  EAggregatedFields,
  ESortBy,
  ESortByType,
  IBiteAttributeMapItem,
  IBiteAttributeValue,
} from './biteAttributes.types';
import { PayloadAction } from '@reduxjs/toolkit';
import { resetAnalytics, setIsRefreshing } from '../../analytics/analytics.slice';
import { log } from '../../appActivity/appActivity.slice';
import { EAnalyticsScreenType, IGetAttributesPayload } from '../../../screens/analytics/Analytics.types';
import { ESortDirection } from '../../../types/common';
import { organizationFeaturesSelector } from '../../org/org.selectors';
import { FEATURE_FLAG_IDS } from '../../../utils/constants/org';
import { answersIdsFilterSelector } from '../biteStats/biteStats.selector';
import { IGetAttributesAction } from '../../org/org.types';
import { TDisplayMode } from '../../../types/anayltics';

export const API_SORT_TYPES_BY_APP_SORT_TYPE = {
  [ESortByType.VIEWS]: {
    percentage: ESortBy.VIEWS_PERCENTAGE,
    absolute: ESortBy.VIEWS_ABSOLUTE,
  },
  [ESortByType.ANSWERS]: {
    percentage: ESortBy.ANSWERS_PERCENTAGE,
    absolute: ESortBy.ANSWERS_ABSOLUTE,
  },
  [ESortByType.COMMENTS]: {
    percentage: ESortBy.COMMENTS_PERCENTAGE,
    absolute: ESortBy.COMMENTS_ABSOLUTE,
  },
};

function* getOrganizationAttributesSaga({ payload }: PayloadAction<IGetAttributesAction>) {
  const { organizationId, onSuccess, onError } = payload;
  const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);

  yield put(
    log({
      event: 'getOrganizationAttributesSaga: start',
      data: {
        organizationId,
      },
    }),
  );

  try {
    const { id: orgId } = yield select(activeOrganizationSelector);
    const sharedWith = yield select(biteSharedWithFilterSelector);
    const biteId = yield select(contentIdSelector);

    const {
      data: { attributes },
    } = yield withRetry(
      () =>
        calls.getOrganizationAttributes({
          orgId: organizationId || orgId,
          attributeValueIds: selectedFilterAttributeValueIds,
          biteIds: [biteId],
          sharedWith,
        }),
      {
        errorContext: {
          action: 'attributes.saga getOrganizationAttributesSaga',
        },
      },
    );

    if (typeof onSuccess === 'function') {
      onSuccess(attributes);
    }

    yield put(
      log({
        event: 'getOrganizationAttributesSaga: success',
        data: {
          organizationId,
        },
      }),
    );

    yield put(setOrganizationAttributes({ attributes }));
  } catch (error) {
    yield put(
      log({
        event: 'getOrganizationAttributesSaga: error',
        data: {
          error,
          // axios case
          errorResponse: error?.response,
          organizationId,
        },
      }),
    );

    if (typeof onError === 'function') {
      onError();
    }
    yield put(setOrganizationAttributesError({ error }));
  }
}

const getSelectedExcludeAttributeId = (attribute: IBiteAttributeMapItem, selectedFilterAttributeValueIds: number[]) => {
  const attributeIdsMap = {};
  attribute.selectedValues.forEach((value: IBiteAttributeValue) => {
    attributeIdsMap[value.id] = true;
  });
  return selectedFilterAttributeValueIds.filter((id: number) => !attributeIdsMap[id]);
};

function* getFilterListSaga({
  payload: { attributeId, reset, callback },
}: PayloadAction<{ attributeId: number; reset?: boolean; callback: () => void }>) {
  yield put(
    log({
      event: 'getFilterListSaga: start',
      data: {
        attributeId,
        reset,
      },
    }),
  );

  try {
    const { id: orgId } = yield select(activeOrganizationSelector);
    const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);
    const attribute = yield select(filterAttributeByIdSelector(attributeId));
    const sharedWith = yield select(biteSharedWithFilterSelector);
    const biteId = yield select(contentIdSelector);

    const attributeValueIds = getSelectedExcludeAttributeId(attribute, selectedFilterAttributeValueIds);

    const { data } = yield withRetry(
      () =>
        calls.getOrganizationAttributes({
          orgId,
          attributeId,
          attributeValueIds,
          sharedWith,
          biteIds: [biteId],
          next: !reset ? attribute?.next : undefined,
          pageSize: 20,
        }),
      {
        errorContext: {
          action: 'attributes.saga getFilterListSaga',
        },
      },
    );

    yield put(
      log({
        event: 'getFilterListSaga: success',
        data: {
          attributeId,
          reset,
        },
      }),
    );

    yield put(setFilterList({ attributeId, values: data.attributes?.[0]?.values || [], next: data.next, reset }));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    yield put(
      log({
        event: 'getFilterListSaga: error',
        data: {
          error,
          // axios case
          errorResponse: error?.response,
          attributeId,
          reset,
        },
      }),
    );

    if (reset) {
      yield put(setFilterList({ attributeId, values: null, next: null, reset: true }));
    }
    yield put(setFilterListError({ attributeId, error }));

    if (typeof callback === 'function') {
      callback();
    }
  }
}

function* getSearchListSaga({
  payload: { attributeId, reset, withDebounce, callback },
}: PayloadAction<{ attributeId: number; reset?: boolean; withDebounce?: boolean; callback: () => void }>) {
  yield put(
    log({
      event: 'getSearchListSaga: start',
      data: {
        attributeId,
        reset,
        withDebounce,
      },
    }),
  );

  try {
    if (withDebounce) {
      yield delay(300);
    }
    const { id: orgId } = yield select(activeOrganizationSelector);
    const searchValue = yield select(searchValueSelector);
    const sharedWith = yield select(biteSharedWithFilterSelector);
    const biteId = yield select(contentIdSelector);
    const searchValuesNext = yield select(searchValuesNextSelector);

    const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);
    const attribute = yield select(filterAttributeByIdSelector(attributeId));
    const attributeValueIds = getSelectedExcludeAttributeId(attribute, selectedFilterAttributeValueIds);

    const { data } = yield withRetry(
      () =>
        calls.getOrganizationAttributes({
          orgId,
          attributeId,
          attributeValueIds,
          sharedWith,
          biteIds: [biteId],
          searchAttributeValue: searchValue,
          next: !reset ? searchValuesNext : undefined,
          pageSize: 20,
        }),
      {
        errorContext: {
          action: 'attributes.saga getSearchListSaga',
        },
      },
    );

    yield put(
      log({
        event: 'getSearchListSaga: success',
        data: {
          attributeId,
          reset,
          withDebounce,
        },
      }),
    );

    yield put(setSearchList({ data: data.attributes, next: data.next, reset }));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    yield put(
      log({
        event: 'getSearchListSaga: error',
        data: {
          error,
          // axios case
          errorResponse: error?.response,
          attributeId,
          reset,
          withDebounce,
        },
      }),
    );

    yield put(setSearchListError({ error: error.message, reset }));

    if (typeof callback === 'function') {
      callback();
    }
  }
}

function* getAttributeSaga({
  attributeId,
  searchAttributeValue,
  aggregatedFields,
  sortBy,
  sortDirection,
  answerIds,
  next,
}: {
  attributeId: number;
  answerIds?: number[];
  searchAttributeValue?: string;
  aggregatedFields?: EAggregatedFields[];
  sortBy?: ESortBy;
  sortDirection?: ESortDirection;
  next?: string;
}) {
  const { id: orgId } = yield select(activeOrganizationSelector);
  const biteId = yield select(contentIdSelector);
  const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);
  const sharedWith = yield select(biteSharedWithFilterSelector);
  const organizationFeatures = yield select(organizationFeaturesSelector);
  const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);

  return yield withRetry(
    () =>
      calls.getAnalyticsAttributes({
        orgId,
        biteIds: [biteId],
        attributeId,
        attributeValueIds: selectedFilterAttributeValueIds,
        searchAttributeValue,
        aggregatedFields,
        answerIds,
        sortBy,
        sortDirection,
        sharedWith,
        allowedDataOnly,
        next,
      }),
    {
      errorContext: {
        action: 'attributes.saga getAttributeSaga',
      },
    },
  );
}

function* getOverviewAttributeSaga({
  payload: { attributeId, reset },
}: {
  payload: { attributeId: number; reset?: boolean };
}) {
  yield put(
    log({
      event: 'getOverviewAttributeSaga: start',
      data: {
        attributeId,
        reset,
      },
    }),
  );
  try {
    yield put(setOverviewAttributeLoading({ attributeId }));
    const displayMode: TDisplayMode = yield select(biteDisplayModeSelector);
    const sortByType = yield select(sortBySelector(EAnalyticsScreenType.OVERVIEW));
    const sortDirection = yield select(sortDirectionSelector(EAnalyticsScreenType.OVERVIEW));

    const sortBy = API_SORT_TYPES_BY_APP_SORT_TYPE[sortByType][displayMode];

    const next = yield select(overviewAttributeNextSelector(attributeId, displayMode));
    const { data } = yield getAttributeSaga({
      attributeId,
      aggregatedFields: [
        EAggregatedFields.VIEWS,
        EAggregatedFields.ANSWERS,
        EAggregatedFields.COMMENTS,
        EAggregatedFields.TOTAL_USERS_COUNT,
      ],
      sortBy,
      sortDirection,
      next: !reset ? next : undefined,
    });

    yield put(
      log({
        event: 'getOverviewAttributeSaga: success',
        data: {
          attributeId,
          reset,
        },
      }),
    );

    yield put(
      setOverviewAttribute({ attributeId, values: data.attributes?.[0]?.values || [], next: data.next, reset }),
    );
  } catch (error) {
    yield put(
      log({
        event: 'getOverviewAttributeSaga: error',
        data: {
          error,
          // axios case
          errorResponse: error?.response,
          attributeId,
          reset,
        },
      }),
    );

    yield put(setOverviewAttributeError({ attributeId, error, reset }));
  }
}

function* getViewsAttributeSaga({
  payload: { attributeId, reset },
}: {
  payload: { attributeId: number; reset?: boolean };
}) {
  yield put(
    log({
      event: 'getViewsAttributeSaga: start',
      data: {
        attributeId,
        reset,
      },
    }),
  );
  try {
    yield put(setViewsAttributeLoading({ attributeId }));
    const displayMode: TDisplayMode = yield select(biteDisplayModeSelector);
    const sortByType = yield select(sortBySelector(EAnalyticsScreenType.VIEWS));
    const sortDirection = yield select(sortDirectionSelector(EAnalyticsScreenType.VIEWS));
    const next = yield select(viewsAttributeNextSelector(attributeId, displayMode));

    const sortBy = API_SORT_TYPES_BY_APP_SORT_TYPE[sortByType][displayMode];

    const { data } = yield getAttributeSaga({
      attributeId,
      aggregatedFields: [EAggregatedFields.VIEWS, EAggregatedFields.TOTAL_USERS_COUNT],
      sortBy,
      sortDirection,
      next: !reset ? next : undefined,
    });

    yield put(
      log({
        event: 'getViewsAttributeSaga: success',
        data: {
          attributeId,
          reset,
        },
      }),
    );

    yield put(setViewsAttribute({ attributeId, values: data.attributes?.[0]?.values || [], next: data.next, reset }));
  } catch (error) {
    yield put(
      log({
        event: 'getViewsAttributeSaga: error',
        data: {
          error,
          // axios case
          errorResponse: error?.response,
          attributeId,
          reset,
        },
      }),
    );

    yield put(setViewsAttributeError({ attributeId, error, reset }));
  }
}

function* getAnswersAttributeSaga({
  payload: { attributeId, reset },
}: {
  payload: { attributeId: number; reset?: boolean };
}) {
  yield put(
    log({
      event: 'getAnswersAttributeSaga: start',
      data: {
        attributeId,
        reset,
      },
    }),
  );
  try {
    yield put(setAnswersAttributeLoading({ attributeId }));
    const answerIds = yield select(answersIdsFilterSelector);
    const displayMode: TDisplayMode = yield select(biteDisplayModeSelector);
    const sortByType = yield select(sortBySelector(EAnalyticsScreenType.ANSWERS));
    const sortDirection = yield select(sortDirectionSelector(EAnalyticsScreenType.ANSWERS));
    const next = yield select(answersAttributeNextSelector(attributeId, displayMode));

    const sortBy = API_SORT_TYPES_BY_APP_SORT_TYPE[sortByType][displayMode];

    const withSelectedAnswer = answerIds.length;

    const { data } = yield getAttributeSaga({
      attributeId,
      aggregatedFields: [EAggregatedFields.ANSWERS, EAggregatedFields.TOTAL_USERS_COUNT],
      sortBy,
      sortDirection,
      answerIds,
      next: !reset ? next : undefined,
    });

    yield put(
      log({
        event: 'getAnswersAttributeSaga: success',
        data: {
          attributeId,
          reset,
        },
      }),
    );

    yield put(
      setAnswersAttribute({
        attributeId,
        values: data.attributes?.[0]?.values || [],
        next: data.next,
        reset,
        withSelectedAnswer,
      }),
    );
  } catch (error) {
    yield put(
      log({
        event: 'getAnswersAttributeSaga: error',
        data: {
          error,
          // axios case
          errorResponse: error?.response,
          attributeId,
          reset,
        },
      }),
    );

    yield put(setAnswersAttributeError({ attributeId, error, reset }));
  }
}

function* getCommentsAttributeSaga({
  payload: { attributeId, reset },
}: {
  payload: { attributeId: number; reset?: boolean };
}) {
  yield put(
    log({
      event: 'getCommentsAttributeSaga: start',
      data: {
        attributeId,
        reset,
      },
    }),
  );
  try {
    yield put(setCommentsAttributeLoading({ attributeId }));
    const displayMode: TDisplayMode = yield select(biteDisplayModeSelector);
    const sortByType = yield select(sortBySelector(EAnalyticsScreenType.COMMENTS));
    const sortDirection = yield select(sortDirectionSelector(EAnalyticsScreenType.COMMENTS));
    const next = yield select(commentsAttributeNextSelector(attributeId, displayMode));

    const sortBy = API_SORT_TYPES_BY_APP_SORT_TYPE[sortByType][displayMode];

    const { data } = yield getAttributeSaga({
      attributeId,
      aggregatedFields: [EAggregatedFields.COMMENTS, EAggregatedFields.TOTAL_USERS_COUNT],
      sortBy,
      sortDirection,
      next: !reset ? next : undefined,
    });

    yield put(
      log({
        event: 'getCommentsAttributeSaga: success',
        data: {
          attributeId,
          reset,
        },
      }),
    );

    yield put(
      setCommentsAttribute({ attributeId, values: data.attributes?.[0]?.values || [], next: data.next, reset }),
    );
  } catch (error) {
    yield put(
      log({
        event: 'getCommentsAttributeSaga: error',
        data: {
          error,
          // axios case
          errorResponse: error?.response,
          attributeId,
          reset,
        },
      }),
    );

    yield put(setCommentsAttributeError({ attributeId, error, reset }));
  }
}

function* getOverviewAttributesSaga({
  payload: { attributeIds, clearAnalyticsCache, reset, callback },
}: PayloadAction<IGetAttributesPayload>) {
  if (clearAnalyticsCache) {
    yield put(
      resetAnalytics({
        excludeFields: {
          analytics: ['contentId', 'contentType', 'hasDistributions', 'isFilterTooltipShown'],
          biteAttributes: ['guestsAttribute', { field: 'overviewAttributesMapById', attributeIds }],
        },
      }),
    );
  }

  const getGuests = () => {
    if (!clearAnalyticsCache) {
      return null;
    }
    const currentTab = EAnalyticsScreenType.OVERVIEW;
    return getGuestsAttributeSaga({ payload: { currentTab } });
  };

  yield all(
    [getGuests, ...attributeIds.map((id) => getOverviewAttributeSaga({ payload: { attributeId: id, reset } }))].filter(
      Boolean,
    ),
  );

  yield put(setIsRefreshing(false));

  if (typeof callback === 'function') {
    callback();
  }
}

function* getViewsAttributesSaga({
  payload: { attributeIds, clearAnalyticsCache, reset, callback },
}: PayloadAction<IGetAttributesPayload>) {
  if (clearAnalyticsCache) {
    yield put(
      resetAnalytics({
        excludeFields: {
          analytics: ['contentId', 'contentType', 'hasDistributions', 'isFilterTooltipShown'],
          biteAttributes: [{ field: 'viewsAttributesMapById', attributeIds }],
        },
      }),
    );
  }

  yield all(attributeIds.map((id) => getViewsAttributeSaga({ payload: { attributeId: id, reset } })));

  yield put(setIsRefreshing(false));

  if (typeof callback === 'function') {
    callback();
  }
}

function* getAnswersAttributesSaga({
  payload: { attributeIds, clearAnalyticsCache, reset, callback },
}: PayloadAction<IGetAttributesPayload>) {
  if (clearAnalyticsCache) {
    yield put(
      resetAnalytics({
        excludeFields: {
          analytics: ['contentId', 'contentType', 'hasDistributions', 'isFilterTooltipShown'],
          biteAttributes: [{ field: 'answersAttributesMapById', attributeIds }],
        },
      }),
    );
  }

  yield all(attributeIds.map((id) => getAnswersAttributeSaga({ payload: { attributeId: id, reset } })));

  yield put(setIsRefreshing(false));

  if (typeof callback === 'function') {
    callback();
  }
}

function* getCommentsAttributesSaga({
  payload: { attributeIds, clearAnalyticsCache, reset, callback },
}: PayloadAction<IGetAttributesPayload>) {
  if (clearAnalyticsCache) {
    yield put(
      resetAnalytics({
        excludeFields: {
          analytics: ['contentId', 'contentType', 'hasDistributions', 'isFilterTooltipShown'],
          biteAttributes: [{ field: 'commentsAttributesMapById', attributeIds }],
        },
      }),
    );
  }

  yield all(attributeIds.map((id) => getCommentsAttributeSaga({ payload: { attributeId: id, reset } })));

  yield put(setIsRefreshing(false));

  if (typeof callback === 'function') {
    callback();
  }
}
function* getGuestsAttributeSaga({ payload: { currentTab } }: { payload: { currentTab: EAnalyticsScreenType } }) {
  const answerIds = yield select(answersIdsFilterSelector);
  const { id: orgId } = yield select(activeOrganizationSelector);
  const biteId = yield select(contentIdSelector);
  const organizationFeatures = yield select(organizationFeaturesSelector);
  const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);

  yield put(
    log({
      event: 'getGuestsAttributeSaga: start',
      data: {
        orgId,
        biteId,
      },
    }),
  );

  try {
    yield put(setGuestsAttributeLoading({ currentTab, isAnswersSelected: !!answerIds.length }));
    const { data } = yield withRetry(
      () =>
        calls.getGuestsAttribute({
          orgId,
          biteIds: [biteId],
          answerIds,
          allowedDataOnly,
          aggregatedFields: [
            EAggregatedFields.VIEWS,
            EAggregatedFields.ANSWERS,
            EAggregatedFields.COMMENTS,
            EAggregatedFields.TOTAL_USERS_COUNT,
          ],
        }),
      {
        errorContext: {
          action: 'attributes.saga getGuestsAttributeSaga',
        },
      },
    );

    yield put(
      log({
        event: 'getGuestsAttributeSaga: success',
        data: {
          orgId,
          biteId,
        },
      }),
    );

    yield put(setGuestsAttribute({ attribute: data, currentTab, isAnswersSelected: !!answerIds.length }));
  } catch (error) {
    yield put(setGuestsAttributeError({ error, currentTab, isAnswersSelected: !!answerIds.length }));

    yield put(
      log({
        event: 'getGuestsAttributeSaga: error',
        data: {
          error,
          // axios case
          errorResponse: error?.response,
        },
      }),
    );
  }
}

export default function* biteAttributesSaga() {
  yield all([
    takeLatest(getOrganizationAttributes, getOrganizationAttributesSaga),
    takeLatest(getFilterList, getFilterListSaga),
    takeLatest(getSearchList, getSearchListSaga),

    takeLatest(getViewsAttributes, getViewsAttributesSaga),
    takeLatest(getAnswersAttributes, getAnswersAttributesSaga),
    takeLatest(getCommentsAttributes, getCommentsAttributesSaga),
    takeLatest(getOverviewAttributes, getOverviewAttributesSaga),

    takeLatest(getGuestsAttribute, getGuestsAttributeSaga),
  ]);
}
