import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../../../../utils/dimensions';
import ImageModal from '../../../../../../components/shared/ImageModal/ImageModal';
import { log } from '../../../../../../store/appActivity/appActivity.slice';
import { useDispatch } from 'react-redux';
import { answerTextDataSet, mediaImageDataSet, moreAnswerTextDataSet, moreButtonDataSet } from '../ListItem.constants';

interface IAnswerProps {
  answer: string;
  url?: string;
}

const Answer: React.FC<IAnswerProps> = ({ answer, url }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const isNeedMoreButton = answer?.length > 100 || answer?.split('\n').length > 2;

  const handelMoreButtonPress = useCallback(() => {
    if (!isNeedMoreButton) {
      return;
    }

    dispatch(
      log({
        event: 'Answer.handelMoreButtonPress',
        data: { answer, url },
      }),
    );

    setIsOpen(true);
  }, [answer, dispatch, isNeedMoreButton, url]);

  if (!answer && !url) {
    return null;
  }

  return (
    <S.AnswerContainer>
      <S.Content>
        <S.AnswerTextContainer activeOpacity={1} onPress={handelMoreButtonPress}>
          <S.AnswerText
            //@ts-ignore
            dataSet={isOpen ? answerTextDataSet : moreAnswerTextDataSet}
            numberOfLines={isOpen ? undefined : 1}
          >
            {answer}
          </S.AnswerText>
        </S.AnswerTextContainer>
        {!isOpen && isNeedMoreButton && (
          <S.MoreButton
            //@ts-ignore
            dataSet={moreButtonDataSet}
            onPress={handelMoreButtonPress}
          >
            <S.MoreText>{t('analytics.answered.more')}</S.MoreText>
          </S.MoreButton>
        )}
        {!!url && <Media url={url} />}
      </S.Content>
    </S.AnswerContainer>
  );
};

const Media = ({ url }: { url: string }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const source = useMemo(() => ({ uri: url }), [url]);

  const handleToggleModal = useCallback(() => {
    setIsModalVisible((prev) => !prev);
  }, []);

  return (
    <>
      <S.MediaContainer
        //@ts-ignore
        dataSet={mediaImageDataSet}
        onPress={handleToggleModal}
      >
        <S.Media source={source} />
      </S.MediaContainer>
      <ImageModal isVisible={isModalVisible} onClose={handleToggleModal} image={url} />
    </>
  );
};

const S = {
  MediaContainer: styled.TouchableOpacity`
    width: ${isWeb ? calcWidth(420) : deviceWidth - calcWidth(130)}px;
    height: ${calcHeight(180)}px;
    margin-top: ${calcHeight(8)}px;
    background: ${({ theme }) => theme.colors.lightGray34}};
  `,
  Media: styled.Image`
    resize-mode: cover;
    width: 100%;
    height: 100%;
  `,
  Content: styled.View`
    align-items: flex-start;
  `,
  AnswerContainer: styled.View`
    max-width: ${deviceWidth - calcWidth(130)}px;
    margin-left: ${calcWidth(8)}px;
  `,
  AnswerTextContainer: styled.TouchableOpacity``,
  AnswerText: styled.Text`
    color: ${({ theme }) => theme.colors.gray19};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s13};
    font-weight: 400;
    max-width: ${isWeb ? calcWidth(350) : deviceWidth - calcWidth(140)}px;
    text-align: left;
    align-self: flex-start;
  `,
  MoreButton: styled.TouchableOpacity``,
  MoreText: styled.Text`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s13};
    font-weight: 400;
  `,
};

export default memo(Answer);
