import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
  getAnswersAggregated,
  getAnswersData,
  getBiteDistributions,
  getCommentsData,
  getOverviewData,
  getViewsData,
  setAggregatedAnswersError,
  setAnswersAggregated,
  setAnswersData,
  setAnswersDataError,
  setCommentsData,
  setCommentsDataError,
  setOverviewData,
  setOverviewDataError,
  setViewsData,
  setViewsDataError,
  getAnswersNum,
  getCommentsNum,
  getBiteStatsOverview,
  getViewsNum,
  resetAnswersNum,
  resetCommentsNum,
  resetViewsNum,
  setAnswersNum,
  setAnswersNumError,
  setCommentsNum,
  setCommentsNumError,
  setViewsNum,
  setViewsNumError,
} from './biteStats.slice';
import * as calls from '../../api/bites-api/calls/analytics.calls';
import { getBiteDistributions as getBiteDistributionsService } from '../../api/bites-api/calls/analytics.calls';
import {
  answersFilterSelector,
  answersIdsFilterSelector,
  answersNextSelector,
  commentsFilterSelector,
  commentsNextSelector,
  overviewFilterSelector,
  overviewNextSelector,
  viewsFilterSelector,
  viewsNextSelector,
} from './biteStats.selector';
import { activeOrganizationSelector } from '../../auth/auth.selectors';
import withRetry from '../../../utils/withRetry';
import {
  selectedFilterAttributeValueIdsSelector,
  biteSharedWithFilterSelector,
} from '../biteAttributes/biteAttributes.selector';
import { log } from '../../appActivity/appActivity.slice';
import { setSharedWithFilter, setSortBy } from '../biteAttributes/biteAttributes.slice';
import { ESortByType } from '../biteAttributes/biteAttributes.types';
import { organizationFeaturesSelector } from '../../org/org.selectors';
import { FEATURE_FLAG_IDS } from '../../../utils/constants/org';
import { resetAnalytics, setHasDistributions, setIsRefreshing } from '../../analytics/analytics.slice';
import { contentIdSelector } from '../../analytics/analytics.selector';
import { updateFeedBite } from '../../bite/bite.actions';
import { biteItemSelector } from '../../bite/bite.selectors';
import { EditBiteIconType } from '../../../screens/editBite/EditMain/common/SectionItem';
import { IntroSection, QuestionSection } from '../../../types/bite';
import { isEmpty } from 'lodash';
import { EAnalyticsScreenType } from '../../../screens/analytics/Analytics.types';
import { ESharedWithFilter, ESortingDirection } from '../../../types/anayltics';

function* getOverviewDataSaga({ payload }) {
  const reset = payload?.reset;
  const clearAnalyticsCache = payload?.clearAnalyticsCache;
  try {
    const biteId = yield select(contentIdSelector);
    const { id: orgId } = yield select(activeOrganizationSelector);
    const next = yield select(overviewNextSelector);
    const filter = yield select(overviewFilterSelector);
    const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);
    const sharedWith = yield select(biteSharedWithFilterSelector);
    const organizationFeatures = yield select(organizationFeaturesSelector);
    const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);

    if (clearAnalyticsCache) {
      yield put(
        resetAnalytics({
          excludeFields: {
            analytics: [
              'contentId',
              'contentType',
              'currentList',
              'isRefreshing',
              'hasDistributions',
              'isFilterTooltipShown',
            ],
            biteStats: ['overview'],
          },
        }),
      );
    }

    const { data } = yield withRetry(
      () =>
        calls.getOverviewList({
          orgId,
          biteIds: [biteId],
          attributeValueIds: selectedFilterAttributeValueIds,
          sharedWith,
          allowedDataOnly,
          filter,
          next,
        }),
      {
        errorContext: {
          action: 'biteStats.saga getOverviewDataSaga',
        },
      },
    );

    yield put(setOverviewData({ ...data, reset }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  } catch (error) {
    if (clearAnalyticsCache) {
      resetAnalytics({
        excludeFields: {
          analytics: ['contentId', 'contentType', 'currentList', 'hasDistributions', 'isFilterTooltipShown'],
        },
      });
    }

    if (reset) {
      yield put(setOverviewData({ results: [], next: null, reset: true }));
    }
    yield put(setOverviewDataError({ error }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  }
}

function* getViewsDataSaga({ payload }) {
  const reset = payload?.reset;
  const clearAnalyticsCache = payload?.clearAnalyticsCache;

  try {
    const biteId = yield select(contentIdSelector);
    const { id: orgId } = yield select(activeOrganizationSelector);
    const next = yield select(viewsNextSelector);
    const filter = yield select(viewsFilterSelector);
    const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);
    const sharedWith = yield select(biteSharedWithFilterSelector);
    const organizationFeatures = yield select(organizationFeaturesSelector);
    const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);

    if (clearAnalyticsCache) {
      yield put(
        resetAnalytics({
          excludeFields: {
            analytics: [
              'contentId',
              'contentType',
              'currentList',
              'isRefreshing',
              'hasDistributions',
              'isFilterTooltipShown',
            ],
            biteStats: ['views'],
          },
        }),
      );
    }

    const { data } = yield withRetry(
      () =>
        calls.getViewsList({
          orgId,
          biteIds: [biteId],
          filter,
          attributeValueIds: selectedFilterAttributeValueIds,
          sharedWith,
          allowedDataOnly,
          next,
        }),
      {
        errorContext: {
          action: 'biteStats.saga getViewsDataSaga',
        },
      },
    );

    yield put(setViewsData({ ...data, reset }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  } catch (error) {
    if (clearAnalyticsCache) {
      yield put(
        resetAnalytics({
          excludeFields: {
            analytics: ['contentId', 'contentType', 'currentList', 'hasDistributions', 'isFilterTooltipShown'],
          },
        }),
      );
    }

    if (reset) {
      yield put(setViewsData({ results: [], next: null, reset: true }));
    }
    yield put(setViewsDataError({ error }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  }
}

function* getAnswersDataSaga({ payload }) {
  const reset = payload?.reset;
  const clearAnalyticsCache = payload?.clearAnalyticsCache;

  try {
    const biteId = yield select(contentIdSelector);
    const { id: orgId } = yield select(activeOrganizationSelector);
    const next = yield select(answersNextSelector);
    const filter = yield select(answersFilterSelector);
    const answerIds = yield select(answersIdsFilterSelector);
    const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);
    const sharedWith = yield select(biteSharedWithFilterSelector);
    const organizationFeatures = yield select(organizationFeaturesSelector);
    const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);

    if (clearAnalyticsCache) {
      yield put(
        resetAnalytics({
          excludeFields: {
            analytics: [
              'contentId',
              'contentType',
              'currentList',
              'isRefreshing',
              'hasDistributions',
              'isFilterTooltipShown',
            ],
            biteStats: ['aggregatedAnswers', 'answers'],
          },
        }),
      );
    }

    const { data } = yield withRetry(
      () =>
        calls.getAnswersList({
          orgId,
          biteIds: [biteId],
          filter: !answerIds.length ? filter : undefined,
          attributeValueIds: selectedFilterAttributeValueIds,
          sharedWith,
          allowedDataOnly,
          answerIds,
          next,
        }),
      {
        errorContext: {
          action: 'biteStats.saga getAnswersDataSaga',
        },
      },
    );

    yield put(setAnswersData({ ...data, reset }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  } catch (error) {
    if (clearAnalyticsCache) {
      yield put(
        resetAnalytics({
          excludeFields: {
            analytics: ['contentId', 'contentType', 'currentList', 'hasDistributions', 'isFilterTooltipShown'],
            biteStats: ['aggregatedAnswers'],
          },
        }),
      );
    }

    if (reset) {
      yield put(setAnswersData({ results: [], next: null, reset: true }));
    }
    yield put(setAnswersDataError({ error }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  }
}

function* getCommentsDataSaga({ payload }) {
  const reset = payload?.reset;
  const clearAnalyticsCache = payload?.clearAnalyticsCache;

  try {
    const biteId = yield select(contentIdSelector);
    const { id: orgId } = yield select(activeOrganizationSelector);
    const next = yield select(commentsNextSelector);
    const filter = yield select(commentsFilterSelector);
    const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);
    const sharedWith = yield select(biteSharedWithFilterSelector);
    const organizationFeatures = yield select(organizationFeaturesSelector);
    const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);

    if (clearAnalyticsCache) {
      yield put(
        resetAnalytics({
          excludeFields: {
            analytics: [
              'contentId',
              'contentType',
              'currentList',
              'isRefreshing',
              'hasDistributions',
              'isFilterTooltipShown',
            ],
            biteStats: ['comments'],
          },
        }),
      );
    }

    const { data } = yield withRetry(
      () =>
        calls.getCommentsList({
          orgId,
          biteIds: [biteId],
          filter,
          attributeValueIds: selectedFilterAttributeValueIds,
          sharedWith,
          allowedDataOnly,
          next,
        }),
      {
        errorContext: {
          action: 'biteStats.saga getCommentsDataSaga',
        },
      },
    );

    yield put(setCommentsData({ ...data, reset }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  } catch (error) {
    if (clearAnalyticsCache) {
      yield put(
        resetAnalytics({
          excludeFields: {
            analytics: ['contentId', 'contentType', 'currentList', 'hasDistributions', 'isFilterTooltipShown'],
          },
        }),
      );
    }

    if (reset) {
      yield put(setCommentsData({ results: [], next: null, reset: true }));
    }
    yield put(setCommentsDataError({ error }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  }
}

function* getAnswersAggregatedSaga() {
  try {
    const biteId = yield select(contentIdSelector);
    const { id: orgId } = yield select(activeOrganizationSelector);
    const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);
    const sharedWith = yield select(biteSharedWithFilterSelector);
    const organizationFeatures = yield select(organizationFeaturesSelector);
    const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);

    const { data } = yield withRetry(
      () =>
        calls.getAnswersAggregated({
          orgId,
          biteId,
          attributeValueIds: selectedFilterAttributeValueIds,
          sharedWith,
          allowedDataOnly,
        }),
      {
        errorContext: {
          action: 'biteStats.saga getAnswersAggregatedSaga',
        },
      },
    );
    yield put(setAnswersAggregated(data));
  } catch (error) {
    yield put(setAggregatedAnswersError({ error }));
  }
}

function* getBiteDistributionsSaga() {
  yield put(
    log({
      event: 'getBiteDistributionsSaga: start',
    }),
  );
  try {
    const { id: orgId } = yield select(activeOrganizationSelector);
    const biteId = yield select(contentIdSelector);

    const {
      data: { distributions },
    } = yield withRetry(() => getBiteDistributionsService({ orgId, biteId }), {
      errorContext: {
        action: 'attributes.saga getBiteDistributionsSaga',
      },
    });

    yield put(
      log({
        event: 'getBiteDistributionsSaga: success',
      }),
    );

    yield put(setHasDistributions(!!distributions.length));

    if (distributions.length) {
      yield put(setSharedWithFilter([ESharedWithFilter.COMMUNICATION_HUB, ESharedWithFilter.ORGANICALLY]));
    }
  } catch (error) {
    yield put(
      log({
        event: 'getBiteDistributionsSaga: error',
        data: {
          error,
          // axios case
          errorResponse: error?.response,
        },
      }),
    );

    yield put(setHasDistributions(true));
  }
}

function* getViewsNumberSaga() {
  try {
    const biteId = yield select(contentIdSelector);
    const { id: orgId } = yield select(activeOrganizationSelector);
    const organizationFeatures = yield select(organizationFeaturesSelector);
    const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);
    const {
      data: { views_num },
    } = yield withRetry(() => calls.getViewsNumber({ orgId, biteIds: [biteId], allowedDataOnly }), {
      errorContext: {
        action: 'biteStats.saga getViewsNumberSaga',
      },
    });
    yield put(
      updateFeedBite({
        id: biteId,
        bite: {
          watched: views_num || 0,
        },
      }),
    );
    yield put(setViewsNum(views_num));
  } catch (error) {
    yield put(setViewsNumError({ error }));
  }
}

function* getAnswersNumberSaga() {
  try {
    const biteId = yield select(contentIdSelector);
    const { id: orgId } = yield select(activeOrganizationSelector);
    const organizationFeatures = yield select(organizationFeaturesSelector);
    const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);
    const {
      data: { answers_num },
    } = yield withRetry(() => calls.getAnswersNumber({ orgId, biteIds: [biteId], allowedDataOnly }), {
      errorContext: {
        action: 'biteStats.saga getAnswersNumberSaga',
      },
    });
    yield put(
      updateFeedBite({
        id: biteId,
        bite: {
          answers: answers_num || 0,
        },
      }),
    );
    yield put(setAnswersNum(answers_num));
  } catch (error) {
    yield put(setAnswersNumError({ error }));
  }
}

function* getCommentsNumberSaga() {
  try {
    const biteId = yield select(contentIdSelector);
    const { id: orgId } = yield select(activeOrganizationSelector);
    const organizationFeatures = yield select(organizationFeaturesSelector);
    const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);
    const {
      data: { comments_num },
    } = yield withRetry(() => calls.getCommentsNumber({ orgId, biteIds: [biteId], allowedDataOnly }), {
      errorContext: {
        action: 'biteStats.saga getCommentsNumberSaga',
      },
    });

    yield put(
      updateFeedBite({
        id: biteId,
        bite: {
          comments: comments_num || 0,
        },
      }),
    );
    yield put(setCommentsNum(comments_num));
  } catch (error) {
    yield put(setCommentsNumError({ error }));
  }
}

function* getStatsOverviewSaga() {
  const biteId = yield select(contentIdSelector);
  const bite = yield select(biteItemSelector(biteId));

  const isViewsTabEnabled = (() => {
    const currentSection = bite?.bite_sections?.find((item) => item.type === EditBiteIconType.INTRO) as IntroSection;

    if (!currentSection) {
      return false;
    }

    return !(currentSection.task_id === null && currentSection.media === null);
  })();

  const isAnsweredTabEnabled = (() => {
    const currentSection = bite?.bite_sections?.find(
      (item) => item.type === EditBiteIconType.QUESTION,
    ) as QuestionSection;

    if (!currentSection) {
      return false;
    }

    return !isEmpty(currentSection.questions);
  })();

  // if the full bite is not loaded yet
  // we still do not know which sections are available
  // so not to wait, just request all the data
  if (isViewsTabEnabled || !bite.bite_sections) {
    yield put(getViewsNum());
  } else {
    yield put(resetViewsNum());
  }

  if (isAnsweredTabEnabled || !bite.bite_sections) {
    yield put(getAnswersNum());
  } else {
    yield put(resetAnswersNum());
  }

  if (bite.discussion_enabled) {
    yield put(getCommentsNum());
  } else {
    yield put(resetCommentsNum());
  }

  if (!isViewsTabEnabled) {
    yield put(
      setSortBy({
        currentTab: EAnalyticsScreenType.OVERVIEW,
        sortBy: isAnsweredTabEnabled ? ESortByType.ANSWERS : ESortByType.COMMENTS,
        sortDirection: ESortingDirection.DESC,
        withoutReset: true,
      }),
    );
  }
}

export default function* biteStatsSaga() {
  yield all([
    takeLatest(getOverviewData, getOverviewDataSaga),
    takeLatest(getViewsData, getViewsDataSaga),
    takeLatest(getAnswersData, getAnswersDataSaga),
    takeLatest(getCommentsData, getCommentsDataSaga),
    takeLatest(getAnswersAggregated, getAnswersAggregatedSaga),
    takeLatest(getBiteDistributions, getBiteDistributionsSaga),
    takeLatest(getBiteStatsOverview, getStatsOverviewSaga),
    takeLatest(getViewsNum, getViewsNumberSaga),
    takeLatest(getAnswersNum, getAnswersNumberSaga),
    takeLatest(getCommentsNum, getCommentsNumberSaga),
  ]);
}
