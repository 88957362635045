export const skeletonDataSet = { cy: 'skeleton' };
export const numbersDataSet = { cy: 'numbers' };
export const settingsButtonDataSet = { cy: 'settings-button' };
export const bitePreviewButtonDataSet = { cy: 'bite-preview-button' };
export const bitePreviewContainerDataSet = { cy: 'bite-preview-container' };
export const bitePreviewCloseButtonDataSet = { cy: 'bite-preview-close-button' };
export const overviewDataSet = { cy: 'overview-stats' };
export const overviewDataSetDisabled = { cy: 'overview-stats-disabled' };
export const viewsDataSet = { cy: 'views-stats' };
export const commentsDataSet = { cy: 'comments-stats' };
export const answersDataSet = { cy: 'answers-stats' };
export const restrictionBannerDataSet = { cy: 'restriction-banner' };
export const playlistPreviewButtonDataSet = { cy: 'playlist-preview-button' };
export const playlistPreviewContainerDataSet = { cy: 'playlist-preview-container' };
export const playlistPreviewCloseButtonDataSet = { cy: 'playlist-preview-close-button' };
