import React from 'react';
import styled from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import { useTranslation } from 'react-i18next';
import { log } from '../../../store/appActivity/appActivity.slice';
import Intercom from '../../../services/intercom/intercom';
import { useDispatch, useSelector } from 'react-redux';
import { configSelector } from '../../../store/appActivity/appActivity.selectors';
import { organizationFeaturesSelector } from '../../../store/org/org.selectors';
import { FEATURE_FLAG_IDS } from '../../../utils/constants/org';
import { restrictionBannerDataSet } from '../stats.constants';

const RestrictedDataBanner = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const config = useSelector(configSelector);
  const organizationFeatures = useSelector(organizationFeaturesSelector);

  const handleLearnMorePress = () => {
    dispatch(
      log({
        event: 'RestrictedDataBanner.handleLearnMorePress',
      }),
    );

    Intercom.displayArticle(config.intercom.restrictedDataArticleId);
  };

  // TODO: To remove hideAllowedDataOnlyBanner flag after backend tests
  if (!organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart) || config.featureFlags.hideAllowedDataOnlyBanner) {
    return null;
  }

  return (
    <S.Container dataSet={restrictionBannerDataSet}>
      <S.BannerText>{t('biteScreen.restrictedDataBanner.text')}</S.BannerText>
      {config.intercom.restrictedDataArticleId && (
        <S.LearnMoreButton onPress={handleLearnMorePress}>
          <S.LearnMoreText>{t('biteScreen.restrictedDataBanner.learnMore')}</S.LearnMoreText>
        </S.LearnMoreButton>
      )}
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    margin-top: ${calcHeight(20)}px;
    width: ${calcWidth(339)}px;
  `,
  BannerText: styled.Text`
    color: ${({ theme }) => theme.colors.lightGray40};
    font-size: ${({ theme }) => theme.fontSizes.s12};
    line-height: ${calcHeight(15)}px;
    text-align: center;
  `,
  LearnMoreButton: styled.TouchableOpacity`
    margin: auto;
  `,
  LearnMoreText: styled.Text`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: ${({ theme }) => theme.fontSizes.s12};
    line-height: ${calcHeight(15)}px;
    text-align: center;
  `,
};

export default RestrictedDataBanner;
