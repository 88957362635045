// @ts-nocheck
import BitesApi from '../index';
import { ESortBy } from '../../../attributes/playlistAttributes/playlistAttributes.types';
import { ESortDirection } from '../../../../types/common';
import { ESharedWithFilter } from '../../../../types/anayltics';

interface IGetAttributesListRequest {
  orgId: number;
  attributeId?: number;
  playlistIds: number[];
  allowedDataOnly: boolean;
  sharedWith: ESharedWithFilter[];
  attributeValueIds: number[];
  sortBy: ESortBy;
  sortDirection: ESortDirection;
  pageSize?: number;
  next: string;
}

interface IGetGuestsAttributeRequest {
  orgId: number;
  biteIds?: number[];
  playlistIds?: number[];
  answerIds?: number[];
  allowedDataOnly?: boolean;
}

export const getPlaylistAnalyticsAttributes = async ({
  orgId,
  attributeId,
  playlistIds,
  attributeValueIds,
  sharedWith,
  allowedDataOnly,
  next,
  sortBy,
  sortDirection,
  pageSize = 10,
}: IGetAttributesListRequest) =>
  BitesApi.get(`analytics/${orgId}/playlist/attributes/${attributeId ? attributeId + '/' : ''}`, {
    params: {
      playlist_ids: playlistIds?.length ? JSON.stringify(playlistIds) : undefined,
      allowed_data_only: allowedDataOnly || undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      sort_by: sortBy,
      sort_direction: sortDirection,
      page_size: pageSize,
      next,
    },
  });

export const getPlaylistGuestsAttribute = async ({ orgId, playlistIds, allowedDataOnly }: IGetGuestsAttributeRequest) =>
  BitesApi.get(`analytics/${orgId}/playlist/attributes/guest`, {
    params: {
      playlist_ids: playlistIds?.length ? JSON.stringify(playlistIds) : undefined,
      allowed_data_only: allowedDataOnly || undefined,
    },
  });
