import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import Cloud from './Cloud';
import { ICloudAsset } from '../../../store/cloudAssets/cloudAssets.types';
import { FileTypeSelector } from './FileTypeSelector';
import SidebarTitle from './SidebarTitle';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../../store/auth/auth.selectors';
import {
  cloudFileTypeSelector,
  favoriteFlaggedToResetSelector,
  favoriteResultsSelector,
} from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import {
  setFavoriteResults,
  setFlaggedToReset,
} from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import { SidebarProps } from './LeftSidebar';

const FILE_TYPES: ('all' | ICloudAsset['fileType'])[] = ['all', 'video', 'image', 'gif', 'audio'];

const FavoritesSidebar = ({ previewPanelRef, setIsPreviewPanelVisible }: SidebarProps) => {
  const dispatch = useDispatch();

  const { user } = useSelector(authSelector);
  const favoriteResults = useSelector(favoriteResultsSelector);
  const fileType = useSelector(cloudFileTypeSelector);
  const isFlaggedToReset = useSelector(favoriteFlaggedToResetSelector);

  const isFlaggedToResetRef = useRef(isFlaggedToReset);
  isFlaggedToResetRef.current = !isFlaggedToReset ? isFlaggedToReset : isFlaggedToResetRef.current;

  const additionalRequestFilters = useMemo(
    () => ({
      favoriteByUserId: user.id,
    }),
    [user],
  );

  const renderFilterControls = useCallback(() => {
    return <FileTypeSelector fileType={fileType} fileTypes={FILE_TYPES} />;
  }, [fileType]);

  useEffect(() => {
    if (isFlaggedToReset) {
      dispatch(
        setFlaggedToReset({
          favorite: false,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <SidebarTitle title='Favorite' />
      <Cloud
        cloudResults={favoriteResults}
        additionalRequestFilters={additionalRequestFilters}
        renderFilterControls={renderFilterControls}
        setCloudResults={setFavoriteResults}
        setIsPreviewPanelVisible={setIsPreviewPanelVisible}
        isFlaggedToReset={isFlaggedToResetRef.current}
        previewPanelRef={previewPanelRef}
      />
    </>
  );
};
export default memo(FavoritesSidebar);
