import React, { memo, useCallback, useMemo, useState } from 'react';
import PointsButton from '../../../components/shared/buttons/PointsButton';
import BiteSettingsModal from '../../creationBite/common/BiteSettingsModal';
import { ISettingsDoneProps } from '../../creationBite/common/BiteSettingsModal/BiteSettingsModal';
import { useDispatch, useSelector } from 'react-redux';
import BlueAndRedButtonsModal from '../../../components/modals/BlueAndRedButtonsModal';
import { biteItemSelector } from '../../../store/bite/bite.selectors';
import { IOrganization } from '../../../types/organization';
import { showBottomPanel } from '../../../store/bottomPanel/bottomPanel.slice';
import { EBottomPanelComponentName } from '../../../utils/constants/bottomPanel';
import { copyBite, deleteBite, updateBiteData } from '../../../store/bite/bite.actions';
import Routes from '../../../navigation/routes';
import { TouchableOpacity } from 'react-native';
import useOrganizations from '../../../hooks/useOrganizations';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import styled from 'styled-components/native';
import { IBiteItem } from '../../../types/bite';
import useIsItemOwner from '../../../hooks/useIsItemOwner';
import { activeOrganizationSelector, isContentAdminSelector } from '../../../store/auth/auth.selectors';
import { calcWidth } from '../../../utils/dimensions';
import { log } from '../../../store/appActivity/appActivity.slice';
import { StackNavigationProp } from '@react-navigation/stack';
import { settingsButtonDataSet } from '../stats.constants';

interface IProps {
  contentId: number;
  bite?: IBiteItem;
  initialSettings?: ISettingsDoneProps;
}

const BiteSettings: React.FC<IProps> = ({ contentId, initialSettings, bite: biteProp }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<any>>();

  const creatorOrganizationsList = useOrganizations({ isCreator: true });

  const bite = useSelector(biteItemSelector(contentId));
  const activeOrganization = useSelector(activeOrganizationSelector);

  const currentBite = biteProp || bite;

  const isBiteOwner = useIsItemOwner(currentBite);
  const isContentAdmin = useSelector(isContentAdminSelector);

  const isUserCanEdit = isBiteOwner || isContentAdmin;
  const isCloneAvailable = activeOrganization.is_creator;

  const settings = useMemo(
    () =>
      initialSettings || {
        is_private: bite?.is_private,
        discussion_enabled: bite?.discussion_enabled,
      },
    [initialSettings, bite?.is_private, bite?.discussion_enabled],
  );

  const [isDeleteEmptyBiteModalVisible, setDeleteEmptyBiteModalVisible] = useState(false);
  const [isDeleteBiteModalVisible, setDeleteBiteModalVisible] = useState(false);
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<IOrganization>(null);
  const [isSettingsModalVisible, setSettingsModalVisible] = useState(false);

  const openCopyModal = useCallback(() => {
    setIsCopyModalOpen(true);
  }, []);

  const closeCopyModal = useCallback(() => {
    setSelectedOrganization(null);
    setIsCopyModalOpen(false);
  }, []);

  const handleClone = useCallback(() => {
    dispatch(
      log({
        event: 'BiteSettings.handleClone',
        data: { creatorOrganizationsList },
      }),
    );

    if (creatorOrganizationsList.length > 1) {
      dispatch(
        showBottomPanel({
          componentName: EBottomPanelComponentName.OrganizationSelectMenu,
          componentProps: {
            onOrganizationSelect: (org: IOrganization) => {
              setSelectedOrganization(org);
              openCopyModal();
            },
            isCreator: true,
          },
        }),
      );
      return;
    }
    openCopyModal();
  }, [dispatch, creatorOrganizationsList, openCopyModal]);

  const handleDeleteBite = useCallback(() => {
    dispatch(
      log({
        event: 'BiteSettings.handleDeleteBite',
        data: { contentId },
      }),
    );

    dispatch(deleteBite(contentId));
    navigation.navigate(Routes.HomeStack.Home);
  }, [dispatch, navigation, contentId]);

  const closeDeleteEmptyBiteModal = useCallback(() => {
    dispatch(
      log({
        event: 'BiteSettings.closeDeleteEmptyBiteModal',
        data: { contentId },
      }),
    );

    setDeleteEmptyBiteModalVisible(false);
  }, [contentId, dispatch]);

  const closeDeleteBiteModal = useCallback(() => {
    dispatch(
      log({
        event: 'BiteSettings.closeDeleteBiteModal',
        data: { contentId },
      }),
    );

    setDeleteBiteModalVisible(false);
  }, [contentId, dispatch]);

  // const openDeleteBiteModal = useCallback(() => {
  //   setDeleteBiteModalVisible(true);
  // }, []);

  const handleOpenSettingsModal = useCallback(() => {
    dispatch(
      log({
        event: 'BiteSettings.handleOpenSettingsModal',
      }),
    );

    setSettingsModalVisible(true);
  }, [dispatch]);

  const handleCloseSettingsModal = useCallback(() => {
    dispatch(
      log({
        event: 'BiteSettings.handleCloseSettingsModal',
      }),
    );

    setSettingsModalVisible(false);
  }, [dispatch]);

  const handleApplySettings = useCallback(
    (currentSettings?: ISettingsDoneProps) => {
      dispatch(
        log({
          event: 'BiteSettings.handleApplySettings',
          data: { contentId, currentSettings },
        }),
      );

      dispatch(updateBiteData({ biteId: contentId, body: currentSettings }));
      handleCloseSettingsModal();
    },
    [dispatch, contentId, handleCloseSettingsModal],
  );

  // const handleDelete = useCallback(() => {
  //   handleCloseSettingsModal();
  //   openDeleteBiteModal();
  // }, [handleCloseSettingsModal, openDeleteBiteModal]);

  const renderSettingsLeftButton = useCallback(
    () => (
      <TouchableOpacity onPress={handleClone}>
        <S.CloneButtonText>{t('biteScreen.settings.clone')}</S.CloneButtonText>
      </TouchableOpacity>
    ),
    [handleClone, t],
  );

  // const renderSettingsRightButton = useCallback(() => {
  //   if (!isUserCanEdit) {
  //     return null;
  //   }

  //   return (
  //     <TouchableOpacity onPress={handleDelete}>
  //       <S.DeleteButtonText>{t('biteScreen.settings.delete')}</S.DeleteButtonText>
  //     </TouchableOpacity>
  //   );
  // }, [handleDelete, isUserCanEdit, t]);

  const copyBiteModalDescription = useMemo(
    () =>
      selectedOrganization
        ? t('copyBiteModal.descriptionCloneToWorkspace', {
            biteName: currentBite?.subject,
            workspaceName: selectedOrganization.name,
          })
        : t('copyBiteModal.description', {
            biteName: currentBite?.subject,
          }),
    [currentBite, selectedOrganization, t],
  );

  const handleCopyConfirmation = useCallback(() => {
    const targetOrg = creatorOrganizationsList.length === 1 ? creatorOrganizationsList[0] : selectedOrganization;

    dispatch(
      log({
        event: 'BiteSettings.handleCopyConfirmation',
        data: { contentId, targetOrg, currentBite },
      }),
    );

    dispatch(copyBite({ bite: currentBite, org: targetOrg }));
    closeCopyModal();
  }, [creatorOrganizationsList, selectedOrganization, dispatch, contentId, currentBite, closeCopyModal]);

  const goBackHome = useCallback(() => {
    dispatch(
      log({
        event: 'BiteSettings.goBackHome',
        data: { contentId },
      }),
    );

    navigation.navigate(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  }, [contentId, dispatch, navigation]);

  if (!isUserCanEdit && !isCloneAvailable) {
    return <S.EmptyView />;
  }

  return (
    <>
      <PointsButton dataSet={settingsButtonDataSet} onPress={handleOpenSettingsModal} />
      <BiteSettingsModal
        showLeftButtonOnly={!isUserCanEdit}
        isVisible={isSettingsModalVisible}
        isPrivateDefault={settings.is_private}
        isAllowedDiscussionDefault={settings.discussion_enabled}
        isSkipEnabledDefault={settings.skip_able}
        renderLeftButton={renderSettingsLeftButton}
        onClose={handleCloseSettingsModal}
        // renderRightButton={renderSettingsRightButton}
        onDone={handleApplySettings}
      />
      <BlueAndRedButtonsModal
        title={t('deleteEmptyBiteModal.title')}
        description={t('deleteEmptyBiteModal.description')}
        isVisible={isDeleteEmptyBiteModalVisible}
        onClose={closeDeleteEmptyBiteModal}
        onRightButtonClick={handleDeleteBite}
        onLeftButtonClick={goBackHome}
        rightButtonLabel={t('deleteEmptyBiteModal.delete')}
        leftButtonLabel={t('deleteEmptyBiteModal.addLater')}
      />
      <BlueAndRedButtonsModal
        title={t('deleteBiteModal.title')}
        description={t('deleteBiteModal.description')}
        isVisible={isDeleteBiteModalVisible}
        onClose={closeDeleteBiteModal}
        onRightButtonClick={handleDeleteBite}
        onLeftButtonClick={closeDeleteBiteModal}
        rightButtonLabel={t('deleteBiteModal.delete')}
        leftButtonLabel={t('deleteBiteModal.cancel')}
      />
      <BlueAndRedButtonsModal
        title={t('copyBiteModal.title')}
        description={copyBiteModalDescription}
        isVisible={isCopyModalOpen}
        onClose={closeCopyModal}
        onRightButtonClick={handleCopyConfirmation}
        onLeftButtonClick={closeCopyModal}
        rightButtonLabel={t('copyBiteModal.clone')}
        leftButtonLabel={t('copyBiteModal.close')}
        reverseColors
      />
    </>
  );
};

const S = {
  CloneButtonText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  DeleteButtonText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.failRed};
  `,
  EmptyView: styled.View`
    height: ${calcWidth(40)}px;
    width: ${calcWidth(40)}px;
  `,
};

export default memo(BiteSettings);
