import React, { memo } from 'react';
import { PreviewContent, PreviewControls } from './Preview';
import SidebarTitle from '../SidebarTitle';
import styled from 'styled-components';
import { Voice } from '../../../../utils/voices.constants';
import defaultTheme from '../../../../themes/defaultTheme';

type Props = {
  voice: Voice;
  url: string;
  onAdd: () => void;
  onReplace: () => void;
  onClose: () => void;
};
export const PreviewTextToSpeech = memo(({ voice, url, onAdd, onReplace, onClose }: Props) => {
  return (
    <>
      <SidebarTitle title='Text to speech' bold={false} />
      <S.VoiceContainer>
        <S.VoiceImageContainer>
          <S.VoiceImage src={voice.avatar} />
        </S.VoiceImageContainer>
        <S.VoiceDescriptionContainer>
          <S.VoiceName>{voice.name}</S.VoiceName>
          <S.VoiceDescription>{voice.description}</S.VoiceDescription>
        </S.VoiceDescriptionContainer>
      </S.VoiceContainer>
      <PreviewContent>
        <audio src={url} controls />
      </PreviewContent>
      <PreviewControls onAdd={onAdd} onReplace={onReplace} onClose={onClose} />
    </>
  );
});

const S = {
  VoiceContainer: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: center;
    margin: 16px 16px 0;
    width: 300px;
    background: #fff;
    cursor: pointer;
  `,
  VoiceImageContainer: styled.div`
    position: relative;
    width: 53px;
    height: 53px;
    flex-shrink: 0;
  `,
  VoiceImage: styled.img`
    width: 53px;
    height: 53px;
    border-radius: 53px;
  `,
  PlayButton: styled.div`
    position: absolute;
    top: 50%;
    left: -8px;
    width: 30px;
    height: 30px;
  `,
  VoiceDescriptionContainer: styled.div`
    flex: 1;
  `,
  VoiceName: styled.div`
    color: #313235;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  `,
  VoiceDescription: styled.div`
    color: #313235;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  `,
};
