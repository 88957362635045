import React, { memo, useEffect } from 'react';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import AnalyticsHeader from '../common/AnalyticsHeader';
import { useDispatch, useSelector } from 'react-redux';
import { contentIdSelector, currentListSelector } from '../../../store/analytics/analytics.selector';
import PlaylistAttributesFilter from './common/PlaylistAttributesFilter';
import { IStackNavigation } from '../../../navigation/types';
import FilterMenuBottomSheet from './common/FilterMenuBottomSheet';
import ExplanationVideoModal from './common/ExplanationVideoModal';
import { playlistItemSelector } from '../../../store/playlist/playlist.selectors';
import { PLAYLIST_ATTRIBUTES_SCREEN, PLAYLIST_USERS_LIST_SCREEN, STATS_OVERVIEW_SCREEN } from './constants';
import PlaylistAttributes from './Lists/PlaylistAttributes';
import PlaylistUsersList from './Lists/PlaylistUsersList';
import useHasOrganizationAttributes from '../hooks/useHasOrganizationAttributes';
import { ECurrentList } from '../../../store/analytics/analytics.types';
import { createStackNavigator } from '@react-navigation/stack';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import { useIsFocused } from '@react-navigation/native';
import { playlistSharedWithFilterSelector } from '../../../store/attributes/playlistAttributes/playlistAttributes.selector';

const Stack = createStackNavigator();

const defaultScreenOptions = {
  headerShown: false,
  cardStyle: {
    backgroundColor: 'white',
  },
  animationEnabled: false,
};

interface IProps extends IStackNavigation {}

const PlaylistAnalyticsScreen: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const { t } = useTranslation();
  const { hasOrganizationAttributes } = useHasOrganizationAttributes();
  const currentList = useSelector(currentListSelector);
  const initialRouteName =
    currentList === ECurrentList.USERS || !hasOrganizationAttributes
      ? PLAYLIST_USERS_LIST_SCREEN
      : PLAYLIST_ATTRIBUTES_SCREEN;

  const contentId = useSelector(contentIdSelector);
  const playlist = useSelector(playlistItemSelector(contentId));
  const sharedWithFilter = useSelector(playlistSharedWithFilterSelector);

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    dispatch(
      trackEvent({
        event: 'pageview',
        props: { page_title: STATS_OVERVIEW_SCREEN },
      }),
    );
  }, [dispatch, isFocused]);

  return (
    <S.Container>
      <AnalyticsHeader
        sharedWithFilter={sharedWithFilter}
        title={t('analytics.stats.title')}
        subtitle={playlist.subject}
      />
      <PlaylistAttributesFilter />

      <Stack.Navigator initialRouteName={initialRouteName}>
        {hasOrganizationAttributes && (
          <Stack.Screen
            name={PLAYLIST_ATTRIBUTES_SCREEN}
            component={PlaylistAttributes}
            options={defaultScreenOptions}
          />
        )}
        <Stack.Screen name={PLAYLIST_USERS_LIST_SCREEN} component={PlaylistUsersList} options={defaultScreenOptions} />
      </Stack.Navigator>

      <FilterMenuBottomSheet />
      <ExplanationVideoModal />
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    width: 100%;
    height: 100%;
    justify-content: space-between;
  `,
};

export default memo(PlaylistAnalyticsScreen);
