import CommentColorlessIcon from '../../../../assets/icons/analytics/comment_colorless.svg';
import WebViewModal from '../../../../components/modals/WebViewModal/WebViewModal';
import { SITE_URL } from '../../../../utils/constants/urls';
import { calcHeight, isWeb } from '../../../../utils/dimensions';
import React, { memo, useCallback, useState } from 'react';
import useToken from '../../../../hooks/useToken';
import { useDispatch, useSelector } from 'react-redux';
import { contentIdSelector } from '../../../../store/analytics/analytics.selector';
import { biteItemSelector } from '../../../../store/bite/bite.selectors';
import { getBiteShareId } from '../../../../utils/formatDataFromServer';
import styled, { useTheme } from 'styled-components/native';
import { log } from '../../../../store/appActivity/appActivity.slice';
import { headerCommentsButtonDataSet } from '../../analytics.constants';

const CommentsButton = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { token } = useToken();

  const biteId = useSelector(contentIdSelector);
  const bite = useSelector(biteItemSelector(biteId));
  const biteShareId = getBiteShareId(bite);

  const [isShowComments, setIsShowComments] = useState(false);

  const handleShowComments = useCallback(() => {
    dispatch(
      log({
        event: 'CommentsButton.handleShowComments',
      }),
    );

    setIsShowComments(true);
  }, [dispatch]);

  const handleCloseComments = useCallback(() => {
    dispatch(
      log({
        event: 'CommentsButton.handleCloseComments',
      }),
    );

    setIsShowComments(false);
  }, [dispatch]);

  return (
    <>
      <S.HeaderButton
        // @ts-ignore
        dataSet={headerCommentsButtonDataSet}
        onPress={handleShowComments}
      >
        <CommentColorlessIcon />
      </S.HeaderButton>
      <WebViewModal
        isVisible={isShowComments}
        url={`${SITE_URL}/bites/${biteShareId}/discussion?token=${token}&rnd=${Date.now()}&isPreview=1&keepSection=true&hideHeader=true&showSectionTitleHeader=true`}
        onClose={handleCloseComments}
        onModalHide={handleCloseComments}
        controlsPanel={!isWeb && <S.WebViewBottom />}
        topInsetColor={theme.colors.lightGray3}
        bottomInsetColor={theme.colors.gray29}
        withCloseIcon
      />
    </>
  );
};

const S = {
  HeaderButton: styled.TouchableOpacity`
    padding: 10px;
  `,
  WebViewBottom: styled.View`
    height: ${calcHeight(24)}px;
    background-color: ${({ theme }) => theme.colors.gray29};
  `,
  WebViewTop: styled.View<{ height?: number }>`
    height: ${({ height }) => calcHeight(height || 24)}px;
    background-color: ${({ theme }) => theme.colors.lightGray3};
  `,
};

export default memo(CommentsButton);
