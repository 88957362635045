import { IS_PROD } from './env';

export const BITES_ORGANIZATION_ID = 1;

type FeatureFlagIds = {
  SmsDistribution: number;
  OrgChart: number;
  AiStudio: number;
  PlaylistStats: number;
};

export const FeatureFlagStagingIds: FeatureFlagIds = {
  SmsDistribution: 1,
  OrgChart: 2,
  AiStudio: 658134,
  PlaylistStats: 853030,
};

export const FeatureFlagProductionIds: FeatureFlagIds = {
  SmsDistribution: 1,
  OrgChart: 2,
  AiStudio: 658134,
  PlaylistStats: 853030,
};

export const FEATURE_FLAG_IDS = IS_PROD ? FeatureFlagProductionIds : FeatureFlagStagingIds;
