import React, { memo, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentListSelector } from '../../../../store/analytics/analytics.selector';
import { useCallback } from 'react';
import Filter from '../common/Filter';
import AnalyticsList from '../../common/AnalyticsList';
import { EEngagedFilterValue, IAnalyticsViewsListItem } from '../../../../store/attributes/biteStats/biteStats.types';
import { ViewIcons } from '../common/Icons';
import ListItemComponent from '../common/ListItem/ListItem';
import { useIsFocused } from '@react-navigation/native';
import HeaderTabs from '../../common/HeaderTabs';
import { log } from '../../../../store/appActivity/appActivity.slice';
import useHasAttributesToRender from '../../hooks/useHasAttributesToRender';
import useViewsAttributesMap from '../hooks/useViewsAttributesMap';
import { IStackNavigation } from '../../../../navigation/types';
import useHasOrganizationAttributes from '../../hooks/useHasOrganizationAttributes';
import useAttributesData from '../../hooks/useAttributesData';
import { getViewsAttributes } from '../../../../store/attributes/biteAttributes/biteAttributes.slice';
import { EAnalyticsScreenType } from '../../Analytics.types';
import { VIEWS_ATTRIBUTES_SCREEN, VIEWS_LIST_SCREEN } from '../constants';
import useHeaderTabsDivider from '../../hooks/useHeaderTabsDivider';
import { analyticsListDataSet } from '../../analytics.constants';
import {
  isNeedToScrollUpViewsSelector,
  viewsDataSelector,
  viewsErrorSelector,
  viewsFilterSelector,
  viewsLoadingSelector,
  viewsNextSelector,
} from '../../../../store/attributes/biteStats/biteStats.selector';
import {
  getViewsData,
  setIsNeedToScrollUp,
  setViewsData,
  setViewsFilter,
} from '../../../../store/attributes/biteStats/biteStats.slice';
import { ECurrentList } from '../../../../store/analytics/analytics.types';
import { selectedFilterAttributeValueIdsSelector } from '../../../../store/attributes/biteAttributes/biteAttributes.selector';

const VIEWS_FILTERS = [
  {
    label: 'analytics.filter.all',
    value: EEngagedFilterValue.ALL,
  },
  {
    label: 'analytics.filter.engaged',
    value: EEngagedFilterValue.ENGAGED,
  },
  {
    label: 'analytics.filter.notEngaged',
    value: EEngagedFilterValue.NOT_ENGAGED,
  },
];

interface IProps extends IStackNavigation {}

const ViewsList: React.FC<IProps> = ({ navigation }) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const viewsList = useSelector(viewsDataSelector);
  const viewsListError = useSelector(viewsErrorSelector);
  const viewsNext = useSelector(viewsNextSelector);
  const currentFilter = useSelector(viewsFilterSelector);
  const isLoading = useSelector(viewsLoadingSelector);
  const isNeedToScrollUp = useSelector(isNeedToScrollUpViewsSelector);

  const selectedValueIds = useSelector(selectedFilterAttributeValueIdsSelector);

  const { attributesMap } = useViewsAttributesMap();
  const { hasAttributesToRender } = useHasAttributesToRender({ attributesMap });
  const { handleScroll, isShowHeaderBottomDivider } = useHeaderTabsDivider();

  useAttributesData({
    attributesMap,
    onLoad: getViewsAttributes,
  });

  const currentList = useSelector(currentListSelector);
  const { hasOrganizationAttributes } = useHasOrganizationAttributes();

  const handleSetIsNeedToScrollUp = useCallback(
    (props) => {
      dispatch(setIsNeedToScrollUp(props));
    },
    [dispatch],
  );

  const handleFilterChange = useCallback(
    (filter) => {
      dispatch(
        log({
          event: 'ViewsList.handleFilterChange',
          data: { filter },
        }),
      );

      dispatch(setViewsData({ results: [], next: null, reset: true }));
      dispatch(setViewsFilter(filter));
      dispatch(getViewsData());
    },
    [dispatch],
  );

  const renderListItem = useCallback(
    ({ index, item }) => {
      const withDivider = index > 0 && !item.activity && !!viewsList[index - 1]?.activity;
      return <ListItem item={item} withDivider={withDivider} />;
    },
    [viewsList],
  );

  const handleEndReached = useCallback(() => {
    dispatch(
      log({
        event: 'ViewsList.handleEndReached',
        data: { viewsNext, isLoading, viewsListError },
      }),
    );
    dispatch(getViewsData());
  }, [dispatch, isLoading, viewsListError, viewsNext]);

  const handleEndReachedProp = useMemo(() => {
    if (!viewsNext || isLoading || viewsListError) {
      return;
    }

    return handleEndReached;
  }, [handleEndReached, isLoading, viewsListError, viewsNext]);

  const handleRefresh = useCallback(
    ({ isPullToRefresh, callback }) => {
      dispatch(
        log({
          event: 'ViewsList.handleRefresh',
        }),
      );
      dispatch(getViewsData({ reset: true, clearAnalyticsCache: isPullToRefresh, callback }));
    },
    [dispatch],
  );

  const handleErrorRefresh = useCallback(() => {
    dispatch(
      log({
        event: 'ViewsList.handleErrorRefresh',
      }),
    );
    dispatch(getViewsData());
  }, [dispatch]);

  const switchedToFocusRef = useRef(isFocused);

  useEffect(() => {
    const switchedToFocus = isFocused && !switchedToFocusRef.current;
    switchedToFocusRef.current = isFocused;

    if (isLoading || !isFocused || (viewsList !== null && (!viewsListError || !switchedToFocus))) {
      return;
    }

    dispatch(getViewsData({ reset: !viewsListError }));
  }, [dispatch, isFocused, isLoading, viewsList, viewsListError]);

  useEffect(() => {
    if (currentList === ECurrentList.ATTRIBUTES && hasOrganizationAttributes) {
      navigation.replace(VIEWS_ATTRIBUTES_SCREEN);
    }
  }, [currentList, hasOrganizationAttributes, navigation]);

  return (
    <>
      <HeaderTabs
        currentScreen={VIEWS_LIST_SCREEN}
        attributesScreen={VIEWS_ATTRIBUTES_SCREEN}
        listScreen={VIEWS_LIST_SCREEN}
        rightComponent={
          <Filter currentFilter={currentFilter} filters={VIEWS_FILTERS} onChangeFilter={handleFilterChange} />
        }
        disableAttributes={!hasAttributesToRender}
        isShowBottomDivider={isShowHeaderBottomDivider}
      />
      <AnalyticsList
        dataSet={analyticsListDataSet}
        data={viewsList}
        renderItem={renderListItem}
        onEndReached={handleEndReachedProp}
        onErrorRefresh={handleErrorRefresh}
        isLoading={isLoading}
        onRefresh={handleRefresh}
        error={viewsListError}
        selectedValueIds={selectedValueIds}
        listType={EAnalyticsScreenType.VIEWS}
        isNeedToScrollUp={isNeedToScrollUp}
        scrollEventThrottle={10}
        onScroll={handleScroll}
        setIsNeedToScrollUp={handleSetIsNeedToScrollUp}
      />
    </>
  );
};

interface IListItemProps {
  item: IAnalyticsViewsListItem;
  withDivider?: boolean;
}

const ListItem: React.FC<IListItemProps> = ({ item, withDivider }) => {
  const { activity, user } = item;

  const renderIcons = useCallback(() => {
    return <ViewIcons activity={activity} />;
  }, [activity]);

  return (
    <ListItemComponent
      lastActivity={activity?.view_intro_date || activity?.last_interaction_date}
      user={user}
      renderIcons={renderIcons}
      withDivider={withDivider}
    />
  );
};

export default memo(ViewsList);
