import Skeleton from '../../../../components/shared/Skeleton/Skeleton';
import React, { memo, useCallback } from 'react';
import styled from 'styled-components/native';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../../utils/dimensions';
import Divider from '../../../../components/shared/Divider';

interface IProps {
  rowsNum?: number;
  valuesNum?: number;
}

const LoadingState: React.FC<IProps> = ({ rowsNum = 5, valuesNum = 3 }) => {
  const renderComponent = useCallback(
    (_, index) => (
      <React.Fragment key={index}>
        <S.ListItemContainer>
          <S.ListItem>
            <S.ListItemTitle>
              <Skeleton width={100} height={20} borderRadius={4} />
            </S.ListItemTitle>
            <S.IconsContainer>
              {Array(valuesNum)
                .fill(0)
                .map(() => (
                  <S.IconContainer>
                    <S.ValueText>
                      <Skeleton width={30} height={20} borderRadius={4} />
                    </S.ValueText>
                  </S.IconContainer>
                ))}
            </S.IconsContainer>
          </S.ListItem>
        </S.ListItemContainer>
        <S.Divider />
      </React.Fragment>
    ),
    [valuesNum],
  );

  return <S.ListItemContainer>{Array(rowsNum).fill(0).map(renderComponent)}</S.ListItemContainer>;
};

const S = {
  ListItemContainer: styled.View`
    padding: 0 ${calcWidth(19)}px;
  `,
  ListItemTitle: styled.Text`
    flex: 3;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
  `,
  ListItem: styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${calcHeight(16)}px 0;
  `,
  IconsContainer: styled.View`
    flex: 1;
    width: ${isWeb ? calcWidth(512) : deviceWidth - calcWidth(38)}px;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
  `,
  IconContainer: styled.View`
    align-items: center;
    height: ${calcHeight(24)}px;
    width: ${calcWidth(30)}px;
    margin-left: ${calcWidth(12)}px;
    justify-content: center;
  `,
  ValueText: styled.Text`
    text-align: center;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
  `,
  Divider: styled(Divider)`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lightGray1};
  `,
};

export default memo(LoadingState);
