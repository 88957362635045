import {
  ESharedWithFilter,
  ESortingDirection,
  IOrganizationAttribute,
  IOrganizationAttributeMap,
  IOrganizationAttributeValue,
  TDisplayMode,
} from '../../../types/anayltics';

export interface IInitialState {
  displayMode: TDisplayMode;
  attributesMapById: {
    sortBy: ESortByType;
    sortDirection: ESortingDirection;
    absolute: IPlaylistAttributeMap;
    percentage: IPlaylistAttributeMap;
  };
  isGuestsAttributesLoaded: boolean;
  selectedValueIds: number[];
  filterAttributesMapById: IOrganizationAttributeMap;
  initialAttributesMapById: IPlaylistAttributeMap;
  sharedWithFilter: ESharedWithFilter[];
  filterSearchValue: string;
  searchValues: {
    isLoading: boolean;
    error?: string;
    data: IOrganizationAttributeValue[];
    next: null | string;
  };

  organizationAttributes: {
    isLoading: boolean;
    error?: string;
    data: IOrganizationAttribute[];
  };

  guestsAttribute: {
    isLoading: boolean;
    error?: string;
    data: PlaylistStats;
  };
}

export interface IPlaylistAttributeMap {
  [key: number]: IPlaylistAttributeMapItem;
}

export interface IPlaylistAttributeMapItem {
  isLoading: boolean;
  error?: string;
  data: PlaylistAttributeTitle;
  selectedValues?: PlaylistAttributeValue[];
  next?: string;
}

export enum ESortBy {
  STARTED_ABSOLUTE = 'started_absolute',
  STARTED_PERCENTAGE = 'started_percentage',
  PROGRESS_ABSOLUTE = 'progress_absolute',
  PROGRESS_PERCENTAGE = 'progress_percentage',
  COMPLETED_ABSOLUTE = 'completed_absolute',
  COMPLETED_PERCENTAGE = 'completed_percentage',
  SUCCESS_ABSOLUTE = 'success_absolute',
  SUCCESS_PERCENTAGE = 'success_percentage',
}

export enum ESortByType {
  STARTED = 'started',
  PROGRESS = 'progress',
  COMPLETED = 'completed',
  SUCCESS = 'success',
}

export type PlaylistStats = {
  started_users_num: number; // absolute, number of users who started in the playlist
  shared_with_users_num: number | null; // total number of users shared with
  viewed_bite_shares_num: number; // total sum how many bite shares were viewed
  completed_users_num: number; // number of users who completed all active bite shares in the playlist
  total_answers_num: number;
  answered_correctly_num: number;
};

export type PlaylistAttributeValue = {
  id: number;
  attribute_title_id: number;
  name: string;
  stats: PlaylistStats;
};

export type PlaylistAttributeTitle = {
  id: number;
  title: string;
  values: PlaylistAttributeValue[];
};
