import Routes from '../../navigation/routes';
import { getHomeScreen } from './common';
import { EAnalyticsScreenType } from '../../screens/analytics/Analytics.types';

export const getAnalyticsStatsRoute = (tab: EAnalyticsScreenType) => ({
  index: 2,
  routes: [
    getHomeScreen(),
    {
      name: Routes.AnalyticsStack.StackName,
      params: {
        screen: Routes.AnalyticsStack.BiteOverview,
      },
    },
    {
      name: Routes.AnalyticsStack.StackName,
      params: {
        screen: Routes.AnalyticsStack.BiteStats,
        params: {
          initialRouteName: tab,
        },
      },
    },
  ],
});
