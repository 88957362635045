import React, { memo, useCallback, useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components/native';
import { useSelector } from 'react-redux';
import { selectedValuesByAttributeIdSelector } from '../../../../store/attributes/biteAttributes/biteAttributes.selector';
import { selectedValuesByAttributeIdSelector as selectedPlaylistValuesByAttributeIdSelectorBite } from '../../../../store/attributes/playlistAttributes/playlistAttributes.selector';
import { attributesFilterDataSet, attributesFilterItemDataSet } from '../../analytics.constants';
import Skeleton from '../../../../components/shared/Skeleton/Skeleton';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import ArrowDownIcon from '../../../../assets/icons/feed/arrow-down.svg';
import { IOrganizationAttribute } from '../../../../types/anayltics';
import { IS_IOS } from '../../../../utils/constants/env';
import { contentTypeSelector } from '../../../../store/analytics/analytics.selector';
import { EAnalyticsContentType } from '../../../../store/analytics/analytics.types';
import { AnalyticsAttributeMap } from '../../Analytics.types';

interface IFilterListItemProps {
  item: IOrganizationAttribute;
  onPress: (item: IOrganizationAttribute) => void;
  isRtl: boolean;
  isWebRtl: boolean;
  initialAttributesMap: AnalyticsAttributeMap;
}

const FilterListItem: React.FC<IFilterListItemProps> = ({ item, onPress, isRtl, isWebRtl, initialAttributesMap }) => {
  const theme = useTheme();

  const contentType = useSelector(contentTypeSelector);
  const biteSelectedValues = useSelector(selectedValuesByAttributeIdSelector(item.id));
  const playlistSelectedValues = useSelector(selectedPlaylistValuesByAttributeIdSelectorBite(item.id));

  const selectedValues = contentType === EAnalyticsContentType.BITE ? biteSelectedValues : playlistSelectedValues;

  const isSelected = !!selectedValues.length;
  const isEmpty = !item.values?.length && !isSelected;

  const isLoading = useMemo(
    () => item.values === null && initialAttributesMap[item.id].data.values.length,
    [initialAttributesMap, item.id, item.values],
  );

  const handlePress = useCallback(() => {
    if (isEmpty) {
      return;
    }

    onPress(item);
  }, [isEmpty, item, onPress]);

  return (
    <S.ListItemContainer
      // @ts-ignore
      dataSet={attributesFilterDataSet}
      isWebRtl={isWebRtl}
      isRtl={isRtl}
      activeOpacity={isEmpty ? 1 : undefined}
      isEmpty={isEmpty}
      onPress={handlePress}
      isSelected={isSelected}
    >
      {isLoading ? (
        <Skeleton height={calcHeight(20)} width={calcWidth(70)} />
      ) : (
        <>
          <S.ListItemText
            // @ts-ignore
            dataSet={attributesFilterItemDataSet}
            isSelected={isSelected}
            isEmpty={isEmpty}
          >
            {selectedValues.length === 1 ? selectedValues[0].name : item.title}
          </S.ListItemText>
          {selectedValues.length > 1 && (
            <S.SelectedNumberContainer>
              <S.SelectedNumberText>{selectedValues.length}</S.SelectedNumberText>
            </S.SelectedNumberContainer>
          )}
          {!isEmpty && (
            <S.ArrowDownIconContainer>
              <ArrowDownIcon color={theme.colors.primaryBlue} />
            </S.ArrowDownIconContainer>
          )}
        </>
      )}
    </S.ListItemContainer>
  );
};

const S = {
  ListItemContainer: styled.TouchableOpacity<{
    isWebRtl: boolean;
    isRtl: boolean;
    isEmpty?: boolean;
    isSelected: boolean;
  }>`
    align-items: center;
    border-radius: 4px;
    background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.lightBlue : 'transparent')};
    padding: ${calcHeight(6)}px ${calcWidth(3)}px ${calcHeight(isWeb ? 6 : 3)}px ${calcWidth(6)}px;
    flex-direction: ${({ isWebRtl }) => (isWebRtl ? 'row-reverse' : 'row')};
    ${({ isRtl }) =>
      isRtl
        ? css`
            margin-left: ${calcWidth(12)}px;
          `
        : css`
            margin-right: ${calcWidth(12)}px;
          `}
    ${({ isEmpty }) =>
      isWeb
        ? css`
            cursor: ${isEmpty ? 'default' : 'pointer'};
          `
        : ''};
  `,
  ListItemText: styled.Text<{ isSelected?: boolean; isEmpty?: boolean }>`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    color: ${({ theme, isSelected, isEmpty }) =>
      isSelected ? theme.colors.text : isEmpty ? theme.colors.gray17 : theme.colors.gray19};
    font-weight: ${({ isSelected }) => (isSelected ? '700' : '400')};
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
    height: ${({ theme }) => theme.fontSizes.s14}px;
    text-align: center;
    margin-bottom: ${calcHeight(IS_IOS ? 2 : 0)}px;
  `,
  SelectedNumberContainer: styled.View`
    width: ${calcWidth(17)}px;
    height: ${calcHeight(17)}px;
    border-radius: ${calcWidth(9)}px;
    background-color: ${({ theme }) => theme.colors.primaryBlue};
    align-items: center;
    justify-content: center;
    margin-left: ${calcWidth(4)}px;
    margin-bottom: ${calcHeight(isWeb ? 0 : 4)}px;
  `,
  SelectedNumberText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
    text-align: center;
  `,
  ArrowDownIconContainer: styled.View`
    width: ${calcWidth(16)}px;
    height: ${calcHeight(16)}px;
    align-items: center;
    justify-content: center;
    margin-bottom: ${isWeb ? 0 : calcHeight(4)}px;
  `,
};

export default memo(FilterListItem);
