import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectedTimelineItemIdSelector, timelineItemSeletor } from '../../../store/videoEditor/videoEditor.selectors';
import defaultTheme from '../../../themes';
import { cloudAssetSelector } from '../../../store/cloudAssets/cloudAssets.selector';
import { VOICES_LIST } from '../../../utils/voices.constants';
import CaretDownIcon from '../../../assets/icons/videoEditor/caret-down.svg';
import EditIcon from '../../../assets/icons/videoEditor/edit.svg';
import { TextToSpeechSettingsModal } from './TextToSpeechSettingsModal';
import { setHighlightSelectedTimelineItem } from '../../../store/videoEditor/videoEditor.slice';

const TextToSpeechSettings = () => {
  const dispatch = useDispatch();

  const selectedTimelineItemId = useSelector(selectedTimelineItemIdSelector);
  const timelineItem = useSelector(timelineItemSeletor(selectedTimelineItemId));
  const cloudAsset = useSelector(cloudAssetSelector(timelineItem.cloudAssetId));

  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);

  const voice = useMemo(
    () => VOICES_LIST.find((v) => v.id === cloudAsset.sourceMeta.voiceId),
    [cloudAsset.sourceMeta.voiceId],
  );

  const handleToggleEditOpen = useCallback(() => {
    setIsEditModalOpen(true);
    dispatch(setHighlightSelectedTimelineItem(true));
  }, [dispatch]);

  const handleCloseEditModal = useCallback(() => {
    setIsEditModalOpen(false);
    dispatch(setHighlightSelectedTimelineItem(null));
  }, [dispatch]);

  return (
    <>
      <S.Container>
        <S.VoiceContainer onClick={handleToggleEditOpen}>
          <S.VoiceImageContainer>
            <S.VoiceImage src={voice.avatar} />
          </S.VoiceImageContainer>
          <S.VoiceName>{voice.name}</S.VoiceName>
          <S.CaretIcon>
            <CaretDownIcon />
          </S.CaretIcon>
        </S.VoiceContainer>
        <S.Spacing size={2} />
        <S.ControlTitle>Volume</S.ControlTitle>
        <S.Spacing />
        <S.TextContainer onClick={handleToggleEditOpen}>{cloudAsset.sourceMeta.text}</S.TextContainer>
        <S.EditBtnContainer>
          <S.EditBtn onClick={handleToggleEditOpen}>
            <EditIcon />
            <S.EditBtnText>Edit</S.EditBtnText>
          </S.EditBtn>
        </S.EditBtnContainer>
      </S.Container>
      <TextToSpeechSettingsModal
        isVisible={isEditModalOpen}
        initialText={cloudAsset.sourceMeta.text}
        initialVoiceId={cloudAsset.sourceMeta.voiceId}
        initialCloudAsset={cloudAsset}
        onClose={handleCloseEditModal}
      />
    </>
  );
};
export default TextToSpeechSettings;

const S = {
  Container: styled.div`
    flex: 1;
    padding-top: 16px;
    border-top: 1px solid ${defaultTheme.colors.lightGray45};
    background: #fafaff;
  `,
  ControlTitle: styled.div`
    padding: 0 8px;
    font-size: 13px;
    font-weight: 700;
    font-family: ${defaultTheme.fontFamilies.Arimo};
  `,
  Spacing: styled.div<{ size?: number }>`
    height: ${({ size = 1 }) => size * 8}px;
  `,
  VoiceContainer: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    margin: 0 8px;
    padding: 6px 8px;
    border-radius: 5px;
    border: 1px solid rgba(151, 151, 151, 0.3);
    cursor: pointer;

    &:hover {
      background-color: #efeff4;
    }
  `,
  VoiceImageContainer: styled.div`
    position: relative;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
  `,
  VoiceImage: styled.img`
    width: 30px;
    height: 30px;
    border-radius: 30px;
  `,
  VoiceName: styled.div`
    flex: 1;
    color: #313235;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  `,
  CaretIcon: styled.div`
    display: flex;
    align-items: center;
    width: 18px;
  `,
  TextContainer: styled.div`
    flex: 1;
    margin: 0 8px;
    padding: 8px 12px;
    border-radius: 5px;
    border: 1px solid rgba(151, 151, 151, 0.3);
    color: #313235;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;

    &:hover {
      background-color: #efeff4;
    }
  `,
  EditBtnContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 8px;
  `,
  EditBtn: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #efeff4;
    }
  `,
  EditBtnText: styled.div`
    margin-left: 4px;
    color: ${defaultTheme.colors.primaryBlue};
    font-family: ${defaultTheme.fontFamilies.Arimo};
    text-align: center;
    font-size: 13px;
    font-weight: 400;
  `,
};
