import React, { FC, memo } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import Routes from '../../routes';
import BiteAnalyticsScreen from '../../../screens/analytics/bite/BiteAnalyticsScreen';
import BiteScreen from '../../../screens/stats/bite/BiteScreen';
import BitePreview from '../../../screens/stats/bite/BitePreview';
import { useSelector } from 'react-redux';
import { contentIdSelector, contentTypeSelector } from '../../../store/analytics/analytics.selector';
import { IStackNavigation } from '../../types';
import ReturnToFeed from '../../../components/shared/ReturnToFeed/ReturnToFeed';
import { biteItemSelector } from '../../../store/bite/bite.selectors';
import { biteOrganizationAttributesSelector } from '../../../store/attributes/biteAttributes/biteAttributes.selector';
import { isWeb } from '../../../utils/dimensions';
import PlaylistScreen from '../../../screens/stats/playlist/PlaylistScreen';
import { playlistItemSelector } from '../../../store/playlist/playlist.selectors';
import PlaylistPreview from '../../../screens/stats/playlist/PlaylistPreview';
import PlaylistAnalyticsScreen from '../../../screens/analytics/playlist/PlaylistAnalyticsScreen';
import { playlistOrganizationAttributesSelector } from '../../../store/attributes/playlistAttributes/playlistAttributes.selector';
import { IOrganizationAttribute } from '../../../types/anayltics';
import { EAnalyticsContentType } from '../../../store/analytics/analytics.types';

interface IProps extends IStackNavigation {}

const Stack = createStackNavigator();

const defaultScreenOptions = {
  headerShown: false,
  animationEnabled: !isWeb,
  cardStyle: {
    backgroundColor: 'white',
  },
};

const AnalyticsStack: FC<IProps> = () => {
  const contentId = useSelector(contentIdSelector);
  const bite = useSelector(biteItemSelector(contentId));
  const playlist = useSelector(playlistItemSelector(contentId));
  const biteAttributes: IOrganizationAttribute[] = useSelector(biteOrganizationAttributesSelector);
  const playlistAttributes: IOrganizationAttribute[] = useSelector(playlistOrganizationAttributesSelector);
  const contentType = useSelector(contentTypeSelector);
  const content = contentType === EAnalyticsContentType.BITE ? bite : playlist;

  if (!contentId || !content) {
    return <ReturnToFeed />;
  }

  return (
    <Stack.Navigator>
      <Stack.Screen name={Routes.AnalyticsStack.BiteOverview} component={BiteScreen} options={defaultScreenOptions} />
      <Stack.Screen name={Routes.AnalyticsStack.BitePreview} component={BitePreview} options={defaultScreenOptions} />
      {!!biteAttributes && (
        <Stack.Screen
          name={Routes.AnalyticsStack.BiteStats}
          component={BiteAnalyticsScreen}
          options={defaultScreenOptions}
        />
      )}

      <Stack.Screen
        name={Routes.AnalyticsStack.PlaylistOverview}
        component={PlaylistScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name={Routes.AnalyticsStack.PlaylistPreview}
        component={PlaylistPreview}
        options={defaultScreenOptions}
      />
      {!!playlistAttributes && (
        <Stack.Screen
          name={Routes.AnalyticsStack.PlaylistStats}
          component={PlaylistAnalyticsScreen}
          options={defaultScreenOptions}
        />
      )}
    </Stack.Navigator>
  );
};

export default memo(AnalyticsStack);
