import React, { memo, useCallback, useMemo } from 'react';
import Routes from '../../../navigation/routes';
import styled, { useTheme } from 'styled-components/native';
import ShadowedContainer from '../../../components/ShadowedContainer';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import ArrowDown from '../../../assets/icons/feed/arrow-down.svg';
import ShareSection from '../../creationBite/common/ShareSection';
import Constants from '../../Shared/constants';
import Button from '../../../components/shared/buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { biteItemSelector, bitesLoadingStateMapSelector } from '../../../store/bite/bite.selectors';
import { EButtonSize } from '../../../types/common';
import useIsItemOwner from '../../../hooks/useIsItemOwner';
import { isContentAdminSelector } from '../../../store/auth/auth.selectors';
import Skeleton from '../../../components/shared/Skeleton';
import { contentIdSelector } from '../../../store/analytics/analytics.selector';
import useIsDraft from '../../../hooks/useIsDraft';
import { log } from '../../../store/appActivity/appActivity.slice';
import { EditButtonDataSet } from '../../Preview/Preview.contants';
import { AWS_URL } from '../../../utils/constants/env';
import BiteLogo from '../../../assets/images/bite-logo.png';
import { getIsRtl, getIsWebRtl } from '../../../locale/i18n';
import { StackNavigationProp } from '@react-navigation/stack';
import { bitePreviewButtonDataSet } from '../stats.constants';

interface IProps {
  isRefreshing: boolean;
}

const BiteOverview: React.FC<IProps> = ({ isRefreshing }) => {
  const navigation = useNavigation<StackNavigationProp<any>>();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const isRtl = getIsRtl();
  const isWebRtl = getIsWebRtl();

  const biteId = useSelector(contentIdSelector);
  const bite = useSelector(biteItemSelector(biteId));
  const isDraft = useIsDraft(bite?.id);
  const biteLoadingState = useSelector(bitesLoadingStateMapSelector(biteId));
  const isBiteOwner = useIsItemOwner(bite);
  const isContentAdmin = useSelector(isContentAdminSelector);
  const isUserCanEdit = isBiteOwner || isContentAdmin;
  const isLoading = biteLoadingState.isLoading;

  const resizeMode = bite?.linked_cover_url ? 'cover' : 'contain';

  const source = useMemo(() => {
    const workspaceLogoUrl = bite?.branding?.logo_url ? `${AWS_URL}/${bite.branding.logo_url}` : null;
    const uri = bite?.linked_cover_url || workspaceLogoUrl;

    return uri ? { uri } : BiteLogo;
  }, [bite?.branding?.logo_url, bite?.linked_cover_url]);

  const handlePressEditBite = useCallback(() => {
    dispatch(
      log({
        event: 'BiteOverview.handlePressEditBite',
        data: { biteId: bite?.id, isGoBack: true, is_draft: isDraft },
      }),
    );

    navigation.navigate(Routes.EditBiteStack.StackName, {
      screen: Routes.EditBiteStack.EditMain,
      params: { biteId: bite?.id, isGoBack: true },
    });
  }, [bite?.id, dispatch, isDraft, navigation]);

  const renderLeftComponent = useCallback(
    (disabled) => {
      return (
        <Button
          isShadowed
          onPress={handlePressEditBite}
          disabled={disabled}
          text={t('analytics.overview.editBite')}
          style={buttonStyle}
          fill={theme.colors.white}
          border={theme.colors.primaryBlue}
          textColor={theme.colors.primaryBlue}
          dataSet={EditButtonDataSet}
        />
      );
    },
    [handlePressEditBite, t, theme.colors.primaryBlue, theme.colors.white],
  );

  const handlePreviewPress = useCallback(() => {
    dispatch(
      log({
        event: 'BiteOverview.handlePreviewPress',
        data: { biteId: bite?.id },
      }),
    );

    navigation.navigate(Routes.AnalyticsStack.BitePreview, { biteId: bite?.id });
  }, [bite?.id, dispatch, navigation]);

  return (
    <ShadowedContainer distance={14} radius={14}>
      <S.Container>
        <S.Preview
          //@ts-ignore
          dataSet={bitePreviewButtonDataSet}
          isRtl={isWebRtl}
          onPress={handlePreviewPress}
        >
          <S.TitleBlock isRtl={isWebRtl}>
            {isLoading && !isRefreshing ? (
              <>
                <Skeleton width={calcWidth(47)} height={calcHeight(66)} borderRadius={7} />
                <S.Title>
                  <Skeleton width={calcWidth(210)} height={calcHeight(16)} />
                  <Skeleton width={calcWidth(100)} height={calcHeight(16)} />
                </S.Title>
              </>
            ) : (
              <>
                <S.Image source={source} resizeMode={resizeMode} />
                <S.Title>{bite?.subject}</S.Title>
              </>
            )}
          </S.TitleBlock>
          <S.ArrowContainer isRtl={isRtl}>
            <ArrowDown color={theme.colors.primaryBlue} />
          </S.ArrowContainer>
        </S.Preview>
        {!!bite && (
          <>
            <S.Divider />
            <ShareSection
              disabled={isLoading && !isRefreshing}
              buttonSize={EButtonSize.SMALL}
              subject={bite?.subject}
              shareData={bite}
              from={Constants.CreationFlows.BiteCreation}
              leftButtonComponent={isUserCanEdit ? renderLeftComponent : null}
            />
          </>
        )}
      </S.Container>
    </ShadowedContainer>
  );
};

const buttonStyle = {
  height: calcHeight(42),
  width: calcWidth(103),
};

const S = {
  Container: styled.View`
    margin-top: 12px;
    width: ${calcWidth(351)}px;
    background: white;
    border-radius: 20px;
    padding-bottom: ${calcHeight(19)}px;
  `,
  Image: styled.Image`
    width: ${calcWidth(47)}px;
    height: ${calcHeight(66)}px;
    border-radius: 7px;
  `,
  Preview: styled.TouchableOpacity<{ isRtl: boolean }>`
    width: 100%;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
    padding: 10px 18px;
  `,
  Divider: styled.View`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.lightGray32};
  `,
  Title: styled.Text`
    max-width: ${calcWidth(240)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    margin: ${calcHeight(6)}px ${calcWidth(16)}px 0;
    font-size: ${({ theme }) => theme.fontSizes.s14};
    font-weight: 700;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.text};
  `,
  TitleBlock: styled.View<{ isRtl: boolean }>`
    flex: 1;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
  `,
  ArrowContainer: styled.View<{ isRtl: boolean }>`
    width: ${calcWidth(24)}px;
    height: ${calcHeight(24)}px;

    transform: ${({ isRtl }) => (isRtl ? 'rotate(90deg)' : 'rotate(-90deg)')};
  `,
};

export default memo(BiteOverview);
