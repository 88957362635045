import { RootState } from '../../index';
import { TDisplayMode } from '../../../types/anayltics';

export const organizationAttributesLoadingSelector = (state: RootState) =>
  state.playlistAttributes.organizationAttributes.isLoading;
export const playlistAttributesAbsoluteMapSelector = (state: RootState) =>
  state.playlistAttributes.attributesMapById.absolute;
export const playlistAttributesPercentageMapSelector = (state: RootState) =>
  state.playlistAttributes.attributesMapById.percentage;
export const playlistAttributeNextSelector = (attributeId: number, displayMode: TDisplayMode) => (state: RootState) =>
  state.playlistAttributes.attributesMapById[displayMode][attributeId]?.next;

export const playlistOrganizationAttributesSelector = (state: RootState) =>
  state.playlistAttributes.organizationAttributes.data;

export const organizationAttributesErrorSelector = (state: RootState) =>
  state.playlistAttributes.organizationAttributes.error;

export const playlistFilterAttributesMapByIdSelector = (state: RootState) =>
  state.playlistAttributes.filterAttributesMapById;

export const playlistInitialAttributesMapByIdSelector = (state: RootState) =>
  state.playlistAttributes.initialAttributesMapById;

export const selectedValuesByAttributeIdSelector = (attributeId: number) => (state: RootState) =>
  state.playlistAttributes.filterAttributesMapById?.[attributeId]?.selectedValues;

export const selectedFilterAttributeValueIdsSelector = (state: RootState) => state.playlistAttributes.selectedValueIds;

export const filterAttributeByIdLoadingSelector = (attributeId: number) => (state: RootState) =>
  state.playlistAttributes.filterAttributesMapById?.[attributeId]?.isLoading;

export const searchValueSelector = (state: RootState) => state.playlistAttributes.filterSearchValue;

export const playlistDisplayModeSelector = (state: RootState) => state.playlistAttributes.displayMode;

export const filterAttributeByIdSelector = (attributeId: number) => (state: RootState) =>
  state.playlistAttributes.filterAttributesMapById?.[attributeId];

export const searchDataSelector = (state: RootState) => state.playlistAttributes.searchValues.data || [];

export const searchValuesLoadingSelector = (state: RootState) => state.playlistAttributes.searchValues.isLoading;

export const searchValuesErrorSelector = (state: RootState) => state.playlistAttributes.searchValues.error;

export const searchValuesNextSelector = (state: RootState) => state.playlistAttributes.searchValues.next;

export const hasOverviewAttributesSelector = (state: RootState) => {
  const displayMode = state.playlistAttributes.displayMode;
  const map = state.playlistAttributes.attributesMapById[displayMode];

  return Object.values(map).some((value) => value.data.values.length > 0);
};

export const isGuestsAttributeLoadedSelector = (state: RootState) => state.playlistAttributes.isGuestsAttributesLoaded;

export const guestsAttributeSelector = (state: RootState) => state.playlistAttributes.guestsAttribute;

export const sortDirectionSelector = (state: RootState) => state.playlistAttributes.attributesMapById.sortDirection;

export const sortBySelector = (state: RootState) => state.playlistAttributes.attributesMapById.sortBy;

export const playlistSharedWithFilterSelector = (state: RootState) => state.playlistAttributes.sharedWithFilter;
