import { RootState } from '../index';

export const contentIdSelector = (state: RootState) => state.analytics.contentId;

export const contentTypeSelector = (state: RootState) => state.analytics.contentType;

export const currentListSelector = (state: RootState) => state.analytics.currentList;

export const isRefreshingSelector = (state: RootState) => state.analytics.isRefreshing;

export const isFilterBottomSheetOpenSelector = (state: RootState) => state.analytics.isFilterBottomSheetOpen;

export const hasDistributionsSelector = (state: RootState) => state.analytics.hasDistributions;

export const isFilterTooltipShownSelector = (state: RootState) => state.analytics.isFilterTooltipShown;
