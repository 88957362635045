import React from 'react';
import StockVideos from './StockVideos';
import StockImages from './StockImages';
import StockGifs from './StockGifs';
import StockStickers from './StockStickers';
import SidebarTitle from './SidebarTitle';
import { useSelector } from 'react-redux';
import { stockFileTypeSelector } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import { SidebarProps } from './LeftSidebar';

const tabs = {
  video: StockVideos,
  image: StockImages,
  gif: StockGifs,
  sticker: StockStickers,
};

const StockSidebar = ({ previewPanelRef, setIsPreviewPanelVisible }: SidebarProps) => {
  const fileType = useSelector(stockFileTypeSelector);

  const TabComponent = tabs[fileType];

  return (
    <>
      <SidebarTitle title='Web' />
      <TabComponent setIsPreviewPanelVisible={setIsPreviewPanelVisible} previewPanelRef={previewPanelRef} />
    </>
  );
};
export default StockSidebar;
