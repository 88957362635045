import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ESortByType,
  IBiteAttributeMap,
  IBiteAttributeMapItem,
  IBiteAttributeValue,
  IInitialState,
} from './biteAttributes.types';
import { cloneDeep } from 'lodash';
import { prepareAnalytics, resetAnalytics } from '../../analytics/analytics.slice';
import { EAnalyticsScreenType, IGetAttributesPayload } from '../../../screens/analytics/Analytics.types';
import { TExcludeFields } from '../biteStats/biteStats.types';
import {
  ESharedWithFilter,
  ESortingDirection,
  IOrganizationAttribute,
  IOrganizationAttributeValue,
  TDisplayMode,
} from '../../../types/anayltics';
import { IGetAttributesAction } from '../../org/org.types';

export const initialState: IInitialState = {
  displayMode: 'percentage',
  overviewAttributesMapById: {
    sortBy: ESortByType.VIEWS,
    sortDirection: ESortingDirection.DESC,
    absolute: null,
    percentage: null,
    isGuestsAttributesLoaded: false,
  },
  viewsAttributesMapById: {
    sortBy: ESortByType.VIEWS,
    sortDirection: ESortingDirection.DESC,
    absolute: null,
    percentage: null,
    isGuestsAttributesLoaded: false,
  },
  answersAttributesMapById: {
    sortBy: ESortByType.ANSWERS,
    sortDirection: ESortingDirection.DESC,
    absolute: null,
    percentage: null,
    isGuestsAttributesLoaded: false,
  },
  commentsAttributesMapById: {
    sortBy: ESortByType.COMMENTS,
    sortDirection: ESortingDirection.DESC,
    absolute: null,
    percentage: null,
    isGuestsAttributesLoaded: false,
  },
  selectedValueIds: [],
  filterAttributesMapById: null,
  filterSearchValue: '',
  sharedWithFilter: [],

  searchValues: {
    isLoading: false,
    error: null,
    data: null,
    next: null,
  },

  initialAttributesMapById: null,
  organizationAttributes: {
    isLoading: false,
    error: null,
    data: null,
  },

  guestsAttribute: {
    isLoading: false,
    error: null,
    data: null,
  },
  guestsAttributeAnswersWithOption: {
    isLoading: false,
    error: null,
    data: null,
  },
};

const ATTRIBUTES = 'ATTRIBUTES';

export const getGuestsAttribute = createAction<{ currentTab: EAnalyticsScreenType }>(
  `${ATTRIBUTES}/getGuestsAttribute`,
);
export const getOverviewAttributes = createAction<IGetAttributesPayload>(`${ATTRIBUTES}/getOverviewAttributes`);
export const getViewsAttributes = createAction<IGetAttributesPayload>(`${ATTRIBUTES}/getViewsAttributes`);
export const getAnswersAttributes = createAction<IGetAttributesPayload>(`${ATTRIBUTES}/getAnswersAttributes`);
export const getCommentsAttributes = createAction<IGetAttributesPayload>(`${ATTRIBUTES}/getCommentsAttributes`);

const biteAttributesSlice = createSlice({
  name: ATTRIBUTES,
  initialState,
  reducers: {
    setDisplayMode: (state, { payload }: PayloadAction<TDisplayMode>) => {
      state.displayMode = payload;
    },
    setFilterSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.filterSearchValue = payload;
    },
    getOrganizationAttributes: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<IGetAttributesAction>,
    ) => {
      state.organizationAttributes.isLoading = true;
      state.organizationAttributes.error = null;
    },
    getFilterList: (
      state,
      { payload }: PayloadAction<{ attributeId: number; reset?: boolean; callback?: () => void }>,
    ) => {
      state.filterAttributesMapById[payload.attributeId].isLoading = true;
      state.filterAttributesMapById[payload.attributeId].error = null;
    },
    setOverviewAttributeLoading: (state, { payload }: PayloadAction<{ attributeId: number }>) => {
      const { attributeId } = payload;
      const displayMode = state.displayMode;

      state.overviewAttributesMapById[displayMode] = {
        ...state.overviewAttributesMapById[displayMode],
        [attributeId]: {
          ...state.overviewAttributesMapById[displayMode][attributeId],
          isLoading: true,
          error: null,
        },
      };
    },
    setViewsAttributeLoading: (state, { payload }: PayloadAction<{ attributeId: number }>) => {
      const { attributeId } = payload;
      const displayMode = state.displayMode;
      state.viewsAttributesMapById[displayMode] = {
        ...state.viewsAttributesMapById[displayMode],
        [attributeId]: {
          ...state.viewsAttributesMapById[displayMode][attributeId],
          isLoading: true,
          error: null,
        },
      };
    },
    setAnswersAttributeLoading: (state, { payload }: PayloadAction<{ attributeId: number }>) => {
      const { attributeId } = payload;
      const displayMode = state.displayMode;
      state.answersAttributesMapById[displayMode] = {
        ...state.answersAttributesMapById[displayMode],
        [attributeId]: {
          ...state.answersAttributesMapById[displayMode][attributeId],
          isLoading: true,
          error: null,
        },
      };
    },
    setCommentsAttributeLoading: (state, { payload }: PayloadAction<{ attributeId: number }>) => {
      const { attributeId } = payload;
      const displayMode = state.displayMode;
      state.commentsAttributesMapById[displayMode] = {
        ...state.commentsAttributesMapById[displayMode],
        [attributeId]: {
          ...state.commentsAttributesMapById[displayMode][attributeId],
          isLoading: true,
          error: null,
        },
      };
    },

    setFilterList: (
      state,
      {
        payload,
      }: PayloadAction<{ attributeId: number; values: IOrganizationAttributeValue[]; next?: string; reset?: boolean }>,
    ) => {
      const { attributeId, reset, values } = payload;
      if (state.filterAttributesMapById[attributeId]?.next && !reset) {
        state.filterAttributesMapById[attributeId].data.values =
          state.filterAttributesMapById[attributeId].data.values.concat(values);
      } else {
        state.filterAttributesMapById[attributeId].data.values = values;
      }

      state.filterAttributesMapById[payload.attributeId].isLoading = false;
      state.filterAttributesMapById[payload.attributeId].error = null;
      state.filterAttributesMapById[payload.attributeId].next = payload.next;
    },
    setFilterListError: (state, { payload }: PayloadAction<{ attributeId: number; error: string }>) => {
      const { attributeId, error } = payload;
      state.filterAttributesMapById[attributeId].isLoading = false;
      state.filterAttributesMapById[attributeId].error = error;
    },
    setOrganizationAttributes: (state, { payload }: PayloadAction<{ attributes: IOrganizationAttribute[] }>) => {
      const { attributes } = payload;
      state.organizationAttributes.isLoading = false;
      state.organizationAttributes.error = null;
      state.organizationAttributes.data = attributes.map((attr) => ({
        ...attr,
        values: null,
      }));

      const map = {};
      const filterMap = {};
      const initialMap = {};

      attributes.forEach((attribute: IOrganizationAttribute) => {
        map[attribute.id] = {
          isLoading: false,
          error: null,
          data: {
            ...attribute,
            values: null,
          },
          next: null,
        };

        filterMap[attribute.id] = {
          isLoading: false,
          error: null,
          data: attribute,
          selectedValues: state.filterAttributesMapById?.[attribute.id]?.selectedValues || [],
          next: null,
        };

        initialMap[attribute.id] = {
          isLoading: false,
          error: null,
          data: {
            ...attribute,
            values: attribute.values.slice(0, 1),
          },
          next: null,
        };
      });

      state.initialAttributesMapById = initialMap;
      state.filterAttributesMapById = filterMap;

      setAllAttributesMaps(state, map);
    },
    setOverviewAttribute: (
      state,
      {
        payload,
      }: PayloadAction<{ attributeId: number; values: IBiteAttributeValue[]; next?: string; reset?: boolean }>,
    ) => {
      const { attributeId, reset, values, next } = payload;
      const displayMode = state.displayMode;

      if (state.overviewAttributesMapById[displayMode][attributeId]?.next && !reset) {
        state.overviewAttributesMapById[displayMode][attributeId].data.values =
          state.overviewAttributesMapById[displayMode][attributeId].data.values.concat(values);
      } else {
        state.overviewAttributesMapById[displayMode][attributeId].data.values = values || [];
        updateOrganizationAttributes(state, attributeId, values);
      }

      state.overviewAttributesMapById[displayMode][attributeId].isLoading = false;
      state.overviewAttributesMapById[displayMode][attributeId].error = null;
      state.overviewAttributesMapById[displayMode][attributeId].next = next;
    },
    setViewsAttribute: (
      state,
      {
        payload,
      }: PayloadAction<{ attributeId: number; values: IBiteAttributeValue[]; next?: string; reset?: boolean }>,
    ) => {
      const { attributeId, reset, values, next } = payload;
      const displayMode = state.displayMode;

      if (state.viewsAttributesMapById[displayMode][attributeId]?.next && !reset) {
        state.viewsAttributesMapById[displayMode][attributeId].data.values =
          state.viewsAttributesMapById[displayMode][attributeId].data.values.concat(values);
      } else {
        state.viewsAttributesMapById[displayMode][attributeId].data.values = values || [];
        updateOrganizationAttributes(state, attributeId, values);
      }

      state.viewsAttributesMapById[displayMode][attributeId].isLoading = false;
      state.viewsAttributesMapById[displayMode][attributeId].error = null;
      state.viewsAttributesMapById[displayMode][attributeId].next = next;
    },
    setAnswersAttribute: (
      state,
      {
        payload,
      }: PayloadAction<{
        attributeId: number;
        withSelectedAnswer?: boolean;
        values: IBiteAttributeValue[];
        next?: string;
        reset?: boolean;
      }>,
    ) => {
      const { attributeId, reset, values, withSelectedAnswer, next } = payload;
      const displayMode = state.displayMode;

      if (state.answersAttributesMapById[displayMode][attributeId]?.next && !reset) {
        state.answersAttributesMapById[displayMode][attributeId].data.values =
          state.answersAttributesMapById[displayMode][attributeId].data.values.concat(values);
      } else {
        state.answersAttributesMapById[displayMode][attributeId].data.values = values || [];
        if (!withSelectedAnswer) {
          updateOrganizationAttributes(state, attributeId, values);
        }
      }

      state.answersAttributesMapById[displayMode][attributeId].isLoading = false;
      state.answersAttributesMapById[displayMode][attributeId].error = null;
      state.answersAttributesMapById[displayMode][attributeId].next = next;
    },
    setCommentsAttribute: (
      state,
      {
        payload,
      }: PayloadAction<{ attributeId: number; values: IBiteAttributeValue[]; next?: string; reset?: boolean }>,
    ) => {
      const { attributeId, reset, values, next } = payload;
      const displayMode = state.displayMode;

      if (state.commentsAttributesMapById[displayMode][attributeId]?.next && !reset) {
        state.commentsAttributesMapById[displayMode][attributeId].data.values =
          state.commentsAttributesMapById[displayMode][attributeId].data.values.concat(values);
      } else {
        state.commentsAttributesMapById[displayMode][attributeId].data.values = values || [];
        updateOrganizationAttributes(state, attributeId, values);
      }

      state.commentsAttributesMapById[displayMode][attributeId].isLoading = false;
      state.commentsAttributesMapById[displayMode][attributeId].error = null;
      state.commentsAttributesMapById[displayMode][attributeId].next = next;
    },
    setOverviewAttributeError: (
      state,
      { payload }: PayloadAction<{ attributeId: number; error: string; reset?: boolean }>,
    ) => {
      const { attributeId, error, reset } = payload;
      const displayMode = state.displayMode;
      state.overviewAttributesMapById[displayMode][attributeId].isLoading = false;
      state.overviewAttributesMapById[displayMode][attributeId].error = error;
      if (reset) {
        state.organizationAttributes.data.find((attr) => attr.id === attributeId).values = [];
        state.overviewAttributesMapById[displayMode][attributeId].data.values = [];
      }
    },
    setViewsAttributeError: (
      state,
      { payload }: PayloadAction<{ attributeId: number; error: string; reset?: boolean }>,
    ) => {
      const { attributeId, error, reset } = payload;
      const displayMode = state.displayMode;
      state.viewsAttributesMapById[displayMode][attributeId].isLoading = false;
      state.viewsAttributesMapById[displayMode][attributeId].error = error;
      if (reset) {
        state.organizationAttributes.data.find((attr) => attr.id === attributeId).values = [];
        state.viewsAttributesMapById[displayMode][attributeId].data.values = [];
      }
    },
    setAnswersAttributeError: (
      state,
      { payload }: PayloadAction<{ attributeId: number; error: string; reset?: boolean }>,
    ) => {
      const { attributeId, error, reset } = payload;
      const displayMode = state.displayMode;
      state.answersAttributesMapById[displayMode][attributeId].isLoading = false;
      state.answersAttributesMapById[displayMode][attributeId].error = error;
      if (reset) {
        state.organizationAttributes.data.find((attr) => attr.id === attributeId).values = [];
        state.answersAttributesMapById[displayMode][attributeId].data.values = [];
      }
    },
    setCommentsAttributeError: (
      state,
      { payload }: PayloadAction<{ attributeId: number; error: string; reset?: boolean }>,
    ) => {
      const { attributeId, error, reset } = payload;
      const displayMode = state.displayMode;
      state.commentsAttributesMapById[displayMode][attributeId].isLoading = false;
      state.commentsAttributesMapById[displayMode][attributeId].error = error;
      if (reset) {
        state.organizationAttributes.data.find((attr) => attr.id === attributeId).values = [];
        state.commentsAttributesMapById[displayMode][attributeId].data.values = [];
      }
    },
    setSelectedFilterAttributeValues: (
      state,
      { payload }: PayloadAction<{ attributeId: number; values: IOrganizationAttributeValue[] }>,
    ) => {
      const { attributeId, values } = payload;

      state.filterAttributesMapById[attributeId].selectedValues = values;

      let selectedValueIds = [];
      Object.keys(state.filterAttributesMapById).forEach((id) => {
        const attribute = { ...state.filterAttributesMapById[id] };

        if (attribute.id === attributeId) {
          selectedValueIds.push(...values.map((value: IOrganizationAttributeValue) => value.id));
          return;
        }

        selectedValueIds.push(...attribute.selectedValues.map((value: IOrganizationAttributeValue) => value.id));
      });
      state.selectedValueIds = selectedValueIds;

      Object.keys(state.filterAttributesMapById).forEach((id) => {
        if (Number(id) === attributeId) {
          return;
        }

        state.filterAttributesMapById[id].data.values = [];
      });
    },

    getSearchList: (
      state,
      {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        payload,
      }: PayloadAction<{ attributeId: number; reset?: boolean; withDebounce?: boolean; callback?: () => void }>,
    ) => {
      state.searchValues.isLoading = true;
      state.searchValues.error = null;
    },
    setSearchList: (state, { payload }: PayloadAction<{ data: any; next?: string; reset?: boolean }>) => {
      const { reset, data, next } = payload;
      const currentAttribute = data[0];

      if (state.searchValues.next && !reset) {
        state.searchValues.data = state.searchValues.data.concat(currentAttribute.values);
      } else {
        state.searchValues.data = currentAttribute.values;
      }

      state.searchValues.isLoading = false;
      state.searchValues.error = null;
      state.searchValues.next = next;
    },
    setSearchListError: (state, { payload }: PayloadAction<{ error: string; reset?: boolean }>) => {
      state.searchValues.isLoading = false;
      state.searchValues.error = payload.error;
      if (payload.reset) {
        state.searchValues.data = [];
        state.searchValues.next = null;
      }
    },
    setOrganizationAttributesError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.organizationAttributes.isLoading = false;
      state.organizationAttributes.error = payload.error;
    },
    setGuestsAttributeLoading: (
      state,
      {
        payload: { currentTab, isAnswersSelected },
      }: PayloadAction<{ currentTab: EAnalyticsScreenType; isAnswersSelected: boolean }>,
    ) => {
      if (currentTab === EAnalyticsScreenType.ANSWERS && isAnswersSelected) {
        state.guestsAttributeAnswersWithOption.isLoading = true;
        state.guestsAttributeAnswersWithOption.error = null;
        return;
      }

      state.guestsAttribute.isLoading = true;
      state.guestsAttribute.error = null;
    },
    setGuestsAttributeError: (
      state,
      {
        payload: { error, currentTab, isAnswersSelected },
      }: PayloadAction<{ error: string; currentTab: EAnalyticsScreenType; isAnswersSelected: boolean }>,
    ) => {
      if (currentTab === EAnalyticsScreenType.ANSWERS && isAnswersSelected) {
        state.guestsAttributeAnswersWithOption.isLoading = false;
        state.guestsAttributeAnswersWithOption.error = error;
        state.guestsAttributeAnswersWithOption.data = null;
        return;
      }

      state.guestsAttribute.isLoading = false;
      state.guestsAttribute.error = error;
      state.guestsAttribute.data = null;
    },
    setGuestsAttribute: (
      state,
      {
        payload: { attribute, currentTab, isAnswersSelected },
      }: PayloadAction<{
        attribute: IBiteAttributeValue;
        currentTab: EAnalyticsScreenType;
        isAnswersSelected: boolean;
      }>,
    ) => {
      state[`${currentTab}AttributesMapById`].isGuestsAttributesLoaded = true;

      if (currentTab === EAnalyticsScreenType.ANSWERS && isAnswersSelected) {
        state.guestsAttributeAnswersWithOption.isLoading = false;
        state.guestsAttributeAnswersWithOption.error = null;
        state.guestsAttributeAnswersWithOption.data = attribute;
        return;
      }

      state.guestsAttribute.isLoading = false;
      state.guestsAttribute.error = null;
      state.guestsAttribute.data = attribute;
    },
    setSortBy: (
      state,
      {
        payload: { currentTab, sortBy, sortDirection, withoutReset },
      }: PayloadAction<{
        currentTab: EAnalyticsScreenType;
        sortBy?: ESortByType;
        sortDirection: ESortingDirection;
        withoutReset?: boolean;
      }>,
    ) => {
      const map = state[`${currentTab}AttributesMapById`];

      map.sortBy = sortBy;
      map.sortDirection = sortDirection;

      if (!withoutReset) {
        state[`${currentTab}AttributesMapById`] = {
          ...map,
          absolute: getCleanedAttributesMap(state[`${currentTab}AttributesMapById`].absolute),
          percentage: getCleanedAttributesMap(state[`${currentTab}AttributesMapById`].percentage),
        };
      }
    },
    setSharedWithFilter: (state, { payload }: PayloadAction<ESharedWithFilter[]>) => {
      state.sharedWithFilter = payload;
    },
    resetSearchValues: (state) => {
      state.filterSearchValue = initialState.filterSearchValue;
      state.searchValues = initialState.searchValues;
    },
    resetAnswersAttributes: (state) => {
      const cleanedMap = getCleanedAttributesMap(state.initialAttributesMapById);

      state.answersAttributesMapById.absolute = cloneDeep(cleanedMap);
      state.answersAttributesMapById.percentage = cloneDeep(cleanedMap);
      state.answersAttributesMapById.isGuestsAttributesLoaded = false;
    },
  },
  extraReducers: {
    [resetAnalytics.type]: (state, { payload }) => {
      const { withFiltersReset, excludeFields } = payload;
      const exclude = excludeFields?.biteAttributes;
      const map = state.initialAttributesMapById;

      if (!map) {
        return state;
      }

      if (withFiltersReset) {
        const filtersMap = map
          ? Object.keys(map).reduce((acc, attributeId) => {
              const attribute = map[attributeId];
              acc[attributeId] = {
                ...attribute,
                selectedValues: withFiltersReset
                  ? []
                  : state.filterAttributesMapById[attribute.id].selectedValues || [],
              };

              return acc;
            }, {})
          : {};
        state.selectedValueIds = [];
        state.filterAttributesMapById = filtersMap;

        if (!exclude?.includes('sharedWithFilter')) {
          state.sharedWithFilter = initialState.sharedWithFilter;
        }
      }

      if (!exclude?.includes('guestsAttribute')) {
        state.overviewAttributesMapById.isGuestsAttributesLoaded = false;
        state.viewsAttributesMapById.isGuestsAttributesLoaded = false;
        state.answersAttributesMapById.isGuestsAttributesLoaded = false;
        state.commentsAttributesMapById.isGuestsAttributesLoaded = false;
      }
      setAllAttributesMaps(state, map, exclude);
    },
    [prepareAnalytics.type]: () => {
      return initialState;
    },
  },
});

const updateOrganizationAttributes = (state: IInitialState, attributeId: number, values: IBiteAttributeValue[]) => {
  // Checks against other (1 level) filters must be added here, once implemented
  // ie. active users only, etc.
  if (state.selectedValueIds.length === 0 && state.sharedWithFilter.length === 0) {
    state.initialAttributesMapById[attributeId].data.values = values.slice(0, 1);
  }

  state.organizationAttributes = {
    ...state.organizationAttributes,
    data: state.organizationAttributes.data.map((attr: IOrganizationAttribute) => {
      if (attr.id === attributeId) {
        return {
          ...attr,
          values: values.slice(0, 1),
        };
      }

      return attr;
    }),
  };
};

const setAllAttributesMaps = (
  state: IInitialState,
  map: IBiteAttributeMap,
  excludeFields?: TExcludeFields['biteAttributes'],
) => {
  const cleanedMap = getCleanedAttributesMap(map);

  const updatedState = {
    overviewAttributesMapById: {
      ...state.overviewAttributesMapById,
      absolute: cloneDeep(cleanedMap),
      percentage: cloneDeep(cleanedMap),
    },
    viewsAttributesMapById: {
      ...state.viewsAttributesMapById,
      absolute: cloneDeep(cleanedMap),
      percentage: cloneDeep(cleanedMap),
    },
    answersAttributesMapById: {
      ...state.answersAttributesMapById,
      absolute: cloneDeep(cleanedMap),
      percentage: cloneDeep(cleanedMap),
    },
    commentsAttributesMapById: {
      ...state.commentsAttributesMapById,
      absolute: cloneDeep(cleanedMap),
      percentage: cloneDeep(cleanedMap),
    },
  };

  if (excludeFields) {
    excludeFields.forEach((field) => {
      if (typeof field === 'string') {
        return;
      }

      field.attributeIds.forEach((attributeId) => {
        updatedState[field.field][state.displayMode][attributeId] = state[field.field][state.displayMode][attributeId];
      });
    });
  }

  state.overviewAttributesMapById = updatedState.overviewAttributesMapById;
  state.viewsAttributesMapById = updatedState.viewsAttributesMapById;
  state.answersAttributesMapById = updatedState.answersAttributesMapById;
  state.commentsAttributesMapById = updatedState.commentsAttributesMapById;
};

const getCleanedAttributesMap = (map: IBiteAttributeMap) => {
  return Object.values(map).reduce((acc, attribute: IBiteAttributeMapItem) => {
    acc[attribute.data.id] = {
      ...attribute,
      data: {
        ...attribute.data,
        values: null,
      },
    };

    return acc;
  }, {});
};

export const {
  setDisplayMode,
  setOverviewAttributeLoading,
  setViewsAttributeLoading,
  setAnswersAttributeLoading,
  setCommentsAttributeLoading,
  getOrganizationAttributes,
  getFilterList,
  setOverviewAttribute,
  setViewsAttribute,
  setAnswersAttribute,
  setCommentsAttribute,
  setOrganizationAttributes,
  setOrganizationAttributesError,
  setSelectedFilterAttributeValues,
  setOverviewAttributeError,
  setViewsAttributeError,
  setAnswersAttributeError,
  setCommentsAttributeError,
  setFilterList,
  setFilterListError,
  setFilterSearchValue,
  resetAnswersAttributes,
  getSearchList,
  setSearchList,
  setSearchListError,
  setGuestsAttributeLoading,
  setGuestsAttribute,
  setGuestsAttributeError,
  setSortBy,
  setSharedWithFilter,
  resetSearchValues,
} = biteAttributesSlice.actions;

export default biteAttributesSlice.reducer;
