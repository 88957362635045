import React from 'react';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../../utils/dimensions';
import { useCallback, useMemo, useRef, useState } from 'react';
import { PanGestureHandler, State } from 'react-native-gesture-handler';
import styled, { useTheme } from 'styled-components/native';

import BackIcon from '../../../../assets/icons/creationFlow/back.svg';
import Button from '../../../../components/shared/buttons/Button';
import useAudioPreview from '../../../../hooks/useAudioPreview';
import VoiceCard from './VoiceCard';
import ShadowedContainer from '../../../../components/ShadowedContainer';
import LinearGradient from '../../../../components/shared/LinearGradient';

const FORM_WIDTH = isWeb ? calcWidth(820) : deviceWidth - calcWidth(40);
const PAGE_WIDTH = isWeb ? calcWidth(720) : deviceWidth - calcWidth(140);
const PAGE_SIZE = isWeb ? 5 : 3;

const VoicesSlider = ({ activeVoiceId, voicesList, onSelect }) => {
  const theme = useTheme();
  const scrollRef = useRef(null);
  const maxPages = useMemo(() => Math.ceil((voicesList.length * 2) / PAGE_SIZE), [voicesList]);

  const [currentPage, setCurrentPage] = useState(0);

  const { activeAudioId, handlePlayPreview } = useAudioPreview();

  const goToNextPage = useCallback(() => {
    const nextPage = currentPage < maxPages - 1 ? currentPage + 1 : currentPage;
    const nextPageOffset = (PAGE_WIDTH / 2) * nextPage;

    scrollRef.current.scrollTo({ x: nextPageOffset, y: 0, animated: true });
    setCurrentPage(nextPage);
  }, [currentPage, maxPages]);

  const goToPrevPage = useCallback(() => {
    const prevPage = currentPage > 0 ? currentPage - 1 : currentPage;
    const prevPageOffset = (PAGE_WIDTH / 2) * prevPage;

    scrollRef.current.scrollTo({ x: prevPageOffset, y: 0, animated: true });
    setCurrentPage(prevPage);
  }, [currentPage]);

  const handleSwipe = useCallback(
    ({ nativeEvent }) => {
      if (nativeEvent.oldState !== State.ACTIVE) {
        return;
      }

      if (nativeEvent.translationX < 0) {
        goToNextPage();
        return;
      }

      goToPrevPage();
    },
    [goToNextPage, goToPrevPage],
  );

  return (
    <S.VoicesSlider>
      {isWeb && (
        <S.ControlButtonContainer>
          <ShadowedContainer>
            <S.BackButton onPress={goToPrevPage} disabled={currentPage === 0}>
              <BackIcon fill={theme.colors.primaryBlue} />
            </S.BackButton>
          </ShadowedContainer>
        </S.ControlButtonContainer>
      )}
      <S.StartFade angle={90} colors={['white', 'transparent']} />
      <S.SwipeContainer ref={scrollRef} scrollEnabled={false} showsHorizontalScrollIndicator={false} horizontal>
        <PanGestureHandler onHandlerStateChange={handleSwipe}>
          <S.ButtonsSwipeContent maxPages={maxPages}>
            <S.SideSpace />
            {voicesList.map((voice) => (
              <VoiceCard
                key={voice.id}
                voice={voice}
                activeAudioId={activeAudioId}
                activeVoiceId={activeVoiceId}
                onPlayVoice={handlePlayPreview}
                onSelect={onSelect}
                width={PAGE_WIDTH / PAGE_SIZE}
                height={calcHeight(224)}
              />
            ))}
            <S.SideSpace />
          </S.ButtonsSwipeContent>
        </PanGestureHandler>
      </S.SwipeContainer>
      <S.EndFade angle={90} colors={['transparent', 'white']} />
      {isWeb && (
        <S.ControlButtonContainer isNext>
          <ShadowedContainer>
            <S.BackButton onPress={goToNextPage} disabled={currentPage === maxPages - 1} isNext>
              <BackIcon fill={theme.colors.primaryBlue} />
            </S.BackButton>
          </ShadowedContainer>
        </S.ControlButtonContainer>
      )}
    </S.VoicesSlider>
  );
};

const S = {
  VoicesSlider: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: ${FORM_WIDTH}px;
    margin-left: ${calcWidth(-50)}px;
  `,
  ControlButtonContainer: styled.View`
    z-index: 6;
    position: absolute;
    overflow: visible;
    top: ${calcHeight(-20)}px;
    ${({ isNext }) => (isNext ? `right: ${calcWidth(45)}px;` : `left: ${calcWidth(45)}px;`)};
  `,
  BackButton: styled.TouchableOpacity<{
    disabled?: boolean;
    isNext?: boolean;
  }>`
    align-items: center;
    justify-content: center;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${calcWidth(40 / 2)}px;
    height: ${calcWidth(40)}px;
    width: ${calcWidth(40)}px;
    ${({ isNext }) => (isNext ? 'transform: rotate(180deg);' : null)};
  `,
  SwipeContainer: styled.ScrollView`
    flex-direction: row;
    width: ${FORM_WIDTH - 60}px;
    height: ${calcHeight(224)}px;
    overflow: hidden;
  `,

  VoiceCard: styled.View`
    width: ${PAGE_WIDTH / PAGE_SIZE}px;
    height: ${calcHeight(224)}px;
    margin: 0 ${calcWidth(5)}px;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme, active }) => (active ? theme.colors.primary : theme.colors.white)};
    border-radius: 18px;
  `,
  VoiceCardImage: styled.Image`
    width: ${calcWidth(75)}px;
    height: ${calcWidth(75)}px;
    border-radius: ${calcWidth(75 / 2)}px;
    overflow: hidden;
  `,
  VoiceCardName: styled.Text`
    margin-top: ${calcHeight(4)}px;
    padding: 0 ${calcWidth(5)}px;
    font-size: ${({ theme }) => theme.fontSizes.s11}px;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
  ButtonsSwipeContent: styled.View<{ maxPages: number }>`
    min-width: ${({ maxPages }) => (maxPages > 1 ? PAGE_WIDTH * maxPages : PAGE_WIDTH)}px;
    flex-direction: row;
  `,
  VoiceCardButton: styled(Button)``,
  SideSpace: styled.View`
    width: ${calcWidth(50)}px;
    height: 100%;
  `,
  StartFade: styled(LinearGradient)`
    position: absolute;
    left: 0;
    z-index: 5;
    width: ${calcWidth(100)}px;
    height: 100%;
    pointer-events: none;
  `,
  EndFade: styled(LinearGradient)`
    position: absolute;
    right: 0;
    z-index: 5;
    width: ${calcWidth(100)}px;
    height: 100%;
    pointer-events: none;
  `,
};

export default VoicesSlider;
