import React, { memo, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import ProcessingStatus from '../ProcessingStatus.web';
import LeftPrimarySidebar from './LeftPrimarySidebar';
import styled from 'styled-components';
import defaultTheme from '../../../themes';
import {
  IS_EXPANDED_TRANSITION_TS,
  PRIMARY_SIDEBAR_WIDTH,
  SECONDARY_SIDEBAR_WIDTH,
  SPACING,
  TIMELINE_CONTROLS_HEIGHT,
} from '../videoEditor.constants';
import CloudSidebar from './CloudSidebar';
import StockSidebar from './StockSidebar';
import TextSidebar from './TextSidebar.web';
import RecordSidebar from './RecordSidebar.web';
import FavoritesSidebar from './FavoritesSidebar';
import BrandSidebar from './BrandSidebar';
import ScopedSidebar from './ScopedSidebar';
import { useDispatch, useSelector } from 'react-redux';
import {
  overlaySelector,
  primaryTabSelector,
} from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import AddToBrandOverlay from './AddToBrandOverlay/AddToBrandOverlay';
import RemoveFromBrandOverlay from './AddToBrandOverlay/RemoveFromBrandOverlay';
import { IInitialState } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.types';
import { setPrimaryTab } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import TextToSpeechSidebar from './TextToSpeechSidebar.web';
import { replacingSelectedTimelineItemSelector } from '../../../store/videoEditor/videoEditor.selectors';
import RecordVideoSidebar from './RecordVideoSidebar';
import ScreenRecordingSidebar from './ScreenRecordingSidebar';
import GenerateImageSidebar from './GenerateImageSidebar';

const overlayByType = {
  ADD_TO_BRAND: AddToBrandOverlay,
  REMOVE_FROM_BRAND: RemoveFromBrandOverlay,
};

export type SidebarProps = {
  setIsPreviewPanelVisible: (isVisible: boolean) => void;
  setIsAdditionalPanelVisible: (isVisible: boolean) => void;
  isAdditionalPanelVisible: boolean;
  isPreviewPanelVisible: boolean;
  previewPanelRef: RefObject<HTMLDivElement>;
  additionalPanelRef: RefObject<HTMLDivElement>;
};

const secondarySidebarComponentByType = {
  CLOUD: CloudSidebar,
  SCOPED: ScopedSidebar,
  FAVORITES: FavoritesSidebar,
  BRAND: BrandSidebar,
  STOCK: StockSidebar,
  TEXT_TO_SPEECH: TextToSpeechSidebar,
  RECORD_VIDEO: RecordVideoSidebar,
  SCREEN_RECORDING: ScreenRecordingSidebar,
  GENERATE_IMAGE: GenerateImageSidebar,
  RECORD: RecordSidebar,
  TEXT: TextSidebar,
};

const LeftSidebar = ({
  timelineHeight,
  isTimelineExpanded,
}: {
  timelineHeight: number;
  isTimelineExpanded: boolean;
}) => {
  const dispatch = useDispatch();

  const overlay = useSelector(overlaySelector);
  const replacingSelectedTimelineItem = useSelector(replacingSelectedTimelineItemSelector);

  const additionalPanelRef = useRef(null);
  const previewPanelRef = useRef(null);

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const primaryTab = useSelector(primaryTabSelector);

  const [isPreviewPanelVisible, setIsPreviewPanelVisible] = useState(false);
  const [isAdditionalPanelVisible, setIsAdditionalPanelVisible] = useState(false);

  const setSecondarySidebar = useCallback(
    (tab: IInitialState['primaryTab']) => {
      dispatch(setPrimaryTab(tab));
    },
    [dispatch],
  );

  const handleCloseAdditionalSidebar = useCallback(() => {
    setIsAdditionalPanelVisible(false);
  }, []);

  const SecondarySidebarComponent = secondarySidebarComponentByType[primaryTab];
  const OverlayComponent = overlayByType[overlay?.type];

  useEffect(() => {
    handleCloseAdditionalSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryTab]);

  return (
    <>
      <S.Sidebar isSidebarExpanded={isSidebarExpanded} timelineHeight={timelineHeight}>
        <LeftPrimarySidebar
          secondarySidebar={primaryTab}
          isTimelineExpanded={isTimelineExpanded}
          isSidebarExpanded={isSidebarExpanded}
          setSecondarySidebar={setSecondarySidebar}
          setIsSidebarExpanded={setIsSidebarExpanded}
        />
        <S.LeftPreviewSidebar isVisible={isPreviewPanelVisible} ref={previewPanelRef}>
          {isAdditionalPanelVisible && <S.Overlay onClick={handleCloseAdditionalSidebar} />}
        </S.LeftPreviewSidebar>
        <S.LeftAdditionalSidebar isVisible={isAdditionalPanelVisible} ref={additionalPanelRef} />
        <S.SecondarySidebar>
          {SecondarySidebarComponent && (
            <SecondarySidebarComponent
              setIsPreviewPanelVisible={setIsPreviewPanelVisible}
              setIsAdditionalPanelVisible={setIsAdditionalPanelVisible}
              isAdditionalPanelVisible={isAdditionalPanelVisible}
              isPreviewPanelVisible={isPreviewPanelVisible}
              previewPanelRef={previewPanelRef}
              additionalPanelRef={additionalPanelRef}
            />
          )}
          <ProcessingStatus />
        </S.SecondarySidebar>
        {replacingSelectedTimelineItem && (
          <S.ReplacingHighlight
            isPreviewPanelVisible={isPreviewPanelVisible}
            isAdditionalPanelVisible={isAdditionalPanelVisible}
          />
        )}
        {overlay && OverlayComponent && <OverlayComponent />}
      </S.Sidebar>
    </>
  );
};
export default memo(LeftSidebar);

const LEFT_PREVIEW_SIDEBAR_WIDTH = 650;
const LEFT_ADDITIONAL_SIDEBAR_WIDTH = 300;
export const S = {
  Sidebar: styled.div<{
    isSidebarExpanded: boolean;
    timelineHeight: number;
  }>`
    z-index: 10;
    position: absolute;
    top: ${SPACING}px;
    left: ${SPACING}px;
    bottom: ${({ isSidebarExpanded, timelineHeight }) =>
      !isSidebarExpanded ? TIMELINE_CONTROLS_HEIGHT + timelineHeight + SPACING : SPACING}px;
    display: flex;
    flex-shrink: 0;
    transition: bottom ${IS_EXPANDED_TRANSITION_TS}ms ease-in-out;
  `,
  SecondarySidebar: styled.div`
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: -12px;
    padding-left: 12px;
    width: ${SECONDARY_SIDEBAR_WIDTH + 12}px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    background-color: ${defaultTheme.colors.white};
  `,
  LeftPreviewSidebar: styled.div<{ isVisible: boolean }>`
    position: absolute;
    top: 0px;
    left: calc(100% - 12px);
    bottom: 0px;
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    width: ${LEFT_PREVIEW_SIDEBAR_WIDTH}px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    background-color: ${defaultTheme.colors.white};
    transition: all 300ms ease-in-out;
    overflow: hidden;
    ${({ isVisible }) => `transform: translateX(${isVisible ? 0 : -100}px);`}
    ${({ isVisible }) => `opacity: ${isVisible ? 1 : 0};`}
    ${({ isVisible }) => `pointer-events: ${isVisible ? 'auto' : 'none'};`}
  `,
  LeftAdditionalSidebar: styled.div<{ isVisible: boolean }>`
    position: absolute;
    top: 0px;
    left: calc(100% - 12px);
    bottom: 0px;
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    width: ${LEFT_ADDITIONAL_SIDEBAR_WIDTH}px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    background-color: ${defaultTheme.colors.white};
    transition: all 300ms ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
    ${({ isVisible }) => `transform: translateX(${isVisible ? 0 : -100}px);`}
    ${({ isVisible }) => `opacity: ${isVisible ? 1 : 0};`}
    ${({ isVisible }) => `pointer-events: ${isVisible ? 'auto' : 'none'};`}
  `,
  Overlay: styled.div`
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  `,
  ReplacingHighlight: styled.div<{
    isPreviewPanelVisible: boolean;
    isAdditionalPanelVisible: boolean;
  }>`
    z-index: 100;
    position: absolute;
    top: 0;
    left: ${PRIMARY_SIDEBAR_WIDTH}px;
    bottom: 0;
    width: ${({ isPreviewPanelVisible, isAdditionalPanelVisible }) =>
      isPreviewPanelVisible
        ? SECONDARY_SIDEBAR_WIDTH + LEFT_PREVIEW_SIDEBAR_WIDTH - 12
        : isAdditionalPanelVisible
        ? SECONDARY_SIDEBAR_WIDTH + LEFT_ADDITIONAL_SIDEBAR_WIDTH - 12
        : SECONDARY_SIDEBAR_WIDTH}px;
    border: 3px solid #fe9900;
    border-radius: 8px;
    transition: all 300ms ease-in-out;
    pointer-events: none;
  `,
};
