import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeOrganizationSelector } from '../../store/auth/auth.selectors';
import { showBottomPanel } from '../../store/bottomPanel/bottomPanel.slice';
import { EBottomPanelComponentName } from '../../utils/constants/bottomPanel';
import { IOrganization } from '../../types/organization';
import useOrganizations from '../../hooks/useOrganizations';
import BlueAndRedButtonsModal from '../../components/modals/BlueAndRedButtonsModal';
import { playlistItemSelector } from '../../store/playlist/playlist.selectors';
import { useTranslation } from 'react-i18next';
import { clonePlaylist } from '../../store/playlist/playlist.actions';
import ShadowedContainer from '../../components/ShadowedContainer';
import CopyIcon from '../../assets/icons/share-copy.svg';
import styled from 'styled-components/native';
import { calcHeight, calcWidth } from '../../utils/dimensions';

const ClonePlaylistButton = ({ playlistId }: { playlistId: number }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeOrganization = useSelector(activeOrganizationSelector);
  const isCloneAvailable = activeOrganization.is_creator;
  const creatorOrganizationsList = useOrganizations({ isCreator: true });
  const playlist = useSelector(playlistItemSelector(playlistId));

  const [selectedOrganization, setSelectedOrganization] = useState<IOrganization>(null);
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);

  const openCloneModal = useCallback(() => {
    setIsCloneModalOpen(true);
  }, []);

  const closeCloneModal = useCallback(() => {
    setSelectedOrganization(null);
    setIsCloneModalOpen(false);
  }, []);

  const handleClone = useCallback(() => {
    if (creatorOrganizationsList.length > 1) {
      dispatch(
        showBottomPanel({
          componentName: EBottomPanelComponentName.OrganizationSelectMenu,
          componentProps: {
            onOrganizationSelect: (org: IOrganization) => {
              setSelectedOrganization(org);
              openCloneModal();
            },
            isCreator: true,
          },
        }),
      );
      return;
    }
    openCloneModal();
  }, [dispatch, openCloneModal, creatorOrganizationsList.length]);

  const handleCloneConfirmation = useCallback(() => {
    const targetOrg = creatorOrganizationsList.length === 1 ? creatorOrganizationsList[0] : selectedOrganization;
    dispatch(clonePlaylist({ playlist, org: targetOrg }));
    closeCloneModal();
  }, [creatorOrganizationsList, selectedOrganization, dispatch, playlist, closeCloneModal]);

  const cloneModalDescription = useMemo(
    () =>
      selectedOrganization
        ? t('copyBiteModal.descriptionCloneToWorkspace', {
            biteName: playlist?.subject,
            workspaceName: selectedOrganization.name,
          })
        : t('copyBiteModal.description', {
            biteName: playlist?.subject,
          }),
    [playlist, selectedOrganization, t],
  );

  if (!isCloneAvailable) {
    return null;
  }
  return (
    <>
      <ShadowedContainer>
        <S.ClonePlaylistButton onPress={handleClone}>
          <CopyIcon style={S.Icon} />
        </S.ClonePlaylistButton>
      </ShadowedContainer>
      <BlueAndRedButtonsModal
        title={playlist?.is_quiz ? t('cloneQuizModal.title') : t('clonePlaylistModal.title')}
        description={cloneModalDescription}
        isVisible={isCloneModalOpen}
        onClose={closeCloneModal}
        onRightButtonClick={handleCloneConfirmation}
        onLeftButtonClick={closeCloneModal}
        rightButtonLabel={t('copyBiteModal.clone')}
        leftButtonLabel={t('copyBiteModal.close')}
        reverseColors
      />
    </>
  );
};

const S = {
  ClonePlaylistButton: styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${calcWidth(40 / 2)}px;
    height: ${calcWidth(40)}px;
    width: ${calcWidth(40)}px;
  `,
  Icon: {
    width: calcWidth(18),
    height: calcHeight(18),
  },
};

export default ClonePlaylistButton;
