import BitesApi from '../index';
import { EGenerationStatus, IGeneration } from '../../../aiGeneration/aiGeneration.types';
import { ICloudAsset } from '../../../cloudAssets/cloudAssets.types';

interface ICreateGenerationProps {
  orgId: string;
  generation: {
    inputCloudAssetIds: string[];
    topics: string[];
    withIntro: boolean;
    withBgMusic: boolean;
    voiceId: string;
    regeneratedFromId?: string;
  };
}

interface IUpdateGenerationProps {
  orgId: string;
  generationId: string;
  generation: Partial<IGeneration>;
}

export const createGeneration = ({ orgId, generation }: ICreateGenerationProps) =>
  BitesApi.post(`/script_to_video/organization/${orgId}/generation/`, {
    generation,
  });

export const updateGeneration = ({ orgId, generationId, generation }: IUpdateGenerationProps) =>
  BitesApi.patch(`/script_to_video/organization/${orgId}/generation/${generationId}`, {
    generation,
  });

export const searchGenerations = ({
  orgId,
  filters,
  additionalFields,
  sort,
  pageSize,
  page,
}: {
  orgId: string;
  filters: {
    orgId?: string;
    statuses?: EGenerationStatus[];
    search?: string;
  };
  additionalFields?: string[];
  sort?: Record<string, 1 | -1>;
  pageSize: number;
  page: number;
}) =>
  BitesApi.post(`/script_to_video/organization/${orgId}/generation/search`, {
    filters,
    additionalFields,
    sort,
    pageSize,
    page,
  });

export const runGeneration = ({ orgId, generationId }: { orgId: string; generationId: string }) =>
  BitesApi.post(`/script_to_video/organization/${orgId}/generation/${generationId}/generate`);

export const getGeneration = ({ orgId, generationId }: { orgId: string; generationId: string }) =>
  BitesApi.get(`/script_to_video/organization/${orgId}/generation/${generationId}`);

export const searchGeneratedScripts = ({ orgId, filters }: { orgId: string; filters?: { generationId: string } }) =>
  BitesApi.post(`/script_to_video/organization/${orgId}/video_script/search`, {
    filters,
  });

export const searchGeneratedScript = ({ orgId, scriptId }: { orgId: string; scriptId: string }) =>
  BitesApi.get(`/script_to_video/organization/${orgId}/video_script/${scriptId}`);

export const updateScript = ({ orgId, scriptId, script }: { orgId: string; scriptId: string; script: any }) =>
  BitesApi.patch(`/script_to_video/organization/${orgId}/video_script/${scriptId}`, {
    videoScript: script,
  });

export const createVideo = ({ orgId, scriptId }: { orgId: string; scriptId: string }) =>
  BitesApi.post(`/script_to_video/organization/${orgId}/video_script/${scriptId}/video/generate`);

export const deleteScript = ({ orgId, scriptId }: { orgId: string; scriptId: string }) =>
  BitesApi.delete(`/script_to_video/organization/${orgId}/video_script/${scriptId}`);

export const gererateTextToSpeech = ({ voiceId, orgId, text }: { voiceId: string; orgId: number; text: string }) =>
  BitesApi.post<{ cloudAsset: ICloudAsset }>('/common_services/cloud_asset/text_to_speech', {
    voiceId,
    orgId,
    text,
  });
