import React, { memo, useCallback } from 'react';
import { IPanelChildInjectedProps } from '../../../../store/bottomPanel/bottomPanel';
import styled from 'styled-components/native';
import { calcHeight } from '../../../../utils/dimensions';
import { TFilter, TFilterValue } from '../../../../store/attributes/biteStats/biteStats.types';
import { useTranslation } from 'react-i18next';
import { usersFilterButtonDataSet } from '../../../../screens/analytics/analytics.constants';

interface IProps extends IPanelChildInjectedProps {
  filters: TFilter[];
  currentFilter: TFilterValue;
  onChangeFilter: (filter: TFilterValue) => void;
}

const FilterMenu: React.FC<IProps> = ({ onChangeFilter, filters, currentFilter, onClosePanel }) => {
  const handleChangeFilter = (filter) => {
    onClosePanel(() => {
      onChangeFilter(filter);
    });
  };

  return (
    <S.Container>
      {filters.map((item) => (
        <FilterButton key={item.value} currentFilter={currentFilter} item={item} onPress={handleChangeFilter} />
      ))}
    </S.Container>
  );
};

interface IFilterButtonProps {
  currentFilter: TFilterValue;
  onPress: (filter: TFilterValue) => void;
  item: TFilter;
}
const FilterButton: React.FC<IFilterButtonProps> = ({ currentFilter, item, onPress }) => {
  const { t } = useTranslation();
  const handlePress = useCallback(() => {
    onPress(item.value);
  }, [item, onPress]);

  return (
    <S.FilterWrapper
      // @ts-ignore
      dataSet={usersFilterButtonDataSet}
      key={item.value}
      onPress={handlePress}
    >
      <S.Title isActive={currentFilter === item.value}>{t(item.label)}</S.Title>
    </S.FilterWrapper>
  );
};

const S = {
  Container: styled.View`
    width: 100%;
  `,
  FilterWrapper: styled.TouchableOpacity`
    height: ${calcHeight(75)}px;
    border-color: ${({ theme }) => theme.colors.gray17};
    border-bottom-width: 1px;

    align-items: center;
    justify-content: center;
  `,
  Title: styled.Text<{ isActive: boolean }>`
    font-size: ${({ theme }) => theme.fontSizes.s18};
    color: ${({ theme, isActive }) => (isActive ? theme.colors.primaryBlue : theme.colors.gray18)};
  `,
};

export default memo(FilterMenu);
