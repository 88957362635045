import { RootState } from '../../index';
import { EAnalyticsScreenType } from '../../../screens/analytics/Analytics.types';
import { TDisplayMode } from '../../../types/anayltics';

export const organizationAttributesLoadingSelector = (state: RootState) =>
  state.biteAttributes.organizationAttributes.isLoading;
export const overviewAttributesAbsoluteMapSelector = (state: RootState) =>
  state.biteAttributes.overviewAttributesMapById.absolute;
export const overviewAttributesPercentageMapSelector = (state: RootState) =>
  state.biteAttributes.overviewAttributesMapById.percentage;
export const overviewAttributeNextSelector = (attributeId: number, displayMode: TDisplayMode) => (state: RootState) =>
  state.biteAttributes.overviewAttributesMapById[displayMode][attributeId]?.next;

export const viewsAttributesAbsoluteMapSelector = (state: RootState) =>
  state.biteAttributes.viewsAttributesMapById.absolute;
export const viewsAttributesPercentageMapSelector = (state: RootState) =>
  state.biteAttributes.viewsAttributesMapById.percentage;

export const viewsAttributeNextSelector = (attributeId: number, displayMode: TDisplayMode) => (state: RootState) =>
  state.biteAttributes.viewsAttributesMapById[displayMode][attributeId]?.next;

export const answersAttributesAbsoluteMapSelector = (state: RootState) =>
  state.biteAttributes.answersAttributesMapById.absolute;
export const answersAttributesPercentageMapSelector = (state: RootState) =>
  state.biteAttributes.answersAttributesMapById.percentage;

export const answersAttributeNextSelector = (attributeId: number, displayMode: TDisplayMode) => (state: RootState) =>
  state.biteAttributes.answersAttributesMapById[displayMode][attributeId]?.next;

export const commentsAttributesAbsoluteMapSelector = (state: RootState) =>
  state.biteAttributes.commentsAttributesMapById.absolute;
export const commentsAttributesPercentageMapSelector = (state: RootState) =>
  state.biteAttributes.commentsAttributesMapById.percentage;

export const commentsAttributeNextSelector = (attributeId: number, displayMode: TDisplayMode) => (state: RootState) =>
  state.biteAttributes.commentsAttributesMapById[displayMode][attributeId]?.next;

export const biteOrganizationAttributesSelector = (state: RootState) =>
  state.biteAttributes.organizationAttributes.data;

export const organizationAttributesErrorSelector = (state: RootState) =>
  state.biteAttributes.organizationAttributes.error;

export const biteFilterAttributesMapByIdSelector = (state: RootState) => state.biteAttributes.filterAttributesMapById;

export const biteInitialAttributesMapByIdSelector = (state: RootState) => state.biteAttributes.initialAttributesMapById;

export const selectedValuesByAttributeIdSelector = (attributeId: number) => (state: RootState) =>
  state.biteAttributes.filterAttributesMapById?.[attributeId]?.selectedValues;

export const selectedFilterAttributeValueIdsSelector = (state: RootState) => state.biteAttributes.selectedValueIds;

export const filterAttributeByIdLoadingSelector = (attributeId: number) => (state: RootState) =>
  state.biteAttributes.filterAttributesMapById?.[attributeId]?.isLoading;

export const searchValueSelector = (state: RootState) => state.biteAttributes.filterSearchValue;

export const biteDisplayModeSelector = (state: RootState) => state.biteAttributes.displayMode;

export const filterAttributeByIdSelector = (attributeId: number) => (state: RootState) =>
  state.biteAttributes.filterAttributesMapById?.[attributeId];

export const searchDataSelector = (state: RootState) => state.biteAttributes.searchValues.data || [];

export const searchValuesLoadingSelector = (state: RootState) => state.biteAttributes.searchValues.isLoading;

export const searchValuesErrorSelector = (state: RootState) => state.biteAttributes.searchValues.error;

export const searchValuesNextSelector = (state: RootState) => state.biteAttributes.searchValues.next;

export const hasOverviewAttributesSelector = (state: RootState) => {
  const displayMode = state.biteAttributes.displayMode;
  const map = state.biteAttributes.overviewAttributesMapById[displayMode];

  return Object.values(map).some((value) => value.data.values.length > 0);
};
export const hasViewsAttributesSelector = (state: RootState) => {
  const displayMode = state.biteAttributes.displayMode;
  const map = state.biteAttributes.viewsAttributesMapById[displayMode];

  return Object.values(map).some((value) => value.data.values.length > 0);
};

export const hasAnsweredAttributesSelector = (state: RootState) => {
  const displayMode = state.biteAttributes.displayMode;
  const map = state.biteAttributes.answersAttributesMapById[displayMode];

  return Object.values(map).some((value) => value.data.values.length > 0);
};

export const hasCommentsAttributesSelector = (state: RootState) => {
  const displayMode = state.biteAttributes.displayMode;
  const map = state.biteAttributes.commentsAttributesMapById[displayMode];

  return Object.values(map).some((value) => value.data.values.length > 0);
};

export const isGuestsAttributeLoadedSelector = (currentTab: EAnalyticsScreenType) => (state: RootState) =>
  state.biteAttributes[`${currentTab}AttributesMapById`].isGuestsAttributesLoaded;

export const guestsAttributeSelector = (state: RootState) => state.biteAttributes.guestsAttribute;

export const guestsAttributeAnswersWithOptionSelector = (state: RootState) =>
  state.biteAttributes.guestsAttributeAnswersWithOption;

export const sortDirectionSelector = (currentTab: EAnalyticsScreenType) => (state: RootState) =>
  state.biteAttributes[`${currentTab}AttributesMapById`].sortDirection;

export const sortBySelector = (currentTab: EAnalyticsScreenType) => (state: RootState) =>
  state.biteAttributes[`${currentTab}AttributesMapById`].sortBy;

export const biteSharedWithFilterSelector = (state: RootState) => state.biteAttributes.sharedWithFilter;
