import React, { memo } from 'react';
import { PreviewContent, PreviewControls, VideoPlayer } from './Preview';
import SidebarTitle from '../SidebarTitle';

type Props = {
  url: string;
  onAdd: () => void;
  onReplace: () => void;
  onClose: () => void;
};
export const PreviewVideo = memo(({ url, onAdd, onReplace, onClose }: Props) => {
  return (
    <>
      <SidebarTitle title='Video' bold={false} />
      <PreviewContent>
        <VideoPlayer url={url} />
      </PreviewContent>
      <PreviewControls onAdd={onAdd} onReplace={onReplace} onClose={onClose} />
    </>
  );
});
