import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useShare } from '../../../../components/SharePanel/hooks/useShare';
import { EShareItemType, ESharePanelViewMode, showSharePanel } from '../../../../store/sharePanel/sharePanel.slice';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import styled, { css } from 'styled-components/native';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import TeamsShareModal from '../../../../components/modals/TeamsShareModal';
import MSTeamsPurpleIcon from '../../../../assets/icons/ms-teams-purple.svg';
import { IBiteItem } from '../../../../types/bite';
import { getBiteShareId } from '../../../../utils/formatDataFromServer';
import { IPlaylist } from '../../../../store/playlist/playlist.types';
import { IBiteShare } from '../../../../types/biteShare';
import { ESharePanelCallbackType } from '../../../../types/sharePanel';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import RequestNotificationPermissionsModal from '../../../../components/modals/RequestNotificationPermissionsModal';
import Button from '../../../../components/shared/buttons/Button/Button';
import themes from '../../../../themes';
import { changeButtonDataSet, selectedShareModeDataSet, shareButton } from './ShareSection.constants';
import { EButtonSize } from '../../../../types/common';
import useUserCanEdit from '../../../../components/shared/useUserCanEdit';
import { getIsWebRtl } from '../../../../locale/i18n';

export enum EShareButtonType {
  FILLED = 'filled',
  HOLLOW = 'hollow',
}

interface IProps {
  disabled?: boolean;
  shareData: IBiteItem | IPlaylist;
  subject: string;
  shareButtonType?: EShareButtonType;
  shareItemType?: EShareItemType;
  from?: string;
  leftButtonComponent?: (disabled: boolean) => React.ReactNode;
  buttonSize?: EButtonSize;
  type?: ESharePanelCallbackType;
}

const ShareSection: React.FC<IProps> = ({
  disabled,
  shareData,
  subject,
  shareItemType = EShareItemType.BITE,
  shareButtonType = EShareButtonType.FILLED,
  from,
  leftButtonComponent,
  buttonSize = EButtonSize.BASE,
  type,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isWebRtl = getIsWebRtl();

  const isPlaylist = 'bite_shares' in shareData;
  const sharingData = useMemo(() => ({ ...shareData, subject }), [shareData, subject]);
  const shareId = useMemo(
    () => (shareItemType === EShareItemType.BITE ? getBiteShareId(shareData as IBiteItem | IBiteShare) : shareData.id),
    [shareData, shareItemType],
  );

  const userCanEdit = useUserCanEdit(shareData);
  const [isShareWithTeamsModalOpen, setShareWithTeamsModalState] = useState(false);

  const handleOpenTeamsModal = useCallback(() => {
    setShareWithTeamsModalState(true);
  }, []);

  const handleCloseTeamsModal = useCallback(() => {
    setShareWithTeamsModalState(false);
  }, []);

  const {
    shareModeTitle,
    handleShareItem,
    hasTeamsShare,
    handleOpenNotificationPermissionModal,
    handleCloseNotificationPermissionModal,
    isRequestNotificationPermissionsModalOpen,
  } = useShare({
    shareData: sharingData,
    shareMode: sharingData?.sharing_mode,
    shareItemType,
    from,
  });

  const shareButtonHandler = useCallback(() => {
    if (!isWeb) {
      handleShareItem();
      return;
    }

    const sharePanelState =
      shareItemType === EShareItemType.BITE
        ? {
            callbackType: ESharePanelCallbackType.EDIT_BITE,
            itemType: EShareItemType.BITE,
            data: shareData,
          }
        : {
            callbackType: ESharePanelCallbackType.NEW_PLAYLIST,
            itemType: EShareItemType.PLAYLIST,
            data: shareData,
          };
    dispatch(showSharePanel(sharePanelState));
  }, [dispatch, handleShareItem, shareData, shareItemType]);

  const handleChangeViewSettings = useCallback(() => {
    const trackName = shareItemType === EShareItemType.BITE ? 'change_sharing_bite_btn' : 'change_sharing_playlist_btn';
    const callbackType = type
      ? type
      : shareItemType === EShareItemType.BITE
      ? ESharePanelCallbackType.EDIT_BITE
      : ESharePanelCallbackType.NEW_PLAYLIST;

    dispatch(
      trackEvent({
        event: trackName,
        props: { bite_id: sharingData.id, from },
      }),
    );

    dispatch(
      showSharePanel({
        callbackType,
        viewMode: ESharePanelViewMode.FULLSCREEN,
        itemType: shareItemType,
        data: sharingData,
        from,
      }),
    );
  }, [shareItemType, type, dispatch, sharingData, from]);

  const shareButtonStyleProps = {
    textColor: shareButtonType === EShareButtonType.FILLED ? themes.colors.white : themes.colors.primaryBlue,
    fill: shareButtonType === EShareButtonType.FILLED ? themes.colors.primaryBlue : themes.colors.white,
    isBoldText: true,
    ...(shareButtonType === EShareButtonType.HOLLOW ? { border: themes.colors.primaryBlue } : null),
  };

  return (
    <>
      <S.ButtonsContainer isRtl={isWebRtl} buttonSize={buttonSize}>
        {leftButtonComponent && leftButtonComponent(disabled)}
        <S.ShareButtonContainer isRtl={isWebRtl} hasLeftButtonComponent={!!leftButtonComponent}>
          <S.ShareButton
            isShadowed
            buttonSize={buttonSize}
            disabled={disabled}
            hasLeftButtonComponent={!!leftButtonComponent}
            hasTeamsShare={hasTeamsShare && !leftButtonComponent}
            onPress={shareButtonHandler}
            text={t('common.Share')}
            {...shareButtonStyleProps}
            // @ts-ignore
            dataSet={shareButton}
          />
        </S.ShareButtonContainer>
        {hasTeamsShare && !leftButtonComponent && (
          <S.TeamsShareBtn buttonSize={buttonSize} disabled={disabled} onPress={handleOpenTeamsModal}>
            <MSTeamsPurpleIcon
              width={buttonSize === EButtonSize.SMALL ? calcWidth(26) : calcWidth(32)}
              height={buttonSize === EButtonSize.SMALL ? calcWidth(26) : calcWidth(32)}
              fill='white'
            />
          </S.TeamsShareBtn>
        )}
      </S.ButtonsContainer>
      <S.TeamsButtonAndSharingStatus>
        {!disabled && (
          <S.ShareModeContainer hasTeamsShare={hasTeamsShare && !leftButtonComponent}>
            <S.ShareModeText
              //@ts-ignore
              dataSet={selectedShareModeDataSet}
            >
              {t('biteCreated.sharingStatus', {
                biteShareSetting: shareModeTitle,
              })}
            </S.ShareModeText>
            {userCanEdit && (
              <TouchableOpacity
                onPress={handleChangeViewSettings}
                //@ts-ignore
                dataSet={changeButtonDataSet}
              >
                <S.ShareModeButtonText>{t('biteCreated.change')}</S.ShareModeButtonText>
              </TouchableOpacity>
            )}
          </S.ShareModeContainer>
        )}
        {hasTeamsShare && !!leftButtonComponent && (
          <S.TeamsShareBtn buttonSize={buttonSize} disabled={disabled} onPress={handleOpenTeamsModal}>
            <MSTeamsPurpleIcon
              width={buttonSize === EButtonSize.SMALL ? calcWidth(26) : calcWidth(32)}
              height={buttonSize === EButtonSize.SMALL ? calcWidth(26) : calcWidth(32)}
              fill='white'
            />
          </S.TeamsShareBtn>
        )}
      </S.TeamsButtonAndSharingStatus>
      <TeamsShareModal
        isVisible={isShareWithTeamsModalOpen}
        closeModal={handleCloseTeamsModal}
        shareId={shareId}
        isPlaylist={isPlaylist}
        onShare={handleOpenNotificationPermissionModal}
      />
      <RequestNotificationPermissionsModal
        isVisible={isRequestNotificationPermissionsModalOpen}
        onClose={handleCloseNotificationPermissionModal}
      />
    </>
  );
};

const S = {
  ButtonsContainer: styled.View<{ isRtl: boolean; buttonSize: EButtonSize }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-self: center;
    align-items: center;

    ${({ buttonSize }) =>
      buttonSize === EButtonSize.SMALL &&
      css`
        width: 100%;
        justify-content: space-between;
        padding: 12px 15px 0;
      `}
  `,
  ShareButtonContainer: styled.View<{ isRtl: boolean; hasLeftButtonComponent: boolean }>`
    ${({ isRtl, hasLeftButtonComponent }) =>
      isRtl
        ? css`
            margin-right: ${hasLeftButtonComponent ? calcWidth(16) : '0'}px;
          `
        : css`
            margin-left: ${hasLeftButtonComponent ? calcWidth(16) : '0'}px;
          `};
  `,
  ShareButton: styled(Button)<{ hasLeftButtonComponent: boolean; buttonSize: EButtonSize; hasTeamsShare?: boolean }>`
    width: ${({ hasLeftButtonComponent, buttonSize, hasTeamsShare }) =>
      buttonSize === EButtonSize.SMALL && hasLeftButtonComponent
        ? calcWidth(103)
        : hasLeftButtonComponent
        ? calcWidth(159)
        : isWeb
        ? calcWidth(329) - (hasTeamsShare ? calcWidth(60) : 0)
        : calcWidth(324) - (hasTeamsShare ? calcWidth(80) : 0)}px;
    height: ${({ buttonSize }) => (buttonSize === EButtonSize.SMALL ? calcHeight(42) : calcHeight(50))}px;
  `,
  ShareButtonText: styled.Text<{ shareButtonType: EShareButtonType; disabled: boolean }>`
    color: ${({ theme, shareButtonType, disabled }) =>
      shareButtonType === EShareButtonType.HOLLOW
        ? disabled
          ? theme.colors.lightGray35
          : theme.colors.primaryBlue
        : theme.colors.white};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s18};
    font-weight: bold;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
  ShareModeContainer: styled.View<{ hasTeamsShare?: boolean }>`
    max-width: ${({ hasTeamsShare }) => (hasTeamsShare ? calcWidth(250) : calcWidth(300))}px;
    align-self: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  ShareModeText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
  `,
  ShareModeButtonText: styled.Text`
    margin: 0 ${calcWidth(16)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  TeamsShareBtn: styled.TouchableOpacity<{ buttonSize?: EButtonSize }>`
    align-items: center;
    justify-content: center;
    height: ${({ buttonSize }) => (buttonSize ? calcHeight(40) : calcHeight(48))}px;
    width: ${({ buttonSize }) => (buttonSize ? calcHeight(40) : calcHeight(48))}px;
    border-radius: ${calcHeight(48) / 2}px;
    border: 1px solid ${({ theme }) => theme.colors.primaryBlue};
  `,
  TeamsButtonAndSharingStatus: styled.View`
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: ${isWeb ? calcWidth(323) : calcWidth(350)}px;
    margin-top: ${calcHeight(23)}px;
  `,
};

export default memo(ShareSection);
