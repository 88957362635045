import { useSelector } from 'react-redux';
import {
  playlistDisplayModeSelector,
  playlistAttributesAbsoluteMapSelector,
  playlistAttributesPercentageMapSelector,
} from '../../../../store/attributes/playlistAttributes/playlistAttributes.selector';
import { useMemo } from 'react';

const usePlaylistAttributesMap = () => {
  const displayMode = useSelector(playlistDisplayModeSelector);
  const playlistAttributesAbsoluteMap = useSelector(playlistAttributesAbsoluteMapSelector);
  const playlistAttributesPercentageMap = useSelector(playlistAttributesPercentageMapSelector);

  const attributesMap = useMemo(
    () => (displayMode === 'absolute' ? playlistAttributesAbsoluteMap : playlistAttributesPercentageMap),
    [displayMode, playlistAttributesAbsoluteMap, playlistAttributesPercentageMap],
  );

  return {
    attributesMap,
  };
};

export default usePlaylistAttributesMap;
