import { Pressable, SafeAreaView } from 'react-native';
import AnimatedBottomSheet from '../../../../components/shared/AnimatedBottomSheet/AnimatedBottomSheet';
import React, { useCallback, useEffect, useState } from 'react';
import { calcHeight, calcWidth, deviceHeight, deviceWidth } from '../../../../utils/dimensions';
import styled, { useTheme } from 'styled-components/native';
import { useDispatch, useSelector } from 'react-redux';
import {
  hasDistributionsSelector,
  isFilterBottomSheetOpenSelector,
} from '../../../../store/analytics/analytics.selector';
import { resetAnalytics, setFilterBottomSheetOpen } from '../../../../store/analytics/analytics.slice';
import LockIcon from '../../../../assets/icons/analytics/lock.svg';
import CheckmarkIcon from '../../../../assets/icons/analytics/checkmark.svg';
import {
  playlistDisplayModeSelector,
  playlistSharedWithFilterSelector,
} from '../../../../store/attributes/playlistAttributes/playlistAttributes.selector';
import { log } from '../../../../store/appActivity/appActivity.slice';
import {
  setDisplayMode,
  setSharedWithFilter,
} from '../../../../store/attributes/playlistAttributes/playlistAttributes.slice';
import { useTranslation } from 'react-i18next';
import Intercom from '../../../../services/intercom/intercom';
import { configSelector } from '../../../../store/appActivity/appActivity.selectors';
import { isEqual } from 'lodash';
import Button from '../../../../components/shared/buttons';
import {
  absoluteOptionDataSet,
  filterMenuApplyButtonDataSet,
  filterMenuContainerDataSet,
  lockedSharedWithOptionDataSet,
  percentsOptionDataSet,
  sharedWithOptionDataSet,
} from '../../analytics.constants';
import { ESharedWithFilter } from '../../../../types/anayltics';

const lockedSharedWithDataSet = { 'intercom-target': 'shared-with-filter-title' };

const MAX_HEIGHT = deviceHeight * 0.9;
const MIN_HEIGHT = calcHeight(300);
const FilterMenuBottomSheet = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const displayMode = useSelector(playlistDisplayModeSelector);
  const isFilterBottomSheetOpen = useSelector(isFilterBottomSheetOpenSelector);
  const hasDistributions = useSelector(hasDistributionsSelector);
  const sharedWithFilter = useSelector(playlistSharedWithFilterSelector);
  const config = useSelector(configSelector);

  const [viewDataBy, setViewDataBy] = useState(displayMode);
  const [sharedWith, setSharedWith] = useState<ESharedWithFilter[]>(sharedWithFilter);

  const handleMenuClose = useCallback(() => {
    dispatch(setFilterBottomSheetOpen(false));
  }, [dispatch]);

  const handlePercentsPress = useCallback(() => {
    dispatch(
      log({
        event: 'FilterMenuBottomSheet.handlePercentsPress',
        data: { displayMode },
      }),
    );

    setViewDataBy('percentage');
  }, [dispatch, displayMode]);

  const handleAbsolutePress = useCallback(() => {
    dispatch(
      log({
        event: 'FilterMenuBottomSheet.handleAbsolutePress',
        data: { displayMode },
      }),
    );

    setViewDataBy('absolute');
  }, [dispatch, displayMode]);

  const toggleSharedWith = useCallback(
    (option: ESharedWithFilter) => {
      if (sharedWith.includes(option)) {
        setSharedWith(sharedWith.filter((filter) => filter !== option));
      } else {
        setSharedWith([...sharedWith, option].sort());
      }
    },
    [sharedWith],
  );

  const handleCommunicationHubPress = useCallback(() => {
    dispatch(
      log({
        event: 'FilterMenuBottomSheet.handleCommunicationHubPress',
      }),
    );

    toggleSharedWith(ESharedWithFilter.COMMUNICATION_HUB);
  }, [dispatch, toggleSharedWith]);

  const handleOrganicallyPress = useCallback(() => {
    dispatch(
      log({
        event: 'FilterMenuBottomSheet.handleCommunicationHubPress',
      }),
    );

    toggleSharedWith(ESharedWithFilter.ORGANICALLY);
  }, [dispatch, toggleSharedWith]);

  const handleSelectAllUsers = useCallback(() => {
    setSharedWith([]);
  }, []);

  const handleSelectSharedWith = useCallback(() => {
    if (!hasDistributions) {
      dispatch(
        log({
          event: 'FilterMenuBottomSheet.handleLockedSharedWith',
        }),
      );

      Intercom.displayArticle(config.intercom.lockedCommunicationHubArticleId);
      return;
    }

    setSharedWith([ESharedWithFilter.COMMUNICATION_HUB]);
  }, [dispatch, hasDistributions, config]);

  const handleDone = useCallback(() => {
    const isSharedWithChanged = !isEqual(sharedWith, sharedWithFilter);
    const isDisplayModeChanged = viewDataBy !== displayMode;

    if (!isSharedWithChanged && !isDisplayModeChanged) {
      handleMenuClose();
      return;
    }

    dispatch(
      log({
        event: 'FilterMenuBottomSheet.handleSharedWithFilter',
        data: { sharedWithFilter },
      }),
    );

    if (isSharedWithChanged) {
      dispatch(setSharedWithFilter(sharedWith));
    }

    if (isDisplayModeChanged) {
      dispatch(setDisplayMode(viewDataBy));
    }

    dispatch(
      resetAnalytics({
        excludeFields: {
          analytics: ['contentId', 'contentType', 'currentList', 'hasDistributions', 'isFilterTooltipShown'],
        },
      }),
    );
    handleMenuClose();
  }, [dispatch, sharedWith, sharedWithFilter, viewDataBy, displayMode, handleMenuClose]);

  const renderDoneButton = useCallback(() => {
    return (
      <S.DoneButton
        dataSet={filterMenuApplyButtonDataSet}
        onPress={handleDone}
        content={t('analytics.attributes.filterPanel.apply')}
      />
    );
  }, [handleDone, t]);

  useEffect(() => {
    if (!isFilterBottomSheetOpen) {
      return;
    }

    setViewDataBy(displayMode);
    setSharedWith(sharedWithFilter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterBottomSheetOpen]);

  return (
    <AnimatedBottomSheet
      isVisible={isFilterBottomSheetOpen}
      onClose={handleMenuClose}
      maxHeight={MAX_HEIGHT}
      minHeight={MIN_HEIGHT}
      backdropOpacity={0.5}
      renderFooter={renderDoneButton}
      withScroll
    >
      <SafeAreaView>
        <S.ContentContainer dataSet={filterMenuContainerDataSet}>
          <S.List>
            <S.SectionHeader dataSet={lockedSharedWithDataSet}>
              {t('analytics.filter.filterBy.usersBy')}
            </S.SectionHeader>
            <FilterMenuListItem
              type={EFilterMenuListItemType.POINTER}
              text={t('analytics.filter.allUsers')}
              isSelected={!sharedWith.length}
              onPress={handleSelectAllUsers}
            />
            <FilterMenuListItem
              dataSet={hasDistributions ? sharedWithOptionDataSet : lockedSharedWithOptionDataSet}
              type={EFilterMenuListItemType.POINTER}
              text={t('analytics.filter.sharedWith')}
              isSelected={!!sharedWith.length}
              onPress={handleSelectSharedWith}
              isLocked={!hasDistributions}
            />
            {!!sharedWith.length && (
              <S.LeftOffsetContainer>
                <FilterMenuListItem
                  type={EFilterMenuListItemType.CHECKMARK}
                  text={t('analytics.filter.communicationHub')}
                  isSelected={sharedWith.includes(ESharedWithFilter.COMMUNICATION_HUB)}
                  onPress={handleCommunicationHubPress}
                />
                <FilterMenuListItem
                  type={EFilterMenuListItemType.CHECKMARK}
                  text={t('analytics.filter.organically')}
                  isSelected={sharedWith.includes(ESharedWithFilter.ORGANICALLY)}
                  onPress={handleOrganicallyPress}
                />
              </S.LeftOffsetContainer>
            )}

            <S.Divider />

            <S.SectionHeader>{t('analytics.filter.filterBy.byUsersType')}</S.SectionHeader>
            <FilterMenuListItem
              type={EFilterMenuListItemType.CHECKMARK}
              text={t('analytics.filter.activeUsers')}
              isSelected
              isInactive
            />
            <FilterMenuListItem
              type={EFilterMenuListItemType.CHECKMARK}
              text={t('analytics.filter.inactiveUsers')}
              isInactive
            />
            <FilterMenuListItem
              type={EFilterMenuListItemType.CHECKMARK}
              text={t('analytics.filter.guestsUsers')}
              isSelected
              isInactive
            />

            <S.Divider />

            <S.SectionHeader>{t('analytics.filter.filterBy.viewDataBy')}</S.SectionHeader>
            <FilterMenuListItem
              dataSet={percentsOptionDataSet}
              onPress={handlePercentsPress}
              type={EFilterMenuListItemType.POINTER}
              isSelected={viewDataBy === 'percentage'}
              text={t('analytics.filter.percents')}
            />
            <FilterMenuListItem
              dataSet={absoluteOptionDataSet}
              onPress={handleAbsolutePress}
              type={EFilterMenuListItemType.POINTER}
              isSelected={viewDataBy === 'absolute'}
              text={t('analytics.filter.numbers')}
            />

            <S.Divider />

            <S.SectionHeader>{t('analytics.filter.filterBy.timePeriod')}</S.SectionHeader>
            <FilterMenuListItem
              type={EFilterMenuListItemType.POINTER}
              text={t('analytics.filter.allTimeViews')}
              isSelected
              isInactive
            />
            <FilterMenuListItem
              type={EFilterMenuListItemType.POINTER}
              text={t('analytics.filter.lastSevenDaysViews')}
              isInactive
            />
            <FilterMenuListItem
              type={EFilterMenuListItemType.POINTER}
              text={t('analytics.filter.lastThirtyDaysViews')}
              isInactive
            />
            <FilterMenuListItem
              type={EFilterMenuListItemType.POINTER}
              text={t('analytics.filter.thisYearViews')}
              isInactive
            />
          </S.List>
        </S.ContentContainer>
      </SafeAreaView>
    </AnimatedBottomSheet>
  );
};

enum EFilterMenuListItemType {
  CHECKMARK = 'checkmark',
  POINTER = 'pointer',
}

interface IFilterMenuListItemProps {
  onPress?: () => void;
  text: string;
  type: EFilterMenuListItemType;
  isSelected?: boolean;
  isLocked?: boolean;
  isInactive?: boolean;
  dataSet?: { [key: string]: string };
}

const FilterMenuListItem: React.FC<IFilterMenuListItemProps> = ({
  onPress,
  text,
  type,
  isSelected,
  isLocked,
  isInactive,
  dataSet,
}) => {
  const theme = useTheme();
  const iconColor = isInactive || isLocked ? theme.colors.darkGray3 : theme.colors.primaryBlue;

  return (
    <S.ListItem disabled={isInactive} onPress={onPress}>
      <S.ListItemTextContainer>
        <S.ListItemText dataSet={dataSet} isInactive={isInactive || isLocked}>
          {text}
        </S.ListItemText>
        {isLocked && (
          <S.LockIconContainer>
            <LockIcon />
          </S.LockIconContainer>
        )}
      </S.ListItemTextContainer>
      {isSelected &&
        (type === EFilterMenuListItemType.CHECKMARK ? (
          <CheckmarkIcon color={iconColor} />
        ) : (
          <S.Point color={iconColor} />
        ))}
    </S.ListItem>
  );
};

const S = {
  ContentContainer: styled.View`
    height: auto;
    width: 100%;
    padding: ${calcHeight(25)}px ${calcWidth(25)}px ${calcHeight(90)}px;
  `,
  List: styled.View`
    align-items: center;
  `,
  ListItem: styled.TouchableOpacity`
    width: 100%;
    padding: ${calcHeight(10)}px 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `,
  ListItemText: styled.Text<{ isInactive: boolean }>`
    font-size: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme, isInactive }) => (isInactive ? theme.colors.darkGray3 : theme.colors.text)};
  `,
  Divider: styled.View`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.overviewDivider};
    margin: ${calcHeight(15)}px 0;
  `,
  ListItemTextContainer: styled.View`
    flex-direction: row;
    align-items: center;
  `,
  Point: styled.View<{ color: string }>`
    width: ${calcWidth(8)}px;
    height: ${calcWidth(8)}px;
    border-radius: ${calcWidth(4)}px;
    background-color: ${({ color }) => color};
  `,
  LockIconContainer: styled.View`
    margin-left: ${calcWidth(10)}px;
  `,
  SharedWithButtonContainer: styled(Pressable)`
    width: 100%;
    padding: ${calcHeight(10)}px 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `,
  LeftOffsetContainer: styled.View`
    padding-left: ${calcWidth(15)}px;
    width: 100%;
  `,
  SectionHeader: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.gray1};
    padding: ${calcHeight(5)}px 0 ${calcHeight(10)}px;
    width: 100%;
  `,
  DoneButton: styled(Button)`
    align-self: center;
    bottom: ${calcHeight(20)}px;
    width: ${deviceWidth - calcHeight(50)}px;
    height: ${calcHeight(60)}px;
  `,
};

export default FilterMenuBottomSheet;
