import { EAnalyticsScreenType } from '../../screens/analytics/Analytics.types';
import openPreviewBite from './openPreviewBite';
import store from '../../store';
import { onAttributes } from '../../store/bite/bite.actions';
import openBiteStats from './openBiteStats';

const openFeedBite = ({ biteId, tab }: { biteId: number; tab?: EAnalyticsScreenType }) => {
  const biteIdAsNumber = Number(biteId);
  const callback = ({ attributes }) => {
    if (!attributes) {
      openBiteStats({ biteId: biteIdAsNumber, tab });
      return;
    }

    openPreviewBite({ biteId: biteIdAsNumber, tab });
  };

  store.dispatch(onAttributes({ biteId: biteIdAsNumber, callback }));
};

export default openFeedBite;
