import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ActivityIndicator, FlatList, FlatListProps, RefreshControl } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, isWeb } from '../../../utils/dimensions';
import { useTranslation } from 'react-i18next';
import { useIsFocused } from '@react-navigation/native';
import { log } from '../../../store/appActivity/appActivity.slice';
import { useDispatch } from 'react-redux';
import { setIsRefreshing as setIsRefreshingGlobal } from '../../../store/analytics/analytics.slice';
import { EAnalyticsScreenType } from '../Analytics.types';
import {
  analyticsListErrorDataSet,
  analyticsListLoaderDataSet,
  analyticsListNoDataDataSet,
  refreshButtonDataSet,
} from '../analytics.constants';

interface IProps extends Omit<FlatListProps<FlatList>, 'onRefresh'> {
  data: any[];
  renderItem: ({ item }: { item: any }) => React.ReactElement;
  selectedValueIds: number[];
  isLoading?: boolean;
  onRefresh?: ({ isPullToRefresh, callback }: { isPullToRefresh?: boolean; callback?: () => void }) => void;
  onEndReached?: () => void;
  onErrorRefresh?: () => void;
  error: string;
  isNeedToScrollUp?: boolean;
  listType?: EAnalyticsScreenType;
  dataSet?: { [key: string]: string };
  setIsNeedToScrollUp: (data: { listType: EAnalyticsScreenType; status: boolean }) => void;
}

const AnalyticsList: React.FC<IProps> = ({
  data,
  renderItem,
  isLoading,
  onRefresh,
  onEndReached,
  error,
  onErrorRefresh,
  isNeedToScrollUp,
  setIsNeedToScrollUp,
  listType,
  dataSet,
  selectedValueIds,
  ...props
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const isFocused = useIsFocused();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const hasElements = data?.some((item) => item);

  const keyExtractor = useCallback((item, index) => (item?.user?.id || item?.user?.guest_id) + '-' + index, []);

  const listRef = useRef<FlatList>(null);

  const handleRefresh = useCallback(() => {
    if (typeof onRefresh !== 'function') {
      return;
    }

    dispatch(
      log({
        event: 'AnalyticsList.handleRefresh',
      }),
    );

    dispatch(setIsRefreshingGlobal(true));
    setIsRefreshing(true);
    onRefresh({ isPullToRefresh: true, callback: () => setIsRefreshing(false) });
  }, [dispatch, onRefresh]);

  const handleRefreshWithButton = useCallback(() => {
    if (typeof onRefresh !== 'function') {
      return;
    }
    dispatch(
      log({
        event: 'AnalyticsList.handleRefreshWithButton',
      }),
    );

    onRefresh({ isPullToRefresh: false });
  }, [dispatch, onRefresh]);

  const renderFooterContainer = useCallback(() => {
    if (isRefreshing) {
      return null;
    }

    if (isLoading) {
      return (
        <S.FooterContainer
          // @ts-ignore
          dataSet={analyticsListLoaderDataSet}
        >
          <ActivityIndicator size='large' />
        </S.FooterContainer>
      );
    }

    if (error) {
      return (
        <S.ErrorContainer
          // @ts-ignore
          dataSet={analyticsListErrorDataSet}
        >
          <S.ErrorText>{t('common.somethingWentWrong')}</S.ErrorText>
          <S.RefreshButton
            // @ts-ignore
            dataSet={refreshButtonDataSet}
            onPress={onErrorRefresh}
          >
            <S.RefreshText>{t('analytics.error.refresh')}</S.RefreshText>
          </S.RefreshButton>
        </S.ErrorContainer>
      );
    }

    if (!hasElements) {
      return (
        <S.ErrorContainer
          // @ts-ignore
          dataSet={analyticsListNoDataDataSet}
        >
          <S.ErrorText>{t('analytics.error.noData')}</S.ErrorText>
          <S.RefreshButton
            // @ts-ignore
            dataSet={refreshButtonDataSet}
            onPress={handleRefreshWithButton}
          >
            <S.RefreshText>{t('analytics.error.refresh')}</S.RefreshText>
          </S.RefreshButton>
        </S.ErrorContainer>
      );
    }

    return null;
  }, [isRefreshing, isLoading, error, hasElements, t, onErrorRefresh, handleRefreshWithButton]);

  const handleContentSizeChange = useCallback(
    (contentWidth, contentHeight) => {
      if (
        // @ts-ignore
        window.Cypress ||
        !hasElements ||
        error ||
        isLoading ||
        isRefreshing ||
        contentHeight > window.innerHeight ||
        contentHeight === 0 ||
        typeof onEndReached !== 'function'
      ) {
        return;
      }

      onEndReached();
    },
    [hasElements, error, isLoading, isRefreshing, onEndReached],
  );

  const handleEndReached = useCallback(() => {
    dispatch(
      log({
        event: 'AnalyticsList.handleEndReached',
      }),
    );

    onEndReached();
  }, [dispatch, onEndReached]);

  const handleEndReachedProp = useMemo(() => {
    if (!hasElements || error || isLoading || !isFocused || typeof onEndReached !== 'function') {
      return;
    }

    return handleEndReached;
  }, [hasElements, error, isLoading, isFocused, onEndReached, handleEndReached]);

  useEffect(() => {
    listRef.current.scrollToOffset({ animated: isFocused, offset: 0 });
  }, [isFocused, selectedValueIds]);

  useEffect(() => {
    if (isFocused && isNeedToScrollUp) {
      listRef.current.scrollToOffset({ animated: true, offset: 0 });
      setIsNeedToScrollUp({ listType, status: false });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedToScrollUp]);

  return (
    <S.List
      {...props}
      ref={listRef}
      data={data}
      renderItem={renderItem}
      onEndReached={handleEndReachedProp}
      onEndReachedThreshold={isWeb ? 0.001 : 0.1}
      refreshControl={
        <RefreshControl refreshing={isRefreshing} onRefresh={handleRefresh} tintColor={theme.colors.gray19} />
      }
      ListFooterComponent={renderFooterContainer}
      onContentSizeChange={isWeb && handleContentSizeChange}
      keyExtractor={keyExtractor}
      // @ts-ignore
      dataSet={dataSet}
    />
  );
};

const S = {
  List: styled.FlatList`
    height: 100%;
  `,
  ErrorText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.text};
  `,
  FooterContainer: styled.View`
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: ${calcHeight(20)}px;
  `,
  ErrorContainer: styled.View`
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: ${calcHeight(20)}px 0;
  `,
  RefreshButton: styled.TouchableOpacity`
    margin-top: 10px;
  `,
  RefreshText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
};

export default memo(AnalyticsList);
