import React, { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/native';
import { calcHeight, calcWidth, deviceHeight, deviceWidth } from '../../../../utils/dimensions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/shared/buttons';
import SearchList from './SearchList';
import FilterList from './FilterList';
import FilterPanelHeader from './FilterPanelHeader';
import FilterButton from './FilterButton';
import AnimatedBottomSheet from '../../../../components/shared/AnimatedBottomSheet/AnimatedBottomSheet';
import { SafeAreaView } from 'react-native';
import { log } from '../../../../store/appActivity/appActivity.slice';
import { resetAnalytics } from '../../../../store/analytics/analytics.slice';
import useSelectedValues from '../../hooks/useSelectedValues';
import {
  filterDoneButtonDataSet,
  filterDrawerListDataSet,
  filterDrawerSearchListDataSet,
  filterListItemDataSet,
} from '../../analytics.constants';
import { IOrganizationAttributeMapItem, IOrganizationAttributeValue } from '../../../../types/anayltics';

interface IProps {
  isVisible: boolean;
  onClose: () => void;
  selectedAttributeId: number;
  attributeMapItem: IOrganizationAttributeMapItem;
  selectedValueIds: number[];
  onDoneButtonPress: (payload: { attributeId: number; values: IOrganizationAttributeValue[] }) => void;
  onGetSearchList: (payload: { attributeId: number; reset?: boolean }) => void;
  onGetFilterList: (payload: { attributeId: number; reset?: boolean }) => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  isSearchDataLoading: boolean;
  searchData: IOrganizationAttributeValue[];
  searchDataError: string;
  searchDataNext: string;
}

const FILTER_LIST_MAX_HEIGHT = deviceHeight * 0.8;
const SEARCH_LIST_MAX_HEIGHT = deviceHeight;
const MIN_HEIGHT = calcHeight(300);

const FilterBottomSheet: React.FC<IProps> = ({
  isVisible,
  onClose,
  onDoneButtonPress,
  attributeMapItem,
  selectedValueIds,
  selectedAttributeId,
  onGetSearchList,
  onGetFilterList,
  searchValue,
  setSearchValue,
  isSearchDataLoading,
  searchData,
  searchDataError,
  searchDataNext,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isSearchMode, setIsSearchMode] = useState(false);
  const [isShowSelectedFilters, setIsShowSelectedFilters] = useState(false);

  const [selectedValues, setSelectedValues, selectedValuesMap] = useSelectedValues([]);
  const [displaySelectedValues, setDisplaySelectedValues, displaySelectedValuesMap] = useSelectedValues([]);
  const [initialSelectedValues, setInitialSelectedValues, initialSelectedValuesMap] = useSelectedValues([]);

  const handleClearFilters = useCallback(() => {
    dispatch(
      log({
        event: 'FilterBottomSheet.handleClearFilters',
      }),
    );
    setSelectedValues([]);
    setDisplaySelectedValues([]);
    setIsShowSelectedFilters(false);
  }, [dispatch, setDisplaySelectedValues, setSelectedValues]);

  const handleShowSelectedFilters = useCallback(() => {
    dispatch(
      log({
        event: 'FilterBottomSheet.handleShowSelectedFilters',
        data: { isShowSelectedFilters: !isShowSelectedFilters },
      }),
    );

    if (isShowSelectedFilters) {
      setIsShowSelectedFilters(false);
      return;
    }

    setIsShowSelectedFilters(true);
    setDisplaySelectedValues(selectedValues);
  }, [dispatch, isShowSelectedFilters, selectedValues, setDisplaySelectedValues]);

  const handleDoneButtonPress = useCallback(() => {
    dispatch(
      log({
        event: 'FilterBottomSheet.handleDoneButtonPress',
        data: { selectedAttributeId, selectedValues },
      }),
    );

    dispatch(
      resetAnalytics({
        excludeFields: {
          analytics: ['contentId', 'contentType', 'currentList', 'hasDistributions', 'isFilterTooltipShown'],
        },
      }),
    );

    onDoneButtonPress({ attributeId: selectedAttributeId, values: selectedValues });
    setIsShowSelectedFilters(false);
    onClose();
  }, [dispatch, onClose, onDoneButtonPress, selectedAttributeId, selectedValues]);

  const handleChangeFilter = useCallback(
    (value) => {
      const updatedValues = [...selectedValues];

      if (selectedValuesMap[value.id]) {
        const index = updatedValues.findIndex((item) => item.id === value.id);
        updatedValues.splice(index, 1);
      } else {
        if (!displaySelectedValuesMap[value.id]) {
          const updatedDisplayValues = [...displaySelectedValues];

          updatedDisplayValues.push(value);
          updatedDisplayValues.sort((a, b) => a.name.localeCompare(b.name));

          setDisplaySelectedValues(updatedDisplayValues);
        }

        updatedValues.push(value);
        updatedValues.sort((a, b) => a.name.localeCompare(b.name));
      }

      dispatch(
        log({
          event: 'FilterBottomSheet.handleChangeFilter',
          data: { value, updatedFilters: updatedValues },
        }),
      );

      setSelectedValues(updatedValues);
    },
    [
      dispatch,
      displaySelectedValues,
      displaySelectedValuesMap,
      selectedValues,
      selectedValuesMap,
      setDisplaySelectedValues,
      setSelectedValues,
    ],
  );

  const handleSearchDone = useCallback(() => {
    dispatch(
      log({
        event: 'FilterBottomSheet.handleSearchDone',
      }),
    );
    setDisplaySelectedValues(selectedValues);
    setIsSearchMode(false);
  }, [dispatch, selectedValues, setDisplaySelectedValues]);

  const handleSearch = useCallback(() => {
    dispatch(
      log({
        event: 'FilterBottomSheet.handleSearch',
        data: { selectedAttributeId },
      }),
    );

    onGetSearchList({ attributeId: selectedAttributeId, reset: true });
    setDisplaySelectedValues(selectedValues);
    setIsSearchMode(true);
  }, [dispatch, onGetSearchList, selectedAttributeId, selectedValues, setDisplaySelectedValues]);

  const renderFooter = useCallback(() => {
    if (isSearchMode) {
      return;
    }

    return (
      <S.DoneButton
        // @ts-ignore
        dataSet={filterDoneButtonDataSet}
        onPress={handleDoneButtonPress}
        content={t('analytics.attributes.filterPanel.done')}
      />
    );
  }, [handleDoneButtonPress, isSearchMode, t]);

  const renderItem = useCallback(
    ({ item }) => (
      <FilterButton
        dataSet={filterListItemDataSet}
        value={item}
        onPress={handleChangeFilter}
        isSelected={selectedValuesMap[item.id]}
      />
    ),
    [handleChangeFilter, selectedValuesMap],
  );

  const handleClose = useCallback(() => {
    dispatch(
      log({
        event: 'FilterBottomSheet.handleClose',
      }),
    );

    setIsShowSelectedFilters(false);
    onClose();
  }, [dispatch, onClose]);

  useEffect(() => {
    // when we select an attribute
    // we set initially selected local values
    if (attributeMapItem && isVisible) {
      setInitialSelectedValues(attributeMapItem.selectedValues);
      setDisplaySelectedValues(attributeMapItem.selectedValues);
      setSelectedValues(attributeMapItem.selectedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <AnimatedBottomSheet
      isVisible={isVisible}
      onClose={handleClose}
      isFullscreen={isSearchMode}
      maxHeight={isSearchMode ? SEARCH_LIST_MAX_HEIGHT : FILTER_LIST_MAX_HEIGHT}
      minHeight={MIN_HEIGHT}
      renderFooter={renderFooter}
      withMinFixedHeight
    >
      <SafeAreaView>
        <FilterPanelHeader
          onGetSearchList={onGetSearchList}
          searchValue={searchValue}
          setFilterSearchValue={setSearchValue}
          isSelected={isShowSelectedFilters}
          isSearchMode={isSearchMode}
          onClear={handleClearFilters}
          onDone={handleSearchDone}
          onSearch={handleSearch}
          selectedCount={selectedValues?.length}
          onSelectedCountPress={handleShowSelectedFilters}
          selectedAttributeId={selectedAttributeId}
        />

        <S.ContentContainer>
          {isSearchMode ? (
            <SearchList
              onGetSearchList={onGetSearchList}
              isSearchDataLoading={isSearchDataLoading}
              searchData={searchData}
              searchDataError={searchDataError}
              searchDataNext={searchDataNext}
              selectedValueIds={selectedValueIds}
              dataSet={filterDrawerSearchListDataSet}
              selectedValuesMap={displaySelectedValuesMap}
              selectedValues={displaySelectedValues}
              selectedAttributeId={selectedAttributeId}
              renderItem={renderItem}
            />
          ) : (
            <FilterList
              onGetFilterList={onGetFilterList}
              attributeMapItem={attributeMapItem}
              selectedValueIds={selectedValueIds}
              dataSet={filterDrawerListDataSet}
              initialSelectedValues={initialSelectedValues}
              initialSelectedValuesMap={initialSelectedValuesMap}
              displaySelectedValues={displaySelectedValues}
              maxHeight={FILTER_LIST_MAX_HEIGHT - calcHeight(180)}
              selectedAttributeId={selectedAttributeId}
              renderItem={renderItem}
              isShowSelectedFilters={isShowSelectedFilters}
            />
          )}
        </S.ContentContainer>
      </SafeAreaView>
    </AnimatedBottomSheet>
  );
};

const S = {
  Header: styled.View`
    width: 100%;
    height: 60px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  ContentContainer: styled.View`
    height: auto;
    width: 100%;
    padding: 0 ${calcWidth(25)}px ${calcHeight(80)}px;
  `,
  DoneButton: styled(Button)`
    align-self: center;
    bottom: ${calcHeight(20)}px;
    width: ${deviceWidth - calcHeight(50)}px;
    height: ${calcHeight(60)}px;
  `,
};

export default memo(FilterBottomSheet);
