import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getOverviewAttributes } from '../../../../store/attributes/biteAttributes/biteAttributes.slice';
import HeaderTabs from '../../common/HeaderTabs';
import useOverviewAttributesMap from '../hooks/useOverviewAttributesMap';
import useRedirectFromAttributes from '../../hooks/useRedirectFromAttributes';
import { IStackNavigation } from '../../../../navigation/types';
import { ECurrentList } from '../../../../store/analytics/analytics.types';
import { currentListSelector } from '../../../../store/analytics/analytics.selector';
import GuestsAttribute from '../common/GuestsAttribute';
import {
  selectedFilterAttributeValueIdsSelector,
  biteSharedWithFilterSelector,
} from '../../../../store/attributes/biteAttributes/biteAttributes.selector';
import { OVERVIEW_ATTRIBUTES_SCREEN, OVERVIEW_LIST_SCREEN } from '../constants';
import useHeaderTabsDivider from '../../hooks/useHeaderTabsDivider';
import { attributesListDataSet } from '../../analytics.constants';
import { isNeedToScrollUpOverviewSelector } from '../../../../store/attributes/biteStats/biteStats.selector';
import { ESharedWithFilter } from '../../../../types/anayltics';
import BiteAttributesList from '../common/BiteAttributesList';
import { EAnalyticsScreenType } from '../../Analytics.types';

const OverviewAttributes: React.FC<IStackNavigation> = ({ navigation }) => {
  const { attributesMap } = useOverviewAttributesMap();
  const selectedAttributes = useSelector(selectedFilterAttributeValueIdsSelector);
  const [isHideGuestsAttribute, setIsHideGuestsAttribute] = useState(false);
  const [isFocusedMode, setIsFocusedMode] = useState(false);

  const { handleScroll, isShowHeaderBottomDivider } = useHeaderTabsDivider();

  useRedirectFromAttributes({
    attributesMap,
    listScreen: OVERVIEW_LIST_SCREEN,
    navigation,
  });

  const isNeedToScrollUp = useSelector(isNeedToScrollUpOverviewSelector);
  const currentList = useSelector(currentListSelector);
  const sharedWithFilter = useSelector(biteSharedWithFilterSelector);

  const renderListHeaderComponent = useCallback(
    ({ isRefreshing }) => {
      if (
        selectedAttributes.length ||
        isHideGuestsAttribute ||
        (sharedWithFilter.length === 1 && sharedWithFilter.includes(ESharedWithFilter.COMMUNICATION_HUB))
      ) {
        return null;
      }

      return <GuestsAttribute currentTab={EAnalyticsScreenType.OVERVIEW} isRefreshing={isRefreshing} />;
    },
    [isHideGuestsAttribute, selectedAttributes.length, sharedWithFilter],
  );

  useEffect(() => {
    if (currentList === ECurrentList.USERS) {
      navigation.replace(OVERVIEW_LIST_SCREEN);
    }
  }, [currentList, navigation]);

  return (
    <>
      <HeaderTabs
        currentScreen={OVERVIEW_ATTRIBUTES_SCREEN}
        attributesScreen={OVERVIEW_ATTRIBUTES_SCREEN}
        listScreen={OVERVIEW_LIST_SCREEN}
        isShowBottomDivider={isShowHeaderBottomDivider && !isFocusedMode}
      />
      <BiteAttributesList
        dataSet={attributesListDataSet}
        scrollEventThrottle={10}
        onScroll={handleScroll}
        onSelectAttribute={setIsHideGuestsAttribute}
        onSingleAttributeChanged={setIsFocusedMode}
        isNeedToScrollUp={isNeedToScrollUp}
        listType={EAnalyticsScreenType.OVERVIEW}
        attributesMap={attributesMap}
        onLoad={getOverviewAttributes}
        renderListHeaderComponent={renderListHeaderComponent}
        hasMultipleValues
      />
    </>
  );
};

export default memo(OverviewAttributes);
