import { useSelector } from 'react-redux';
import IntroIcon from '../../../../assets/icons/analytics/intro.svg';
// import IntroHalfIcon from '../../../assets/icons/analytics/intro_half.svg';
import QuestionIcon from '../../../../assets/icons/analytics/question.svg';
import QuestionRightIcon from '../../../../assets/icons/analytics/question_answered.svg';
import QuestionWrongIcon from '../../../../assets/icons/analytics/question_cross.svg';
import CommentIcon from '../../../../assets/icons/analytics/comment.svg';
import React, { memo, useMemo } from 'react';
import styled from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import {
  EAnsweredValue,
  IAnalyticsAnswersListItem,
  IAnalyticsCommentsListItem,
  IAnalyticsViewsListItem,
} from '../../../../store/attributes/biteStats/biteStats.types';
import i18n, { TLocale } from '../../../../locale/i18n';
import localeLetters from '../../../../utils/letters';
import { IBiteAnswer } from '../../../../types/biteQuestion';
import { contentIdSelector } from '../../../../store/analytics/analytics.selector';
import { biteItemSelector } from '../../../../store/bite/bite.selectors';
import { EditBiteIconType } from '../../../editBite/EditMain/common/SectionItem';
import { IntroSection } from '../../../../types/bite';
import { getIsWebRtl } from '../../../../locale/i18n';
import {
  commentsIconDataSet,
  introIconDataSet,
  questionIconDataSet,
  questionRightIconDataSet,
  questionWrongIconDataSet,
} from '../../analytics.constants';

interface IOverviewIconsProps {
  activity: IAnalyticsViewsListItem['activity'];
}

const Intro = ({}) => {
  const biteId = useSelector(contentIdSelector);
  const bite = useSelector(biteItemSelector(biteId));
  const introSection = useMemo(
    () => bite?.bite_sections?.find((item) => item.type === EditBiteIconType.INTRO) as IntroSection,
    [bite],
  );

  if (introSection) {
    return (
      <S.IntroIconContainer
        // @ts-ignore
        dataSet={introIconDataSet}
      >
        <IntroIcon />
      </S.IntroIconContainer>
    );
  }

  // if (activity.video_progress > 0) {
  //   if (activity.video_progress === 100) {
  //     return <IntroIcon />;
  //   }
  //   return <IntroHalfIcon />;
  // }

  return <S.EmptyView />;
};

export const OverviewIcons: React.FC<IOverviewIconsProps> = memo(({ activity }) => {
  const isWebRtl = getIsWebRtl();
  if (!activity) {
    return null;
  }

  return (
    <S.IconsContainer isRtl={isWebRtl}>
      <S.IconContainer>
        <Intro />
      </S.IconContainer>
      <S.IconContainer>
        <QuestionIcons answer={activity.answer} />
      </S.IconContainer>
      {activity.commented ? (
        <S.IconContainer dataSet={commentsIconDataSet}>
          <CommentIcon />
        </S.IconContainer>
      ) : (
        <S.IconContainer>
          <S.EmptyView />
        </S.IconContainer>
      )}
    </S.IconsContainer>
  );
});

interface IAnsweredIconsProps {
  activity: IAnalyticsAnswersListItem['activity'];
  sortedChoices: IBiteAnswer[];
}

const QuestionIcons = ({ answer }) => {
  if (answer === EAnsweredValue.ANSWERED) {
    return (
      <S.QuestionIconContainer
        // @ts-ignore
        dataSet={questionIconDataSet}
      >
        <QuestionIcon />
      </S.QuestionIconContainer>
    );
  }

  if (answer === EAnsweredValue.ANSWERED_CORRECT) {
    return (
      <S.QuestionIconContainer
        // @ts-ignore
        dataSet={questionRightIconDataSet}
      >
        <QuestionRightIcon />
      </S.QuestionIconContainer>
    );
  }

  if (answer === EAnsweredValue.ANSWERED_INCORRECT) {
    return (
      <S.QuestionIconContainer
        // @ts-ignore
        dataSet={questionWrongIconDataSet}
      >
        <QuestionWrongIcon />
      </S.QuestionIconContainer>
    );
  }

  return <S.EmptyView />;
};

export const AnsweredIcons: React.FC<IAnsweredIconsProps> = memo(({ activity, sortedChoices }) => {
  const isWebRtl = getIsWebRtl();
  const choicesLetters: string = useMemo(() => {
    if (!sortedChoices || !activity) {
      return null;
    }

    const choicesMap = activity?.choice_ids?.reduce((map, choiceId) => {
      map[choiceId] = true;
      return map;
    }, {});

    const letters = [];
    sortedChoices.forEach((choice, index) => {
      if (choicesMap?.[choice.id]) {
        const letter = localeLetters[i18n.language as TLocale][index];
        letters.push(letter);
      }
    });

    return letters.join(', ');
  }, [activity, sortedChoices]);

  if (!activity) {
    return null;
  }

  return (
    <S.IconsContainer isRtl={isWebRtl}>
      {!!choicesLetters && <S.Letters>{choicesLetters}</S.Letters>}
      <S.IconContainer>
        <QuestionIcons answer={activity.answer} />
      </S.IconContainer>
    </S.IconsContainer>
  );
});

export const ViewIcons: React.FC<IOverviewIconsProps> = memo(({ activity }) => {
  const isWebRtl = getIsWebRtl();

  if (!activity) {
    return null;
  }

  return (
    <S.IconsContainer isRtl={isWebRtl}>
      <S.IconContainer>
        <Intro />
      </S.IconContainer>
    </S.IconsContainer>
  );
});

interface ICommentsIconsProps {
  activity: IAnalyticsCommentsListItem['activity'];
}

export const CommentsIcons: React.FC<ICommentsIconsProps> = memo(({ activity }) => {
  const isWebRtl = getIsWebRtl();

  if (!activity) {
    return null;
  }

  return (
    <S.IconsContainer
      // @ts-ignore
      dataSet={commentsIconDataSet}
      isRtl={isWebRtl}
    >
      {activity?.commented ? <CommentIcon /> : null}
    </S.IconsContainer>
  );
});

const S = {
  QuestionIconContainer: styled.View``,
  IntroIconContainer: styled.View``,
  IconsContainer: styled.View<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
  `,
  IconContainer: styled.View`
    align-items: center;
    height: ${calcHeight(24)}px;
    width: ${calcWidth(24)}px;
    margin-left: ${calcWidth(12)}px;
  `,
  Letters: styled.Text`
    align-items: center;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s13}px;
    font-weight: 700;
  `,
  EmptyView: styled.View`
    width: ${calcWidth(24)}px;
    height: ${calcHeight(24)}px;
  `,
};
