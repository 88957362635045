import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EAnalyticsContentType, ECurrentList, IInitialState } from './analytics.types';
import { cloneDeep } from 'lodash';
import { TExcludeFields } from '../attributes/biteStats/biteStats.types';

export const initialState: IInitialState = {
  isFilterTooltipShown: false,
  isRefreshing: false,
  isFilterBottomSheetOpen: false,
  currentList: ECurrentList.ATTRIBUTES,
  contentId: null,
  contentType: null,
  hasDistributions: null,
};

const ANALYTICS = 'ANALYTICS';

const analyticsSlice = createSlice({
  name: ANALYTICS,
  initialState,
  reducers: {
    prepareAnalytics: (
      state,
      {
        payload: { contentId, contentType },
      }: PayloadAction<{
        contentId: number;
        contentType: EAnalyticsContentType;
      }>,
    ) => {
      return {
        ...initialState,
        contentId,
        contentType,
      };
    },
    setCurrentList: (state, { payload }: PayloadAction<ECurrentList>) => {
      state.currentList = payload;
    },
    setIsRefreshing: (state, { payload }: PayloadAction<boolean>) => {
      state.isRefreshing = payload;
    },
    setFilterBottomSheetOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isFilterBottomSheetOpen = payload;
    },
    setHasDistributions: (state, { payload }: PayloadAction<boolean>) => {
      state.hasDistributions = payload;
    },
    setIsFilterTooltipShown: (state, { payload }: PayloadAction<boolean>) => {
      state.isFilterTooltipShown = payload;
    },
    resetAnalytics: (
      state,
      {
        payload,
      }: PayloadAction<{
        excludeFields: TExcludeFields;
        withFiltersReset?: boolean;
      }>,
    ) => {
      const { excludeFields, withFiltersReset } = payload;
      const exclude = excludeFields?.analytics;

      if (!exclude) {
        return initialState;
      }

      Object.keys(state).forEach((key) => {
        if (excludeFields.analytics.some((field) => typeof field === 'string' && field === key)) {
          return;
        }

        const newState = cloneDeep(initialState[key]);

        if (!withFiltersReset && state[key].filter) {
          newState.filter = state[key].filter;
        }

        state[key] = newState;
      });
    },
  },
});

export const {
  prepareAnalytics,
  setCurrentList,
  setIsRefreshing,
  setFilterBottomSheetOpen,
  setHasDistributions,
  setIsFilterTooltipShown,
  resetAnalytics,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;
