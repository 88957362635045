import React, { useCallback } from 'react';
import styled from 'styled-components';
import defaultTheme from '../../../themes/defaultTheme';
import { Voice, VOICES_LIST } from '../../../utils/voices.constants';
import useAudioPreview from '../../../hooks/useAudioPreview';
import PlayIcon from '../../../assets/icons/videoEditor/text-to-speech-play.svg';
import PauseIcon from '../../../assets/icons/videoEditor/text-to-speech-pause.svg';

type Props = {
  onSelect: (voice: Voice) => void;
};
const SelectVoices = ({ onSelect }: Props) => {
  const { activeAudioId, handlePlayPreview } = useAudioPreview();

  return (
    <>
      <S.EdgeSpacing />
      {VOICES_LIST.map((voice) => (
        <VoiceItem
          isPlaying={voice.id === activeAudioId}
          voice={voice}
          handlePlayPreview={handlePlayPreview}
          onSelect={onSelect}
          key={voice.id}
        />
      ))}
      <S.EdgeSpacing />
    </>
  );
};
export default SelectVoices;

const VoiceItem = ({
  voice,
  isPlaying,
  handlePlayPreview,
  onSelect,
}: {
  voice: Voice;
  isPlaying: boolean;
  handlePlayPreview: (id: string, src: string) => void;
  onSelect: (voice: Voice) => void;
}) => {
  const handleTogglePlayPreview = useCallback(
    (e) => {
      e.stopPropagation();
      handlePlayPreview(voice.id, voice.sample);
    },
    [handlePlayPreview, voice],
  );

  const handleSelect = useCallback(() => {
    onSelect(voice);
  }, [onSelect, voice]);

  return (
    <>
      <S.VoiceContainer onClick={handleSelect}>
        <S.VoiceImageContainer>
          <S.VoiceImage src={voice.avatar} />
          <S.PlayButton onClick={handleTogglePlayPreview}>{isPlaying ? <PauseIcon /> : <PlayIcon />}</S.PlayButton>
        </S.VoiceImageContainer>
        <S.VoiceDescriptionContainer>
          <S.VoiceName>{voice.name}</S.VoiceName>
          <S.VoiceDescription>{voice.description}</S.VoiceDescription>
        </S.VoiceDescriptionContainer>
      </S.VoiceContainer>
    </>
  );
};

const S = {
  EdgeSpacing: styled.div`
    flex-shrink: 0;
    height: 28px;
  `,
  VoiceContainer: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-left: 12px;
    margin-right: 9px;
    padding: 10px 20px;
    background: #fff;
    cursor: pointer;

    &:hover {
      background: #efeff4;
    }
  `,
  VoiceImageContainer: styled.div`
    position: relative;
    width: 53px;
    height: 53px;
    flex-shrink: 0;
  `,
  VoiceImage: styled.img`
    width: 53px;
    height: 53px;
    border-radius: 53px;
  `,
  PlayButton: styled.div`
    position: absolute;
    top: 50%;
    left: -8px;
    width: 30px;
    height: 30px;
  `,
  VoiceDescriptionContainer: styled.div`
    flex: 1;
  `,
  VoiceName: styled.div`
    color: #313235;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  `,
  VoiceDescription: styled.div`
    color: #313235;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  `,
};
