export interface IOrganizationAttributeMap {
  [key: number]: IOrganizationAttributeMapItem;
}

export interface IOrganizationAttributeMapItem {
  isLoading: boolean;
  error?: string;
  data: IOrganizationAttribute;
  selectedValues?: IOrganizationAttributeValue[];
  next?: string;
}

export interface IOrganizationAttribute {
  title: string;
  index: number;
  id: number;
  organization_id: number;
  values: IOrganizationAttributeValue[];
}

export interface IOrganizationAttributeValue {
  id: number;
  name: string;
  attribute_title_id: number;
}

export type TDisplayMode = 'absolute' | 'percentage';

export enum ESortingDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum ESharedWithFilter {
  COMMUNICATION_HUB = 'communication_hub',
  ORGANICALLY = 'organically',
}
