import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { prepareAnalytics, resetAnalytics } from '../../analytics/analytics.slice';
import { EAnalyticsScreenType, IGetAttributesPayload } from '../../../screens/analytics/Analytics.types';
import { TExcludeFields } from '../biteStats/biteStats.types';
import {
  ESharedWithFilter,
  ESortingDirection,
  IOrganizationAttribute,
  IOrganizationAttributeValue,
  TDisplayMode,
} from '../../../types/anayltics';
import {
  ESortByType,
  IInitialState,
  IPlaylistAttributeMap,
  IPlaylistAttributeMapItem,
  PlaylistAttributeValue,
  PlaylistStats,
} from './playlistAttributes.types';
import { IGetAttributesAction } from '../../org/org.types';

export const initialState: IInitialState = {
  displayMode: 'percentage',
  attributesMapById: {
    sortBy: ESortByType.STARTED,
    sortDirection: ESortingDirection.DESC,
    absolute: null,
    percentage: null,
  },
  isGuestsAttributesLoaded: false,
  selectedValueIds: [],
  filterAttributesMapById: null,
  filterSearchValue: '',
  sharedWithFilter: [],

  searchValues: {
    isLoading: false,
    error: null,
    data: null,
    next: null,
  },

  initialAttributesMapById: null,
  organizationAttributes: {
    isLoading: false,
    error: null,
    data: null,
  },

  guestsAttribute: {
    isLoading: false,
    error: null,
    data: null,
  },
};

const PLAYLIST_ATTRIBUTES = 'PLAYLIST_ATTRIBUTES';

export const getPlaylistGuestsAttribute = createAction<{ currentTab: EAnalyticsScreenType }>(
  `${PLAYLIST_ATTRIBUTES}/getGuestsAttribute`,
);
export const getPlaylistAttributes = createAction<IGetAttributesPayload>(
  `${PLAYLIST_ATTRIBUTES}/getOverviewAttributes`,
);

const playlistAttributesSlice = createSlice({
  name: PLAYLIST_ATTRIBUTES,
  initialState,
  reducers: {
    setDisplayMode: (state, { payload }: PayloadAction<TDisplayMode>) => {
      state.displayMode = payload;
    },
    setFilterSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.filterSearchValue = payload;
    },
    getPlaylistOrganizationAttributes: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { payload }: PayloadAction<IGetAttributesAction>,
    ) => {
      state.organizationAttributes.isLoading = true;
      state.organizationAttributes.error = null;
    },
    getFilterList: (
      state,
      { payload }: PayloadAction<{ attributeId: number; reset?: boolean; callback?: () => void }>,
    ) => {
      state.filterAttributesMapById[payload.attributeId].isLoading = true;
      state.filterAttributesMapById[payload.attributeId].error = null;
    },
    setAttributeLoading: (state, { payload }: PayloadAction<{ attributeId: number }>) => {
      const { attributeId } = payload;
      const displayMode = state.displayMode;

      state.attributesMapById[displayMode] = {
        ...state.attributesMapById[displayMode],
        [attributeId]: {
          ...state.attributesMapById[displayMode][attributeId],
          isLoading: true,
          error: null,
        },
      };
    },

    setFilterList: (
      state,
      {
        payload,
      }: PayloadAction<{ attributeId: number; values: IOrganizationAttributeValue[]; next?: string; reset?: boolean }>,
    ) => {
      const { attributeId, reset, values } = payload;
      if (state.filterAttributesMapById[attributeId]?.next && !reset) {
        state.filterAttributesMapById[attributeId].data.values =
          state.filterAttributesMapById[attributeId].data.values.concat(values);
      } else {
        state.filterAttributesMapById[attributeId].data.values = values;
      }

      state.filterAttributesMapById[payload.attributeId].isLoading = false;
      state.filterAttributesMapById[payload.attributeId].error = null;
      state.filterAttributesMapById[payload.attributeId].next = payload.next;
    },
    setFilterListError: (state, { payload }: PayloadAction<{ attributeId: number; error: string }>) => {
      const { attributeId, error } = payload;
      state.filterAttributesMapById[attributeId].isLoading = false;
      state.filterAttributesMapById[attributeId].error = error;
    },
    setPlaylistOrganizationAttributes: (
      state,
      { payload }: PayloadAction<{ attributes: IOrganizationAttribute[] }>,
    ) => {
      const { attributes } = payload;
      state.organizationAttributes.isLoading = false;
      state.organizationAttributes.error = null;
      state.organizationAttributes.data = attributes.map((attr) => ({
        ...attr,
        values: null,
      }));

      const map = {};
      const filterMap = {};
      const initialMap = {};

      attributes.forEach((attribute: IOrganizationAttribute) => {
        map[attribute.id] = {
          isLoading: false,
          error: null,
          data: {
            ...attribute,
            values: null,
          },
          next: null,
        };

        filterMap[attribute.id] = {
          isLoading: false,
          error: null,
          data: attribute,
          selectedValues: state.filterAttributesMapById?.[attribute.id]?.selectedValues || [],
          next: null,
        };

        initialMap[attribute.id] = {
          isLoading: false,
          error: null,
          data: {
            ...attribute,
            values: attribute.values.slice(0, 1),
          },
          next: null,
        };
      });

      state.initialAttributesMapById = initialMap;
      state.filterAttributesMapById = filterMap;

      setAllAttributesMaps(state, map);
    },
    setAttribute: (
      state,
      {
        payload,
      }: PayloadAction<{ attributeId: number; values: PlaylistAttributeValue[]; next?: string; reset?: boolean }>,
    ) => {
      const { attributeId, reset, values, next } = payload;
      const displayMode = state.displayMode;

      if (state.attributesMapById[displayMode][attributeId]?.next && !reset) {
        state.attributesMapById[displayMode][attributeId].data.values =
          state.attributesMapById[displayMode][attributeId].data.values.concat(values);
      } else {
        state.attributesMapById[displayMode][attributeId].data.values = values || [];
        updateOrganizationAttributes(state, attributeId, values);
      }

      state.attributesMapById[displayMode][attributeId].isLoading = false;
      state.attributesMapById[displayMode][attributeId].error = null;
      state.attributesMapById[displayMode][attributeId].next = next;
    },
    setAttributeError: (state, { payload }: PayloadAction<{ attributeId: number; error: string; reset?: boolean }>) => {
      const { attributeId, error, reset } = payload;
      const displayMode = state.displayMode;
      state.attributesMapById[displayMode][attributeId].isLoading = false;
      state.attributesMapById[displayMode][attributeId].error = error;
      if (reset) {
        state.organizationAttributes.data.find((attr) => attr.id === attributeId).values = [];
        state.attributesMapById[displayMode][attributeId].data.values = [];
      }
    },
    setSelectedFilterAttributeValues: (
      state,
      { payload }: PayloadAction<{ attributeId: number; values: IOrganizationAttributeValue[] }>,
    ) => {
      const { attributeId, values } = payload;

      state.filterAttributesMapById[attributeId].selectedValues = values;

      let selectedValueIds = [];
      Object.keys(state.filterAttributesMapById).forEach((id) => {
        const attribute = { ...state.filterAttributesMapById[id] };

        if (attribute.id === attributeId) {
          selectedValueIds.push(...values.map((value: IOrganizationAttributeValue) => value.id));
          return;
        }

        selectedValueIds.push(...attribute.selectedValues.map((value: IOrganizationAttributeValue) => value.id));
      });
      state.selectedValueIds = selectedValueIds;

      Object.keys(state.filterAttributesMapById).forEach((id) => {
        if (Number(id) === attributeId) {
          return;
        }

        state.filterAttributesMapById[id].data.values = [];
      });
    },

    getSearchList: (
      state,
      {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        payload,
      }: PayloadAction<{ attributeId: number; reset?: boolean; withDebounce?: boolean; callback?: () => void }>,
    ) => {
      state.searchValues.isLoading = true;
      state.searchValues.error = null;
    },
    setSearchList: (state, { payload }: PayloadAction<{ data: any; next?: string; reset?: boolean }>) => {
      const { reset, data, next } = payload;
      const currentAttribute = data[0];

      if (state.searchValues.next && !reset) {
        state.searchValues.data = state.searchValues.data.concat(currentAttribute.values);
      } else {
        state.searchValues.data = currentAttribute.values;
      }

      state.searchValues.isLoading = false;
      state.searchValues.error = null;
      state.searchValues.next = next;
    },
    setSearchListError: (state, { payload }: PayloadAction<{ error: string; reset?: boolean }>) => {
      state.searchValues.isLoading = false;
      state.searchValues.error = payload.error;
      if (payload.reset) {
        state.searchValues.data = [];
        state.searchValues.next = null;
      }
    },
    setPlaylistOrganizationAttributesError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.organizationAttributes.isLoading = false;
      state.organizationAttributes.error = payload.error;
    },
    setGuestsAttributeLoading: (state) => {
      state.guestsAttribute.isLoading = true;
      state.guestsAttribute.error = null;
    },
    setGuestsAttributeError: (state, { payload: { error } }: PayloadAction<{ error: string }>) => {
      state.guestsAttribute.isLoading = false;
      state.guestsAttribute.error = error;
      state.guestsAttribute.data = null;
    },
    setGuestsAttribute: (state, { payload: { attribute } }: PayloadAction<{ attribute: PlaylistStats }>) => {
      state.isGuestsAttributesLoaded = true;

      state.guestsAttribute.isLoading = false;
      state.guestsAttribute.error = null;
      state.guestsAttribute.data = attribute;
    },
    setSortBy: (
      state,
      {
        payload: { sortBy, sortDirection, withoutReset },
      }: PayloadAction<{
        sortBy?: ESortByType;
        sortDirection: ESortingDirection;
        withoutReset?: boolean;
      }>,
    ) => {
      const map = state.attributesMapById;
      map.sortBy = sortBy;
      map.sortDirection = sortDirection;

      if (!withoutReset) {
        state.attributesMapById = {
          ...map,
          absolute: getCleanedPlaylistAttributesMap(state.attributesMapById.absolute),
          percentage: getCleanedPlaylistAttributesMap(state.attributesMapById.percentage),
        };
      }
    },
    setSharedWithFilter: (state, { payload }: PayloadAction<ESharedWithFilter[]>) => {
      state.sharedWithFilter = payload;
    },
    resetSearchValues: (state) => {
      state.filterSearchValue = initialState.filterSearchValue;
      state.searchValues = initialState.searchValues;
    },
  },
  extraReducers: {
    [resetAnalytics.type]: (state, { payload }) => {
      const { withFiltersReset, excludeFields } = payload;
      const exclude = excludeFields?.playlistAttributes;

      const map = state.initialAttributesMapById;

      if (!map) {
        return state;
      }

      if (withFiltersReset) {
        const filtersMap = map
          ? Object.keys(map).reduce((acc, attributeId) => {
              const attribute = map[attributeId];
              acc[attributeId] = {
                ...attribute,
                selectedValues: withFiltersReset
                  ? []
                  : state.filterAttributesMapById[attribute.id].selectedValues || [],
              };

              return acc;
            }, {})
          : {};
        state.selectedValueIds = [];
        state.filterAttributesMapById = filtersMap;

        if (!exclude?.includes('sharedWithFilter')) {
          state.sharedWithFilter = initialState.sharedWithFilter;
        }
      }

      if (!exclude?.includes('guestsAttribute')) {
        state.isGuestsAttributesLoaded = false;
      }
      setAllAttributesMaps(state, map, exclude);
    },
    [prepareAnalytics.type]: () => {
      return initialState;
    },
  },
});

const updateOrganizationAttributes = (state: IInitialState, attributeId: number, values: PlaylistAttributeValue[]) => {
  // Checks against other (1 level) filters must be added here, once implemented
  // ie. active users only, etc.
  if (state.selectedValueIds.length === 0 && state.sharedWithFilter.length === 0) {
    state.initialAttributesMapById[attributeId].data.values = values.slice(0, 1);
  }

  state.organizationAttributes = {
    ...state.organizationAttributes,
    data: state.organizationAttributes.data.map((attr: IOrganizationAttribute) => {
      if (attr.id === attributeId) {
        return {
          ...attr,
          values: values.slice(0, 1),
        };
      }

      return attr;
    }),
  };
};

const setAllAttributesMaps = (
  state: IInitialState,
  map: IPlaylistAttributeMap,
  excludeFields?: TExcludeFields['playlistAttributes'],
) => {
  const cleanedMap = getCleanedPlaylistAttributesMap(map);

  const updatedState = {
    ...state.attributesMapById,
    absolute: cloneDeep(cleanedMap),
    percentage: cloneDeep(cleanedMap),
  };

  if (excludeFields) {
    excludeFields.forEach((field) => {
      if (typeof field === 'string') {
        return;
      }

      field.attributeIds.forEach((attributeId) => {
        updatedState[field.field][state.displayMode][attributeId] = state[field.field][state.displayMode][attributeId];
      });
    });
  }

  state.attributesMapById = updatedState;
};

const getCleanedPlaylistAttributesMap = (map: IPlaylistAttributeMap) => {
  return Object.values(map).reduce((acc, attribute: IPlaylistAttributeMapItem) => {
    acc[attribute.data.id] = {
      ...attribute,
      data: {
        ...attribute.data,
        values: null,
      },
    };

    return acc;
  }, {});
};

export const {
  setDisplayMode,
  setAttribute,
  setAttributeError,
  setAttributeLoading,
  getPlaylistOrganizationAttributes,
  getFilterList,
  setPlaylistOrganizationAttributes,
  setPlaylistOrganizationAttributesError,
  setSelectedFilterAttributeValues,
  setFilterList,
  setFilterListError,
  setFilterSearchValue,
  getSearchList,
  setSearchList,
  setSearchListError,
  setGuestsAttributeLoading,
  setGuestsAttribute,
  setGuestsAttributeError,
  setSortBy,
  setSharedWithFilter,
  resetSearchValues,
} = playlistAttributesSlice.actions;

export default playlistAttributesSlice.reducer;
