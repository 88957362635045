import React, { useCallback, useState } from 'react';
import { attributeValueItemDataSet, viewAllButtonDataSet } from '../../analytics.constants';
import PlaylistAttributeValueItem from './PlaylistAttributeValueItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  playlistDisplayModeSelector,
  selectedFilterAttributeValueIdsSelector,
} from '../../../../store/attributes/playlistAttributes/playlistAttributes.selector';
import ViewAllButton from '../../common/AttributesList/ViewAllButton';
import useAttributesData from '../../hooks/useAttributesData';
import { log } from '../../../../store/appActivity/appActivity.slice';
import AttributesList, { ISection } from '../../common/AttributesList/AttributesList';
import { SectionList, SectionListProps, ViewStyle } from 'react-native';
import { IPlaylistAttributeMap } from '../../../../store/attributes/playlistAttributes/playlistAttributes.types';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { EAnalyticsScreenType, IGetAttributesPayload } from '../../Analytics.types';
import { AnimatedStyleProp } from 'react-native-reanimated';
import { setIsNeedToScrollUp } from '../../../../store/attributes/playlistStats/playlistStats.slice';

interface IProps extends Partial<SectionListProps<SectionList>> {
  attributesMap: IPlaylistAttributeMap;
  onSelectAttribute?: (state: boolean) => void;
  onLoad: ActionCreatorWithOptionalPayload<IGetAttributesPayload, string>;
  hasMultipleValues?: boolean;
  dataSet?: Record<string, string>;
  onSingleAttributeChanged?: (state: boolean) => void;
  isNeedToScrollUp?: boolean;
  listType?: EAnalyticsScreenType;
  renderListHeaderComponent?: ({ isRefreshing }: { isRefreshing: boolean }) => React.ReactElement;
  stickyHeaderStyles?: AnimatedStyleProp<ViewStyle>;
}

const PlaylistAttributesList: React.FC<IProps> = ({
  attributesMap,
  onSelectAttribute,
  onLoad,
  hasMultipleValues,
  ...props
}) => {
  const dispatch = useDispatch();
  const displayMode = useSelector(playlistDisplayModeSelector);

  const selectedValueIds = useSelector(selectedFilterAttributeValueIdsSelector);

  const [manuallySelectedSectionId, setManuallySelectedSectionId] = useState<number>(null);

  const { attributesToRender, singleAttributeId } = useAttributesData({
    attributesMap,
    onLoad,
    manuallySelectedSectionId,
  });

  const handleSetIsNeedToScrollUp = useCallback(
    ({ status }) => {
      dispatch(setIsNeedToScrollUp(status));
    },
    [dispatch],
  );

  const handleLoad = useCallback(
    ({
      attributeIds,
      isPullToRefresh,
      reset,
      callback,
    }: {
      attributeIds: number[];
      isPullToRefresh?: boolean;
      reset?: boolean;
      callback?: () => void;
    }) => {
      dispatch(onLoad({ attributeIds, clearAnalyticsCache: isPullToRefresh, callback, reset }));
    },
    [dispatch, onLoad],
  );

  const handleSelectSection = useCallback(
    (section: ISection) => {
      dispatch(
        log({
          event: 'AttributesList.handleSelectSection',
          data: { section, manuallySelectedSectionId },
        }),
      );

      if (section.id === manuallySelectedSectionId) {
        onSelectAttribute?.(false);
        setManuallySelectedSectionId(null);
        return;
      }

      onSelectAttribute?.(true);
      setManuallySelectedSectionId(section.id);

      if (!section.next || section.isError) {
        return;
      }

      handleLoad({ attributeIds: [section.id] });
    },
    [dispatch, manuallySelectedSectionId, onSelectAttribute, handleLoad],
  );

  const renderItem = useCallback(
    ({ section, item, index }) => {
      if (index === 5 && !singleAttributeId) {
        return (
          <ViewAllButton
            // @ts-ignore
            dataSet={viewAllButtonDataSet}
            section={section}
            onPress={handleSelectSection}
          />
        );
      }

      return (
        <PlaylistAttributeValueItem
          // @ts-ignore
          dataSet={attributeValueItemDataSet}
          displayMode={displayMode}
          item={item}
          withDivider={index < section.data.length - 1}
          hasMultipleValues={hasMultipleValues}
        />
      );
    },
    [displayMode, handleSelectSection, hasMultipleValues, singleAttributeId],
  );

  return (
    <AttributesList
      attributesMap={attributesMap}
      attributesToRender={attributesToRender}
      onSelectAttribute={onSelectAttribute}
      renderItem={renderItem}
      onLoad={handleLoad}
      singleAttributeId={singleAttributeId}
      onSelectSection={handleSelectSection}
      manuallySelectedSectionId={manuallySelectedSectionId}
      selectedValueIds={selectedValueIds}
      onSetIsNeedToScrollUp={handleSetIsNeedToScrollUp}
      {...props}
    />
  );
};

export default PlaylistAttributesList;
