import React, { useCallback } from 'react';
import { ActivityIndicator, StyleProp, ViewStyle } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { css } from 'styled-components/native';
import AddIcon from '../../../../assets/icons/creationFlow/add-icon.svg';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import ShadowedContainer from '../../../ShadowedContainer';

export interface IActionButtonProps {
  style?: StyleProp<ViewStyle>;
  height?: number;
  icon?: EIcon;
  text: string;
  isShadowed?: boolean;
  isBoldText?: boolean;
  textColor: string;
  border?: string;
  fill: string;
  disabled?: boolean;
  onPress: () => void;
  isLoading?: boolean;
  dataSet?: Record<string, string>;
}

export enum EIcon {
  ADD,
}

const OFFSET: [x: string | number, y: string | number] = [0, 0];

const Button: React.FC<IActionButtonProps> = ({
  style,
  height = 45,
  icon,
  border,
  fill,
  isShadowed = false,
  onPress,
  text,
  isBoldText = false,
  textColor,
  disabled,
  isLoading,
  dataSet,
}) => {
  const theme = useTheme();

  const renderButton = useCallback(
    () => (
      <S.Button
        onPress={onPress}
        style={style}
        border={border}
        fill={fill}
        icon={icon}
        disabled={disabled || isLoading}
        // @ts-ignore
        dataSet={dataSet}
      >
        {icon === EIcon.ADD ? <AddIcon /> : null}
        {isLoading ? (
          <S.LoaderWrapper>
            <ActivityIndicator color={theme.colors.black} />
          </S.LoaderWrapper>
        ) : null}
        <S.Text icon={icon} isBoldText={isBoldText} textColor={textColor} disabled={disabled}>
          {text}
        </S.Text>
      </S.Button>
    ),
    [border, dataSet, disabled, fill, icon, isLoading, isBoldText, onPress, style, text, textColor, theme.colors.black],
  );

  if (isShadowed) {
    return (
      <ShadowedContainer offset={OFFSET} distance={30}>
        {renderButton()}
      </ShadowedContainer>
    );
  }

  return <S.Container height={height}>{renderButton()}</S.Container>;
};

const S = {
  Button: styled.TouchableOpacity<{ width?: number; border?: string; fill?: string; icon?: EIcon; disabled: boolean }>`
    flex-direction: row;
    border-radius: ${calcHeight(50 / 2)}px;
    ${({ disabled, border, icon }) => css`
      ${icon === EIcon.ADD ? `padding: 5px ${calcWidth(14)}px;` : ''}
      ${disabled ? 'border: 1px solid #D8D8D8;' : border ? `border: 1px solid ${border};` : ''}
    `}
    align-self: center;
    align-items: center;
    justify-content: center;
    background: ${({ disabled, theme, fill }) => (disabled ? theme.colors.white : fill ? fill : theme.colors.white)};
  `,
  Container: styled.View<{ height: number }>`
    height: ${({ height }) => calcHeight(height)}px;
  `,
  Text: styled.Text<{ disabled: boolean; icon: EIcon; fill: string; isBoldText?: boolean; textColor: string }>`
    line-height: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme, disabled, textColor }) => (disabled ? '#7F8186' : textColor || theme.colors.primaryBlue)};
    font-size: ${({ theme }) => theme.fontSizes.s16};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    ${({ icon, isBoldText }) =>
      css`
        ${icon === EIcon.ADD ? `margin-left: ${calcWidth(10)}px;` : ''}
        ${isBoldText ? 'font-weight: bold;' : ''}
      `}
  `,
  LoaderWrapper: styled.View`
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.colors.lightGray35};
    border-radius: ${calcHeight(50 / 2)}px;
    z-index: 2;
  `,
};

export default Button;
