import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, isWeb } from '../../../../utils/dimensions';
import { ActivityIndicator, RefreshControl, SectionList, SectionListProps, ViewStyle } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import SectionHeader from './SectionHeader';
import { log } from '../../../../store/appActivity/appActivity.slice';
import { setIsRefreshing as setIsRefreshingGlobal } from '../../../../store/analytics/analytics.slice';
import i18n, { isRtlByLang } from '../../../../locale/i18n';
import { useIsFocused } from '@react-navigation/native';
import Animated, { AnimatedStyleProp } from 'react-native-reanimated';
import {
  attributesListErrorFooterDataSet,
  attributesListLoadingFooterDataSet,
  refreshButtonDataSet,
} from '../../analytics.constants';
import { IAttributeToRender } from '../../hooks/useAttributesData';
import { AnalyticsAttributeMap, AnalyticsAttributeValue, EAnalyticsScreenType } from '../../Analytics.types';

interface IProps extends Partial<SectionListProps<SectionList>> {
  attributesMap: AnalyticsAttributeMap;
  onLoad: (payload: {
    attributeIds: number[];
    isPullToRefresh?: boolean;
    reset?: boolean;
    callback?: () => void;
  }) => void;
  attributesToRender: IAttributeToRender[];
  singleAttributeId: number;
  manuallySelectedSectionId: number;
  onSelectSection: (section: ISection) => void;
  selectedValueIds: number[];
  dataSet?: Record<string, string>;
  listType?: EAnalyticsScreenType;
  isNeedToScrollUp?: boolean;
  onSelectAttribute?: (state: boolean) => void;
  onSingleAttributeChanged?: (state: boolean) => void;
  stickyHeaderStyles?: AnimatedStyleProp<ViewStyle>;
  renderListHeaderComponent?: ({ isRefreshing }: { isRefreshing: boolean }) => React.ReactElement;
  onSetIsNeedToScrollUp: (data: { listType: EAnalyticsScreenType; status: boolean }) => void;
}

export interface ISection {
  id?: number;
  title: string;
  data: AnalyticsAttributeValue[];
  isLoading: boolean;
  isError: boolean;
  next?: string;
  isDisplaySkeleton: boolean;
}

const AttributesList: React.FC<IProps> = ({
  attributesMap,
  attributesToRender,
  singleAttributeId,
  manuallySelectedSectionId,
  onLoad,
  renderListHeaderComponent,
  listType,
  isNeedToScrollUp,
  onSelectSection,
  stickyHeaderStyles,
  onSingleAttributeChanged,
  renderItem,
  dataSet,
  selectedValueIds,
  onSetIsNeedToScrollUp,
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isFocused = useIsFocused();

  const sectionListRef = useRef(null);

  const [isRefreshing, setIsRefreshing] = useState(false);

  const keyExtractor = useCallback((item, index) => `${item.id}-${index}`, []);

  const handleRefresh = useCallback(() => {
    dispatch(
      log({
        event: 'AttributesList.handleRefresh',
      }),
    );

    setIsRefreshing(true);
    dispatch(setIsRefreshingGlobal(true));

    onLoad({
      attributeIds: attributesToRender.map((attribute) => attribute.id),
      isPullToRefresh: true,
      callback: () => setIsRefreshing(false),
      reset: true,
    });
  }, [attributesToRender, dispatch, onLoad]);

  const handleErrorRefresh = useCallback(() => {
    dispatch(
      log({
        event: 'AttributesList.handleErrorRefresh',
        data: { singleAttributeId },
      }),
    );

    onLoad({ attributeIds: [singleAttributeId] });
  }, [dispatch, singleAttributeId, onLoad]);

  const handleEndReached = useCallback(() => {
    dispatch(
      log({
        event: 'AttributesList.handleErrorRefresh',
        data: { singleAttributeId },
      }),
    );

    onLoad({ attributeIds: [singleAttributeId] });
  }, [dispatch, singleAttributeId, onLoad]);

  const handleEndReachedProp = useMemo(() => {
    if (
      !singleAttributeId ||
      !attributesMap[singleAttributeId].next ||
      attributesMap[singleAttributeId].isLoading ||
      attributesMap[singleAttributeId].error
    ) {
      return;
    }

    return handleEndReached;
  }, [attributesMap, handleEndReached, singleAttributeId]);

  const scrollToTop = useCallback(({ animated }) => {
    if (sectionListRef.current) {
      const scrollResponder = sectionListRef.current.getScrollResponder();
      scrollResponder?.scrollTo({ x: 0, y: 0, animated });
    }
  }, []);

  const handleContentSizeChange = useCallback(
    (contentWidth, contentHeight) => {
      if (
        !singleAttributeId ||
        !attributesMap[singleAttributeId].next ||
        attributesMap[singleAttributeId].error ||
        attributesMap[singleAttributeId].isLoading ||
        contentHeight > window.innerHeight
      ) {
        return;
      }

      onLoad({ attributeIds: [singleAttributeId] });
    },
    [attributesMap, singleAttributeId, onLoad],
  );

  const renderSectionHeader = useCallback(
    ({ section }) => (
      <>
        <SectionHeader
          isSelected={singleAttributeId === section.id}
          section={section}
          onSelectSection={onSelectSection}
          onRefresh={onLoad}
          hasOnlyOneAttribute={singleAttributeId && !manuallySelectedSectionId}
        />
      </>
    ),
    [singleAttributeId, onSelectSection, onLoad, manuallySelectedSectionId],
  );

  const renderFooterContainer = useCallback(() => {
    if (!singleAttributeId) {
      return null;
    }

    if (
      attributesMap[singleAttributeId].isLoading &&
      !isRefreshing &&
      attributesMap[singleAttributeId].data.values?.length
    ) {
      return (
        <S.FooterContainer
          // @ts-ignore
          dataSet={attributesListLoadingFooterDataSet}
        >
          <ActivityIndicator size='large' />
        </S.FooterContainer>
      );
    }

    if (attributesMap[singleAttributeId].error) {
      return (
        <S.ErrorContainer
          // @ts-ignore
          dataSet={attributesListErrorFooterDataSet}
        >
          <S.ErrorText>{t('common.somethingWentWrong')}</S.ErrorText>
          <S.RefreshButton
            // @ts-ignore
            dataSet={refreshButtonDataSet}
            onPress={handleErrorRefresh}
          >
            <S.RefreshText>{t('analytics.error.refresh')}</S.RefreshText>
          </S.RefreshButton>
        </S.ErrorContainer>
      );
    }

    return null;
  }, [attributesMap, handleErrorRefresh, isRefreshing, singleAttributeId, t]);

  useEffect(() => {
    scrollToTop({ animated: isFocused });
  }, [isFocused, scrollToTop, selectedValueIds]);

  useEffect(() => {
    if (isFocused && isNeedToScrollUp) {
      scrollToTop({ animated: true });
      onSetIsNeedToScrollUp({ listType, status: false });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedToScrollUp]);

  useEffect(() => {
    onSingleAttributeChanged?.(!!singleAttributeId);
  }, [onSingleAttributeChanged, singleAttributeId]);

  const ListHeaderComponent = useMemo(() => {
    if (renderListHeaderComponent) {
      return renderListHeaderComponent({ isRefreshing });
    }

    return undefined;
  }, [isRefreshing, renderListHeaderComponent]);

  if (attributesToRender.length === 0) {
    return null;
  }

  return (
    <>
      {stickyHeaderStyles && !!singleAttributeId && (
        <Animated.View style={stickyHeaderStyles}>
          <SectionHeader
            isSelected
            section={attributesToRender[0]}
            onSelectSection={onSelectSection}
            hasOnlyOneAttribute={singleAttributeId && !manuallySelectedSectionId}
            isSticky
          />
        </Animated.View>
      )}
      <S.SectionList
        // @ts-ignore
        dataSet={dataSet}
        ref={sectionListRef}
        keyExtractor={keyExtractor}
        sections={attributesToRender}
        stickySectionHeadersEnabled={!!singleAttributeId && !stickyHeaderStyles}
        renderItem={renderItem}
        refreshControl={
          <RefreshControl refreshing={isRefreshing} onRefresh={handleRefresh} tintColor={theme.colors.gray19} />
        }
        renderSectionHeader={renderSectionHeader}
        onEndReachedThreshold={isWeb ? 0.001 : 0.1}
        onEndReached={handleEndReachedProp}
        ListHeaderComponent={ListHeaderComponent}
        ListFooterComponent={renderFooterContainer}
        onContentSizeChange={isWeb && handleContentSizeChange}
        {...props}
      />
    </>
  );
};

const AnimatedSectionList = Animated.createAnimatedComponent(SectionList);

const S = {
  SectionList: styled(AnimatedSectionList)`
    height: 100%;
    width: 100%;
  `,
  ViewAllContainer: styled.View<{ isRtl: boolean }>`
    margin: ${calcHeight(16)}px ${calcHeight(19)}px;
    align-items: ${isRtlByLang[i18n.language] ? 'flex-start' : 'flex-end'};
  `,
  ViewAllButton: styled.TouchableOpacity``,
  ViewAllText: styled.Text`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
  `,

  FooterContainer: styled.View`
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: ${calcHeight(20)}px;
  `,
  ErrorContainer: styled.View`
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: ${calcHeight(20)}px 0;
  `,
  ErrorText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.text};
  `,

  RefreshButton: styled.TouchableOpacity`
    margin-top: 10px;
  `,
  RefreshText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
};

export default memo(AttributesList);
