import { useCallback, useState } from 'react';
import { log, logError } from '../store/appActivity/appActivity.slice';
import { useDispatch } from 'react-redux';
import withRetry from '../utils/withRetry';
import { v4 as uuid } from 'uuid';
import { getErrorLogData } from '../utils/getErrorLogData';

type Props = {
  action?: string;
  retries?: number;
};
export const useAsyncAction = (fn: (...props: any[]) => Promise<any>, options?: Props) => {
  const { action = 'useAsyncAction', retries = 3 } = options || {};

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [result, setResult] = useState<any>(null);

  const run = useCallback(
    async (...props: any[]) => {
      const processId = uuid();

      try {
        if (isLoading) {
          return;
        }

        dispatch(
          log({
            event: `${action}: start`,
            processId,
            data: {
              ...props,
            },
          }),
        );

        setIsLoading(true);
        setError(null);
        setResult(null);

        const newResult = await withRetry(() => fn(...props), {
          errorContext: {
            data: {
              action,
            },
          },
          maxAttempts: retries,
        });

        setResult(newResult);

        dispatch(
          log({
            event: `${action}: done`,
            processId,
            data: {
              ...newResult,
            },
          }),
        );
      } catch (_error: any) {
        dispatch(
          logError({
            event: `${action}: error`,
            processId,
            data: {
              error: getErrorLogData(_error),
            },
          }),
        );

        setError(_error);
      } finally {
        setIsLoading(false);
      }
    },
    [action, fn, dispatch, isLoading, retries],
  );

  return {
    isLoading,
    error,
    result,
    run,
  };
};
