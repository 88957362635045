import { useSelector } from 'react-redux';
import {
  biteDisplayModeSelector,
  overviewAttributesAbsoluteMapSelector,
  overviewAttributesPercentageMapSelector,
} from '../../../../store/attributes/biteAttributes/biteAttributes.selector';
import { useMemo } from 'react';

const useOverviewAttributesMap = () => {
  const displayMode = useSelector(biteDisplayModeSelector);
  const overviewAttributesAbsoluteMap = useSelector(overviewAttributesAbsoluteMapSelector);
  const overviewAttributesPercentageMap = useSelector(overviewAttributesPercentageMapSelector);

  const attributesMap = useMemo(
    () => (displayMode === 'absolute' ? overviewAttributesAbsoluteMap : overviewAttributesPercentageMap),
    [displayMode, overviewAttributesAbsoluteMap, overviewAttributesPercentageMap],
  );

  return {
    attributesMap,
  };
};

export default useOverviewAttributesMap;
