import { useSelector } from 'react-redux';
import {
  commentsAttributesAbsoluteMapSelector,
  commentsAttributesPercentageMapSelector,
  biteDisplayModeSelector,
} from '../../../../store/attributes/biteAttributes/biteAttributes.selector';
import { useMemo } from 'react';

const useCommentsAttributesMap = () => {
  const displayMode = useSelector(biteDisplayModeSelector);
  const commentsAttributesAbsoluteMap = useSelector(commentsAttributesAbsoluteMapSelector);
  const commentsAttributesPercentageMap = useSelector(commentsAttributesPercentageMapSelector);

  const attributesMap = useMemo(
    () => (displayMode === 'absolute' ? commentsAttributesAbsoluteMap : commentsAttributesPercentageMap),
    [displayMode, commentsAttributesAbsoluteMap, commentsAttributesPercentageMap],
  );
  return {
    attributesMap,
  };
};

export default useCommentsAttributesMap;
