import { ICloudAsset } from '../../../../store/cloudAssets/cloudAssets.types';

export const getTranscriptionCombinedText = (cloudAsset: ICloudAsset) => {
  const transcription = cloudAsset?.fileMeta?.transcription;

  if (!transcription) {
    return '';
  }

  let text = '';

  for (let i = 0; i < transcription.texts.length; i++) {
    const nextText = transcription.texts[i];

    const t = transcription.texts[i];
    text += t.text;

    if (nextText?.type === undefined || nextText?.type === 'pronunciation') {
      text += ' ';
    }
  }

  return text.replace(/ {2,}/g, ' ');
};
