import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n, { getIsWebRtl, isRtlByLang } from '../../../../locale/i18n';
import { log } from '../../../../store/appActivity/appActivity.slice';
import styled from 'styled-components/native';
import { calcHeight } from '../../../../utils/dimensions';
import { ISection } from './AttributesList';

interface IViewAllButtonProps {
  onPress: (section: ISection) => void;
  section: ISection;
  dataSet?: Record<string, string>;
}

const ViewAllButton: React.FC<IViewAllButtonProps> = ({ onPress, section, dataSet }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isWebRtl = getIsWebRtl();

  const handleViewAll = useCallback(() => {
    dispatch(
      log({
        event: 'ViewAllButton.handleViewAll',
        data: { section },
      }),
    );

    onPress(section);
  }, [dispatch, onPress, section]);

  return (
    <S.ViewAllContainer isRtl={isWebRtl}>
      <S.ViewAllButton
        // @ts-ignore
        dataSet={dataSet}
        onPress={handleViewAll}
      >
        <S.ViewAllText>{t('analytics.attributes.viewAll')}</S.ViewAllText>
      </S.ViewAllButton>
    </S.ViewAllContainer>
  );
};

const S = {
  ViewAllContainer: styled.View<{ isRtl: boolean }>`
    margin: ${calcHeight(16)}px ${calcHeight(19)}px;
    align-items: ${isRtlByLang[i18n.language] ? 'flex-start' : 'flex-end'};
  `,
  ViewAllButton: styled.TouchableOpacity``,
  ViewAllText: styled.Text`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
  `,
};

export default memo(ViewAllButton);
