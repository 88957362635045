import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInitialState } from './playlistStats.types';
import { IAnalyticsViewsListItem } from '../biteStats/biteStats.types';
import { prepareAnalytics, resetAnalytics } from '../../analytics/analytics.slice';
import { ESortByType } from '../playlistAttributes/playlistAttributes.types';
import { ESortingDirection } from '../../../types/anayltics';

export const initialState: IInitialState = {
  overview: {
    data: null,
    isLoading: false,
    error: null,
  },
  stats: {
    sortBy: ESortByType.PROGRESS,
    sortDirection: ESortingDirection.DESC,
    isNeedToScrollUp: false,
    error: null,
    isLoading: false,
    data: null,
    next: null,
  },
};

const PLAYLIST_STATS = 'PLAYLIST_STATS';

export const getPlaylistDistributions = createAction(`${PLAYLIST_STATS}/getPlaylistDistributions`);

interface IGetAnalyticsDataPayload {
  reset: boolean;
  clearAnalyticsCache?: boolean;
  callback?: () => void;
}

const playlistStatsSlice = createSlice({
  name: PLAYLIST_STATS,
  initialState,
  reducers: {
    getPlaylistStatsOverview: (state) => {
      state.overview.isLoading = true;
      state.overview.error = null;
    },
    setIsNeedToScrollUp: (state, { payload }: PayloadAction<boolean>) => {
      state.stats.isNeedToScrollUp = payload;
    },
    setPlaylistStatsOverview: (state, action) => {
      state.overview.data = action.payload;
      state.overview.isLoading = false;
      state.overview.error = null;
    },
    setPlaylistStatsOverviewError: (state, action) => {
      state.overview.isLoading = false;
      state.overview.error = action.payload;
    },
    setSortBy: (
      state,
      {
        payload: { sortBy, sortDirection, withoutReset },
      }: PayloadAction<{
        sortBy?: ESortByType.PROGRESS | ESortByType.SUCCESS;
        sortDirection: ESortingDirection;
        withoutReset?: boolean;
      }>,
    ) => {
      state.stats.sortBy = sortBy || state.stats.sortBy;
      state.stats.sortDirection = sortDirection;
      state.stats.isNeedToScrollUp = !withoutReset;

      if (!withoutReset) {
        state.stats.data = null;
        state.stats.next = null;
      }
    },
    getUsersData: (state, { payload }: PayloadAction<IGetAnalyticsDataPayload | undefined>) => {
      state.stats.isLoading = true;
      state.stats.error = null;
      if (payload?.reset) {
        state.stats.next = null;
      }
    },
    setUsersData: (
      state,
      { payload }: PayloadAction<{ results: IAnalyticsViewsListItem[]; next: null; reset?: boolean }>,
    ) => {
      if (state.stats.next && !payload.reset) {
        state.stats.data = state.stats.data.concat(payload.results);
      } else {
        state.stats.data = payload.results;
      }
      state.stats.next = payload.next;
      state.stats.isLoading = false;
    },
    setUsersDataError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.stats.error = payload.error;
      state.stats.isLoading = false;
    },
  },
  extraReducers: {
    [resetAnalytics.type]: (state, { payload }) => {
      const { excludeFields, withFiltersReset } = payload;
      const exclude = excludeFields?.biteStats;

      if (!exclude) {
        return initialState;
      }

      Object.keys(state.stats).forEach((key) => {
        if (exclude.some((field) => typeof field === 'string' && field === key)) {
          return;
        }

        const newState = initialState.stats[key];

        if (!withFiltersReset && state.stats[key].filter) {
          newState.filter = state.stats[key].filter;
        }

        state.stats[key] = newState;
      });
      state.overview = initialState.overview;
    },
    [prepareAnalytics.type]: () => {
      return initialState;
    },
  },
});

export const {
  getPlaylistStatsOverview,
  setPlaylistStatsOverview,
  setPlaylistStatsOverviewError,
  getUsersData,
  setUsersData,
  setUsersDataError,
  setSortBy,
  setIsNeedToScrollUp,
} = playlistStatsSlice.actions;

export default playlistStatsSlice.reducer;
