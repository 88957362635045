import React, { memo, useCallback, useMemo } from 'react';
import { BottomTabNavigationOptions, createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Overview from './Overview/Overview';
import Views from './Views/Views';
import Answered from './Answered/Answered';
import Comments from './Comments/Comments';
import styled from 'styled-components/native';
import OverviewIcon from '../../../assets/icons/analytics/overview.svg';
import OverviewEmptyIcon from '../../../assets/icons/analytics/overview_empty.svg';
import IntroIcon from '../../../assets/icons/analytics/intro.svg';
import IntroEmptyIcon from '../../../assets/icons/analytics/intro_empty.svg';
import IntroDisabledIcon from '../../../assets/icons/analytics/intro_disabled.svg';
import QuestionIcon from '../../../assets/icons/analytics/question.svg';
import QuestionEmptyIcon from '../../../assets/icons/analytics/question_empty.svg';
import QuestionDisabledIcon from '../../../assets/icons/analytics/question_disabled.svg';
import CommentIcon from '../../../assets/icons/analytics/comment.svg';
import CommentEmptyIcon from '../../../assets/icons/analytics/comment_empty.svg';
import CommentDisabledIcon from '../../../assets/icons/analytics/comment_disabled.svg';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../utils/dimensions';
import defaultTheme from '../../../themes';
import { useTranslation } from 'react-i18next';
import AnalyticsHeader from '../common/AnalyticsHeader';
import { useDispatch, useSelector } from 'react-redux';
import { contentIdSelector } from '../../../store/analytics/analytics.selector';
import { biteItemSelector } from '../../../store/bite/bite.selectors';
import useAnalyticsTabs from './hooks/useAnalyticsTabs';
import { StyleProp, ViewStyle } from 'react-native';
import BiteAttributesFilter from './common/BiteAttributesFilter';
import { IStackNavigation } from '../../../navigation/types';
import FilterMenuBottomSheet from './common/FilterMenuBottomSheet';
import { EAnalyticsScreenType } from '../Analytics.types';
import ExplanationVideoModal from './common/ExplanationVideoModal';
import {
  answeredTabBarIconDataSet,
  commentsTabBarIconDataSet,
  overviewTabBarIconDataSet,
  viewsTabBarIconDataSet,
} from '../analytics.constants';
import { biteSharedWithFilterSelector } from '../../../store/attributes/biteAttributes/biteAttributes.selector';
import { setIsNeedToScrollUp } from '../../../store/attributes/biteStats/biteStats.slice';

const Tab = createBottomTabNavigator();

const SCENE_CONTAINER_STYLES: StyleProp<ViewStyle> = {
  height: '100%',
  width: isWeb ? calcWidth(550) : deviceWidth,
  alignSelf: 'center',
  backgroundColor: defaultTheme.colors.white,
};

const defaultScreenOptions = {
  tabBarStyle: {
    alignSelf: 'center',
    width: isWeb ? calcWidth(550) : deviceWidth,
    height: calcHeight(94),
    borderTopWidth: 0,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: -3,
    },
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingHorizontal: 16,
    paddingTop: 6,
  },
  headerShown: false,
} as BottomTabNavigationOptions;

const renderOverviewIcon = () => <OverviewIcon />;
const renderOverviewEmptyIcon = () => <OverviewEmptyIcon />;
const renderIntroIcon = () => <IntroIcon />;
const renderIntroEmptyIcon = () => <IntroEmptyIcon />;
const renderIntroDisabledIcon = () => <IntroDisabledIcon />;
const renderQuestionIcon = () => <QuestionIcon />;
const renderQuestionEmptyIcon = () => <QuestionEmptyIcon />;
const renderQuestionDisabledIcon = () => <QuestionDisabledIcon />;
const renderCommentIcon = () => <CommentIcon />;
const renderCommentEmptyIcon = () => <CommentEmptyIcon />;
const renderCommentDisabledIcon = () => <CommentDisabledIcon />;

interface IProps extends IStackNavigation {}

const BiteAnalyticsScreen: React.FC<IProps> = ({ route }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const initialRouteName = route.params?.initialRouteName;
  const { isViewsTabEnabled, isAnsweredTabEnabled, isCommentsTabEnabled } = useAnalyticsTabs();

  const [selectedTab, setSelectedTab] = React.useState<EAnalyticsScreenType>(initialRouteName);

  const biteId = useSelector(contentIdSelector);
  const bite = useSelector(biteItemSelector(biteId));
  const sharedWithFilter = useSelector(biteSharedWithFilterSelector);

  const onTabPress = useCallback(
    ({ listType, isSelected, onPress }) =>
      () => {
        if (isSelected) {
          dispatch(setIsNeedToScrollUp({ listType, status: true }));
          return;
        }
        onPress();
        setSelectedTab(listType);
      },
    [dispatch],
  );

  const handleHeaderPress = useCallback(() => {
    onTabPress({ listType: selectedTab, isSelected: true, onPress: () => {} })();
  }, [onTabPress, selectedTab]);

  const renderOverviewTabBarButton = useCallback(
    ({ accessibilityState, onPress, onLongPress, ...props }) => {
      const handlePress = onTabPress({
        listType: EAnalyticsScreenType.OVERVIEW,
        isSelected: accessibilityState.selected,
        onPress,
      });

      return (
        <TabBarIcon
          {...props}
          name={t('analytics.overview.title')}
          selected={accessibilityState.selected}
          onPress={handlePress}
          onLongPress={onLongPress}
          activeIcon={renderOverviewIcon}
          inactiveIcon={renderOverviewEmptyIcon}
          // @ts-ignore
          dataSet={overviewTabBarIconDataSet}
        />
      );
    },
    [onTabPress, t],
  );

  const overviewTabOptions: unknown = useMemo(
    () => ({
      tabBarButton: renderOverviewTabBarButton,
    }),
    [renderOverviewTabBarButton],
  );

  const renderViewsTabBarButton = useCallback(
    ({ accessibilityState, onPress, onLongPress, ...props }) => {
      const handlePress = onTabPress({
        listType: EAnalyticsScreenType.VIEWS,
        isSelected: accessibilityState.selected,
        onPress,
      });

      return (
        <TabBarIcon
          {...props}
          name={isViewsTabEnabled ? t('analytics.views.title') : t('analytics.views.titleDisabled')}
          selected={accessibilityState.selected}
          disabled={!isViewsTabEnabled}
          onPress={handlePress}
          onLongPress={onLongPress}
          activeIcon={renderIntroIcon}
          inactiveIcon={renderIntroEmptyIcon}
          disabledIcon={renderIntroDisabledIcon}
          // @ts-ignore
          dataSet={viewsTabBarIconDataSet}
        />
      );
    },
    [isViewsTabEnabled, onTabPress, t],
  );

  const viewsTabOptions: unknown = useMemo(
    () => ({
      tabBarButton: renderViewsTabBarButton,
    }),
    [renderViewsTabBarButton],
  );

  const renderAnswerTabBarButton = useCallback(
    ({ accessibilityState, onPress, onLongPress, ...props }) => {
      const handlePress = onTabPress({
        listType: EAnalyticsScreenType.ANSWERS,
        isSelected: accessibilityState.selected,
        onPress,
      });

      return (
        <TabBarIcon
          {...props}
          name={isAnsweredTabEnabled ? t('analytics.answered.title') : t('analytics.answered.titleDisabled')}
          selected={accessibilityState.selected}
          disabled={!isAnsweredTabEnabled}
          onPress={handlePress}
          onLongPress={onLongPress}
          activeIcon={renderQuestionIcon}
          inactiveIcon={renderQuestionEmptyIcon}
          disabledIcon={renderQuestionDisabledIcon}
          // @ts-ignore
          dataSet={answeredTabBarIconDataSet}
        />
      );
    },
    [isAnsweredTabEnabled, onTabPress, t],
  );

  const answerTabOptions: unknown = useMemo(
    () => ({
      tabBarButton: renderAnswerTabBarButton,
    }),
    [renderAnswerTabBarButton],
  );

  const renderCommentTabBarButton = useCallback(
    ({ accessibilityState, onPress, onLongPress, ...props }) => {
      const handlePress = onTabPress({
        listType: EAnalyticsScreenType.COMMENTS,
        isSelected: accessibilityState.selected,
        onPress,
      });

      return (
        <TabBarIcon
          {...props}
          name={isCommentsTabEnabled ? t('analytics.comments.title') : t('analytics.comments.titleDisabled')}
          selected={accessibilityState.selected}
          disabled={!isCommentsTabEnabled}
          onPress={handlePress}
          onLongPress={onLongPress}
          activeIcon={renderCommentIcon}
          inactiveIcon={renderCommentEmptyIcon}
          disabledIcon={renderCommentDisabledIcon}
          // @ts-ignore
          dataSet={commentsTabBarIconDataSet}
        />
      );
    },
    [isCommentsTabEnabled, onTabPress, t],
  );

  const commentsTabOptions: unknown = useMemo(
    () => ({
      tabBarButton: renderCommentTabBarButton,
    }),
    [renderCommentTabBarButton],
  );

  return (
    <S.Container>
      <AnalyticsHeader
        onPress={handleHeaderPress}
        title={t('analytics.stats.title')}
        subtitle={bite.subject}
        sharedWithFilter={sharedWithFilter}
      />
      <BiteAttributesFilter />
      <Tab.Navigator
        initialRouteName={initialRouteName || EAnalyticsScreenType.OVERVIEW}
        sceneContainerStyle={SCENE_CONTAINER_STYLES}
        screenOptions={defaultScreenOptions}
      >
        <Tab.Screen name={EAnalyticsScreenType.OVERVIEW} component={Overview} options={overviewTabOptions} />

        <Tab.Screen name={EAnalyticsScreenType.VIEWS} component={Views} options={viewsTabOptions} />

        <Tab.Screen name={EAnalyticsScreenType.ANSWERS} component={Answered} options={answerTabOptions} />

        <Tab.Screen name={EAnalyticsScreenType.COMMENTS} component={Comments} options={commentsTabOptions} />
      </Tab.Navigator>
      <FilterMenuBottomSheet />
      <ExplanationVideoModal />
    </S.Container>
  );
};

const TabBarIcon = ({
  selected,
  disabled,
  onPress,
  onLongPress,
  name,
  activeIcon,
  inactiveIcon,
  disabledIcon,
}: {
  selected: boolean;
  disabled?: boolean;
  onPress: () => void;
  onLongPress: () => void;
  name: string;
  activeIcon: () => React.ReactNode;
  inactiveIcon: () => React.ReactNode;
  disabledIcon?: () => React.ReactNode;
}) => {
  const handlePress = useCallback(() => {
    if (disabled) {
      return;
    }
    if (typeof onPress === 'function') {
      onPress();
    }
  }, [disabled, onPress]);

  const handleLongPress = useCallback(() => {
    if (disabled) {
      return;
    }
    if (typeof onLongPress === 'function') {
      onLongPress();
    }
  }, [disabled, onLongPress]);

  return (
    <S.TabBarButtonContainer>
      <S.TabBarButton onPress={handlePress} onLongPress={handleLongPress} selected={selected}>
        <S.IconContainer>
          {disabled && disabledIcon ? disabledIcon() : selected ? activeIcon() : inactiveIcon()}
        </S.IconContainer>
        <S.TabBarButtonText disabled={disabled} selected={selected}>
          {name}
        </S.TabBarButtonText>
      </S.TabBarButton>
    </S.TabBarButtonContainer>
  );
};

const S = {
  Container: styled.View`
    width: 100%;
    height: 100%;
    justify-content: space-between;
  `,
  TabBarButtonContainer: styled.View`
    margin: auto;
  `,
  TabBarButton: styled.TouchableOpacity<{ selected: boolean }>`
    padding: ${calcHeight(5)}px 0;
    width: ${calcWidth(83)}px;
    height: ${calcHeight(50)}px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: ${({ theme, selected }) => (selected ? theme.colors.textInput : theme.colors.white)};
  `,
  TabBarButtonText: styled.Text<{ disabled: boolean; selected: boolean }>`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme, disabled, selected }) =>
      disabled ? theme.colors.lightGray42 : selected ? theme.colors.text : theme.colors.gray19};
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
    font-weight: ${({ selected }) => (selected ? '700' : '400')};
    line-height: 14px; /* 116.667% */
  `,
  IconContainer: styled.View`
    width: ${calcWidth(24)}px;
    height: ${calcHeight(24)}px;
    align-items: center;
    justify-content: center;
    justify-self: center;
  `,
};

export default memo(BiteAnalyticsScreen);
