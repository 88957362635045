import { EPreviewMode } from '../../components/shared/PreviewControlPanel/constants';
import { navigationRef } from '../../navigation/RootNavigation';
import Routes from '../../navigation/routes';
import { EAnalyticsScreenType } from '../../screens/analytics/Analytics.types';
import openPreviewBiteDashboard from './openPreviewBiteDashboard';

interface IProps {
  biteId: number;
  trigger?: string;
  initialMode?: EPreviewMode;
  withoutDelete?: boolean;
  withoutEdit?: boolean;
  withoutClone?: boolean;
  shareOnMount?: boolean;
  tab?: EAnalyticsScreenType;
}

const openPreviewBite = ({
  biteId,
  trigger,
  initialMode,
  withoutDelete,
  withoutEdit,
  withoutClone,
  shareOnMount,
  tab,
}: IProps) => {
  if (tab) {
    switch (tab) {
      case EAnalyticsScreenType.OVERVIEW:
      case EAnalyticsScreenType.VIEWS:
        openPreviewBiteDashboard(biteId, 'overview');
        break;
      case EAnalyticsScreenType.ANSWERS:
        openPreviewBiteDashboard(biteId, 'answer-analysis');
        break;
      case EAnalyticsScreenType.COMMENTS:
        openPreviewBiteDashboard(biteId, 'discussion');
        break;
      default: {
        openPreviewBiteDashboard(biteId);
      }
    }
    return;
  }

  navigationRef.current.navigate(Routes.PreviewStack.StackName, {
    screen: Routes.PreviewStack.PreviewBite,
    params: {
      biteId,
      trigger,
      initialMode,
      withoutDelete,
      withoutEdit,
      withoutClone,
      shareOnMount,
    },
  });
};
export default openPreviewBite;
