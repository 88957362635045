import {
  IPlaylistAttributeMap,
  IPlaylistAttributeMapItem,
  PlaylistAttributeValue,
} from '../../store/attributes/playlistAttributes/playlistAttributes.types';
import {
  IBiteAttributeMap,
  IBiteAttributeMapItem,
  IBiteAttributeValue,
} from '../../store/attributes/biteAttributes/biteAttributes.types';

export enum EAnalyticsScreenType {
  OVERVIEW = 'overview',
  VIEWS = 'views',
  ANSWERS = 'answers',
  COMMENTS = 'comments',
  PLAYLIST = 'playlist',
}

export interface IGetAttributesPayload {
  attributeIds: number[];
  clearAnalyticsCache?: boolean;
  reset?: boolean;
  callback?: () => void;
}

export type AnalyticsAttributeMap = IBiteAttributeMap | IPlaylistAttributeMap;

export type AnalyticsAttributeMapItem = IBiteAttributeMapItem | IPlaylistAttributeMapItem;

export type AnalyticsAttributeValue = IBiteAttributeValue | PlaylistAttributeValue;
