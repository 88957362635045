import React, { useCallback } from 'react';
import Cloud from './Cloud';
import { ICloudAsset } from '../../../store/cloudAssets/cloudAssets.types';
import { FileTypeSelector } from './FileTypeSelector';
import SidebarTitle from './SidebarTitle';
import { useSelector } from 'react-redux';
import {
  cloudFileTypeSelector,
  cloudResultsSelector,
} from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import { setCloudResults } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import { SidebarProps } from './LeftSidebar';

const FILE_TYPES: ('all' | ICloudAsset['fileType'])[] = ['all', 'video', 'image', 'gif', 'audio'];

const CloudSidebar = ({ previewPanelRef, setIsPreviewPanelVisible }: SidebarProps) => {
  const cloudResults = useSelector(cloudResultsSelector);
  const fileType = useSelector(cloudFileTypeSelector);

  const renderFilterControls = useCallback(() => {
    return <FileTypeSelector fileType={fileType} fileTypes={FILE_TYPES} />;
  }, [fileType]);

  return (
    <>
      <SidebarTitle title='Search' />
      <Cloud
        cloudResults={cloudResults}
        renderFilterControls={renderFilterControls}
        setCloudResults={setCloudResults}
        setIsPreviewPanelVisible={setIsPreviewPanelVisible}
        previewPanelRef={previewPanelRef}
      />
    </>
  );
};
export default CloudSidebar;
