import { RootState } from '../../index';

export const answersQuestionSelector = (state: RootState) => state.biteStats.stats.aggregatedAnswers.data.question;

export const answersAggregatedSelector = (state: RootState) => state.biteStats.stats.aggregatedAnswers.data.aggregated;

export const overviewDataSelector = (state: RootState) => state.biteStats.stats.overview.data;

export const viewsDataSelector = (state: RootState) => state.biteStats.stats.views.data;

export const answersDataSelector = (state: RootState) => state.biteStats.stats.answers.data;

export const commentsDataSelector = (state: RootState) => state.biteStats.stats.comments.data;

export const overviewLoadingSelector = (state: RootState) => state.biteStats.stats.overview.isLoading;

export const viewsLoadingSelector = (state: RootState) => state.biteStats.stats.views.isLoading;

export const answersLoadingSelector = (state: RootState) => state.biteStats.stats.answers.isLoading;

export const commentsLoadingSelector = (state: RootState) => state.biteStats.stats.comments.isLoading;

export const overviewNextSelector = (state: RootState) => state.biteStats.stats.overview.next;

export const viewsNextSelector = (state: RootState) => state.biteStats.stats.views.next;

export const answersNextSelector = (state: RootState) => state.biteStats.stats.answers.next;

export const commentsNextSelector = (state: RootState) => state.biteStats.stats.comments.next;

export const overviewFilterSelector = (state: RootState) => state.biteStats.stats.overview.filter;

export const viewsFilterSelector = (state: RootState) => state.biteStats.stats.views.filter;

export const answersFilterSelector = (state: RootState) => state.biteStats.stats.answers.filter.answered;

export const answersIdsFilterSelector = (state: RootState) => state.biteStats.stats.answers.filter.answerIds;

export const commentsFilterSelector = (state: RootState) => state.biteStats.stats.comments.filter;

export const overviewErrorSelector = (state: RootState) => state.biteStats.stats.overview.error;

export const viewsErrorSelector = (state: RootState) => state.biteStats.stats.views.error;

export const answersErrorSelector = (state: RootState) => state.biteStats.stats.answers.error;

export const commentsErrorSelector = (state: RootState) => state.biteStats.stats.comments.error;

export const answersAggregatedLoadingSelector = (state: RootState) => state.biteStats.stats.aggregatedAnswers.isLoading;

export const answersAggregatedErrorSelector = (state: RootState) => state.biteStats.stats.aggregatedAnswers.error;

export const isNeedToScrollUpOverviewSelector = (state: RootState) => state.biteStats.stats.overview.isNeedToScrollUp;

export const isNeedToScrollUpViewsSelector = (state: RootState) => state.biteStats.stats.views.isNeedToScrollUp;

export const isNeedToScrollUpAnswersSelector = (state: RootState) => state.biteStats.stats.answers.isNeedToScrollUp;

export const isNeedToScrollUpCommentsSelector = (state: RootState) => state.biteStats.stats.comments.isNeedToScrollUp;

export const viewsNumberSelector = (state: RootState) => state.biteStats.overview.views;

export const answersNumberSelector = (state: RootState) => state.biteStats.overview.answers;

export const commentsNumberSelector = (state: RootState) => state.biteStats.overview.comments;
