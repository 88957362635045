import React, { memo, useEffect, useState } from 'react';
import BiteStatsOverview from './BiteStatsOverview';
import { useCallback } from 'react';
import { getBiteStatsOverview } from '../../../store/attributes/biteStats/biteStats.slice';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { bitesLoadingStateMapSelector } from '../../../store/bite/bite.selectors';
import { RefreshControl, ViewStyle } from 'react-native';
import { fetchFullBites } from '../../../store/bite/bite.actions';
import { StyledProps } from 'styled-components';
import { resetAnalytics } from '../../../store/analytics/analytics.slice';
import AnalyticsScreenHeader from '../../analytics/common/AnalyticsScreenHeader';
import Error from '../common/Error';
import { contentIdSelector } from '../../../store/analytics/analytics.selector';
import { log, trackEvent } from '../../../store/appActivity/appActivity.slice';
import RestrictedDataBanner from '../common/RestrictedDataBanner';
import Routes from '../../../navigation/routes';
import BiteOverview from './BiteOverview';

const BiteScreen = () => {
  const dispatch = useDispatch();
  const biteId = useSelector(contentIdSelector);
  const biteLoadingState = useSelector(bitesLoadingStateMapSelector(biteId));
  const [isRefreshing, setIsRefreshing] = useState(false);
  const isError = !biteLoadingState.isLoading && biteLoadingState?.error;

  const handleRefresh = useCallback(() => {
    dispatch(log({ event: 'BiteScreen.handleRefresh', data: { biteId } }));
    setIsRefreshing(true);
    dispatch(
      resetAnalytics({
        excludeFields: { analytics: ['contentId', 'contentType', 'hasDistributions', 'isFilterTooltipShown'] },
      }),
    );
    dispatch(fetchFullBites([biteId]));
    dispatch(getBiteStatsOverview());
  }, [biteId, dispatch]);

  const handleErrorRefresh = useCallback(() => {
    dispatch(log({ event: 'BiteScreen.handleErrorRefresh', data: { biteId } }));
    dispatch(fetchFullBites([biteId]));
    dispatch(getBiteStatsOverview());
  }, [biteId, dispatch]);

  useEffect(() => {
    if (isRefreshing && !biteLoadingState.isLoading) {
      setIsRefreshing(false);
    }
  }, [biteLoadingState?.isLoading, isRefreshing]);

  useEffect(() => {
    dispatch(
      trackEvent({
        event: 'pageview',
        props: { page_title: Routes.AnalyticsStack.BiteOverview },
      }),
    );

    return () => {
      dispatch(log({ event: 'BiteScreen.exit', data: { biteId } }));
    };
  }, [biteId, dispatch]);

  return (
    <S.Container
      contentContainerStyle={S.ContentContainerStyles}
      refreshControl={<S.RefreshControl refreshing={isRefreshing} onRefresh={handleRefresh} />}
    >
      <AnalyticsScreenHeader />
      {isError ? (
        <Error onRefresh={handleErrorRefresh} />
      ) : (
        <>
          <BiteOverview isRefreshing={isRefreshing} />
          <BiteStatsOverview isRefreshing={isRefreshing} />
          <RestrictedDataBanner />
        </>
      )}
    </S.Container>
  );
};

const S = {
  Container: styled.ScrollView``,
  ContentContainerStyles: { alignItems: 'center' } as StyledProps<ViewStyle>,
  RefreshControl: styled(RefreshControl)``,
};

export default memo(BiteScreen);
