import React, { memo, useCallback, useMemo } from 'react';
import Routes from '../../../navigation/routes';
import styled, { useTheme } from 'styled-components/native';
import ShadowedContainer from '../../../components/ShadowedContainer';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import ArrowDown from '../../../assets/icons/feed/arrow-down.svg';
import ShareSection from '../../creationBite/common/ShareSection';
import Button from '../../../components/shared/buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { EButtonSize } from '../../../types/common';
import useIsItemOwner from '../../../hooks/useIsItemOwner';
import { isContentAdminSelector } from '../../../store/auth/auth.selectors';
import { contentIdSelector } from '../../../store/analytics/analytics.selector';
import { log, trackEvent } from '../../../store/appActivity/appActivity.slice';
import { EditButtonDataSet } from '../../Preview/Preview.contants';
import { AWS_URL } from '../../../utils/constants/env';
import BiteLogo from '../../../assets/images/bite-logo.png';
import { getIsRtl, getIsWebRtl } from '../../../locale/i18n';
import { StackNavigationProp } from '@react-navigation/stack';
import { bitePreviewButtonDataSet } from '../stats.constants';
import { playlistItemSelector } from '../../../store/playlist/playlist.selectors';
import { setPlaylistToEdit } from '../../../store/createPlaylist/createPlaylist.actions';
import { EShareItemType } from '../../../store/sharePanel/sharePanel.slice';
import { ESharePanelCallbackType } from '../../../types/sharePanel';

interface IProps {
  isRefreshing: boolean;
}

const PlaylistOverview: React.FC<IProps> = ({ isRefreshing }) => {
  const navigation = useNavigation<StackNavigationProp<any>>();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const isRtl = getIsRtl();
  const isWebRtl = getIsWebRtl();

  const playlistId = useSelector(contentIdSelector);
  const playlist = useSelector(playlistItemSelector(playlistId));
  const isPlaylistOwner = useIsItemOwner(playlist);
  const isContentAdmin = useSelector(isContentAdminSelector);
  const isUserCanEdit = isPlaylistOwner || isContentAdmin;

  const resizeMode = playlist?.linked_cover_url ? 'cover' : 'contain';

  const source = useMemo(() => {
    const workspaceLogoUrl = playlist?.branding?.logo_url ? `${AWS_URL}/${playlist.branding.logo_url}` : null;
    const uri = playlist?.linked_cover_url || workspaceLogoUrl;

    return uri ? { uri } : BiteLogo;
  }, [playlist?.branding?.logo_url, playlist?.linked_cover_url]);

  const handlePressEditPlaylist = useCallback(() => {
    if (playlist?.is_quiz) {
      dispatch(
        trackEvent({
          event: 'edit',
          props: { quiz_id: playlist?.id },
        }),
      );
    } else {
      dispatch(
        trackEvent({
          event: 'edit',
          props: { playlist_id: playlist?.id },
        }),
      );
    }
    dispatch(setPlaylistToEdit({ playlist }));
    if (playlist?.is_quiz) {
      navigation.navigate(Routes.EditQuizStack.StackName, { params: { playlistId: playlist.id } });
      return;
    }
    navigation.navigate(Routes.EditPlaylistStack.StackName, {
      screen: Routes.EditPlaylistStack.EditPlaylist,
      params: { playlistId: playlist.id, isGoBack: true },
    });
  }, [playlist, dispatch, navigation]);

  const renderLeftComponent = useCallback(
    (disabled) => {
      return (
        <Button
          isShadowed
          onPress={handlePressEditPlaylist}
          disabled={disabled}
          text={t('analytics.overview.editPlaylist')}
          style={buttonStyle}
          fill={theme.colors.white}
          border={theme.colors.primaryBlue}
          textColor={theme.colors.primaryBlue}
          dataSet={EditButtonDataSet}
        />
      );
    },
    [handlePressEditPlaylist, t, theme.colors.primaryBlue, theme.colors.white],
  );

  const handlePreviewPress = useCallback(() => {
    dispatch(
      log({
        event: 'PlaylistOverview.handlePreviewPress',
        data: { playlistId: playlist?.id },
      }),
    );

    navigation.navigate(Routes.AnalyticsStack.PlaylistPreview, { playlistId: playlist?.id });
  }, [playlist?.id, dispatch, navigation]);

  return (
    <ShadowedContainer distance={14} radius={14}>
      <S.Container>
        <S.Preview
          //@ts-ignore
          dataSet={bitePreviewButtonDataSet}
          isRtl={isWebRtl}
          onPress={handlePreviewPress}
        >
          <S.TitleBlock isRtl={isWebRtl}>
            <S.Image source={source} resizeMode={resizeMode} />
            <S.Title>{playlist?.subject}</S.Title>
          </S.TitleBlock>
          <S.ArrowContainer isRtl={isRtl}>
            <ArrowDown color={theme.colors.primaryBlue} />
          </S.ArrowContainer>
        </S.Preview>

        {!!playlist && (
          <>
            <S.Divider />
            <ShareSection
              type={ESharePanelCallbackType.PLAYLIST_IN_LIST}
              shareItemType={EShareItemType.PLAYLIST}
              from='edit_playlist'
              disabled={isRefreshing}
              buttonSize={EButtonSize.SMALL}
              subject={playlist?.subject}
              shareData={playlist}
              leftButtonComponent={isUserCanEdit ? renderLeftComponent : null}
            />
          </>
        )}
      </S.Container>
    </ShadowedContainer>
  );
};

const buttonStyle = {
  height: calcHeight(42),
  width: calcWidth(123),
};

const S = {
  Container: styled.View`
    margin-top: 12px;
    width: ${calcWidth(351)}px;
    background: white;
    border-radius: 20px;
    padding-bottom: ${calcHeight(19)}px;
  `,
  Image: styled.Image`
    width: ${calcWidth(47)}px;
    height: ${calcHeight(66)}px;
    border-radius: 7px;
  `,
  Preview: styled.TouchableOpacity<{ isRtl: boolean }>`
    width: 100%;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
    padding: 10px 18px;
  `,
  Divider: styled.View`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.lightGray32};
  `,
  Title: styled.Text`
    max-width: ${calcWidth(240)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    margin: ${calcHeight(6)}px ${calcWidth(16)}px 0;
    font-size: ${({ theme }) => theme.fontSizes.s14};
    font-weight: 700;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.text};
  `,
  TitleBlock: styled.View<{ isRtl: boolean }>`
    flex: 1;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
  `,
  ArrowContainer: styled.View<{ isRtl: boolean }>`
    width: ${calcWidth(24)}px;
    height: ${calcHeight(24)}px;

    transform: ${({ isRtl }) => (isRtl ? 'rotate(90deg)' : 'rotate(-90deg)')};
  `,
};

export default memo(PlaylistOverview);
