import React, { memo } from 'react';
import { PreviewContent, PreviewControls } from './Preview';
import SidebarTitle from '../SidebarTitle';
import styled from 'styled-components';

type Props = {
  url: string;
  onAdd: () => void;
  onReplace: () => void;
  onClose: () => void;
};
export const PreviewImage = memo(({ url, onAdd, onReplace, onClose }: Props) => {
  return (
    <>
      <SidebarTitle title='Image' bold={false} />
      <PreviewContent>
        <S.Image src={url} key={url} />
      </PreviewContent>
      <PreviewControls onAdd={onAdd} onReplace={onReplace} onClose={onClose} />
    </>
  );
});

const S = {
  Image: styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
  `,
};
