import { all } from 'redux-saga/effects';
import appActivitySagaWatcher from './appActivity/appActivity.saga';
import authSagaWatcher from './auth/auth.saga';
import biteSagaWatcher from './bite/bite.saga';
import playlistSagaWatcher from './playlist/playlist.saga';
import iconsSagaWatcher from './icons/icons.saga';
import { createBiteSagaWatcher } from './createBite/createBite.saga';
import createBiteIntroVideoSagaWatcher from './introVideo.saga';
import introVideoPollingSagaWatcher from './introVideoPolling.saga';
import createPlaylistWatcher from './createPlaylist/createPlaylist.saga';
import orgSagaWatcher from './org/org.saga';
import sharePanelSaga from './sharePanel/sharePanel.saga';
import exploreSagaWatcher from './explore/explore.saga';
import draftsSagaWatcher from './drafts/drafts.saga';
import feedSagaWatcher from './feed/feed.saga';
import bitesFeedSagaWatcher from './bitesFeed/bitesFeed.saga';
import questionnaireSagaWatcher from './questionnaire/questionnaire.saga';
import analyticsSaga from './analytics/analytics.saga';
import cloudAssetsSaga from './cloudAssets/cloudAssets.saga';
import videoEditorSaga from './videoEditor/videoEditor.saga';
import biteAttributesSaga from './attributes/biteAttributes/biteAttributes.saga';
import editAiGenerationSaga from './editAiGeneration/editAiGeneration.saga';
import aiGenerationScriptsSaga from './aiGenerationScripts/aiGenerationScripts.saga';
import aiGenerationSaga from './aiGeneration/aiGeneration.saga';
import biteStatsSaga from './attributes/biteStats/biteStats.saga';
import playlistStatsSaga from './attributes/playlistStats/playlistStats.saga';
import playlistAttributesSaga from './attributes/playlistAttributes/playlistAttributes.saga';

export default function* rootSaga() {
  yield all([
    appActivitySagaWatcher(),
    authSagaWatcher(),
    biteSagaWatcher(),
    exploreSagaWatcher(),
    playlistSagaWatcher(),
    createPlaylistWatcher(),
    iconsSagaWatcher(),
    createBiteSagaWatcher(),
    createBiteIntroVideoSagaWatcher(),
    introVideoPollingSagaWatcher(),
    orgSagaWatcher(),
    sharePanelSaga(),
    draftsSagaWatcher(),
    feedSagaWatcher(),
    bitesFeedSagaWatcher(),
    questionnaireSagaWatcher(),
    analyticsSaga(),
    biteStatsSaga(),
    playlistStatsSaga(),
    cloudAssetsSaga(),
    videoEditorSaga(),
    biteAttributesSaga(),
    playlistAttributesSaga(),
    editAiGenerationSaga(),
    aiGenerationScriptsSaga(),
    aiGenerationSaga(),
  ]);
}
