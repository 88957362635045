import store from '../../store';
import { setSelectedTimelineItemId } from '../../store/videoEditor/videoEditor.slice';

export const clickTargetRef = {
  current: null,
};

document.addEventListener('click', () => {
  const replacingSelectedTimelineItem = store.getState().videoEditor.replacingSelectedTimelineItem;

  if (clickTargetRef.current?.isTimelineItemTarget || replacingSelectedTimelineItem) {
    clickTargetRef.current = null;
    return;
  }

  clickTargetRef.current = null;
  store.dispatch(setSelectedTimelineItemId(null));
});
