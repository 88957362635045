export interface IInitialState {
  isFilterTooltipShown: boolean;
  isFilterBottomSheetOpen: boolean;
  isRefreshing: boolean;
  currentList: ECurrentList;
  contentId: number | null;
  contentType: EAnalyticsContentType;
  hasDistributions: boolean | null;
}

export enum ECurrentList {
  ATTRIBUTES = 'attributes',
  USERS = 'users',
}
export enum EAnalyticsContentType {
  BITE = 'bite',
  PLAYLIST = 'playlist',
}
