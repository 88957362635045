import React from 'react';
import { TouchableOpacityProps } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { calcWidth } from '../../../utils/dimensions';
import ShadowedContainer from '../../../components/ShadowedContainer';
import DeleteIcon from '../../../assets/icons/delete-icon.svg';
import { StyleProp, ViewStyle } from 'react-native';
import { deleteButtonDataSet } from '../../../screens/Shared/constants';

export interface IAddAnswerButtonProps extends Pick<TouchableOpacityProps, 'onPress'> {
  containerViewStyle?: StyleProp<ViewStyle>;
  width?: number;
  height?: number;
  isActive?: boolean;
  iconColor?: string;
}

const DeleteButton: React.FC<IAddAnswerButtonProps> = ({
  width,
  height,
  containerViewStyle,
  isActive = false,
  iconColor,
  onPress,
}) => {
  const theme = useTheme();
  return (
    <ShadowedContainer
      distance={20}
      containerViewStyle={containerViewStyle}
      viewStyle={{
        ...S.DeleteIconViewStyle,
        width,
        height,
        backgroundColor: isActive ? theme.colors.pink : theme.colors.white,
        borderColor: isActive ? theme.colors.pinkDark : theme.colors.white,
      }}
    >
      <S.DeleteTouchable
        onPress={onPress}
        //@ts-ignore
        dataSet={deleteButtonDataSet}
      >
        <DeleteIcon style={S.DeleteIcon} fill={iconColor || theme.colors.gray11} />
      </S.DeleteTouchable>
    </ShadowedContainer>
  );
};

const S = {
  DeleteIcon: {
    width: calcWidth(15),
    height: calcWidth(15),
    margin: calcWidth(10),
  },
  DeleteIconViewStyle: {
    width: calcWidth(50),
    height: calcWidth(50),
    borderRadius: calcWidth(50 / 2),
    borderWidth: 1,
  },
  DeleteTouchable: styled.TouchableOpacity`
    flex: 1;
    align-items: center;
    justify-content: center;
  `,
};

export default DeleteButton;
